import React, { useEffect, useRef, useState } from 'react'
import { useGetRegistrationByIdQuery, useUpdateRegistrationMutation } from '../../../redux/LabServices/RegistrationServices'
import { Loader } from '../../../Basic/components';
import { toast } from 'react-toastify';
import ViewOnly from './Document';
import FactoryAddress from '../PrintFormat-TestReport/FactoryAddress';
import Header from './Header';
import moment from 'moment';
import blobToFile from '../../../Utils/blobToFile';
import GenerateProfomaInvoiceNumber from './GenerateProfomaInvoiceNumber';
import DocumentProfomaInvoice from '../Registration/DocumentProfomaInvoice';
import { Modal } from '../../../Inputs';
import empty from "../../../assets/empty.png"
import { getImageUrlPath } from '../../../Utils/helper'

const PrintProformaInvoice = ({ id, componentRef, profomaInvoiceDocument, setProfomaInvoiceDocument }) => {

    const [testDetailsData, setTestDetailsData] = useState([])
    const { data } = useGetRegistrationByIdQuery({ id, params: { invoice: true } }, { skip: !id })
    const [update] = useUpdateRegistrationMutation()
    const [packageDiscount, setPackageDiscount] = useState()

    useEffect(() => {
        setPackageDiscount(data?.data?.packageDetails[0]?.discount ? data?.data?.packageDetails[0]?.discount : 0)
    }, [data, setPackageDiscount])

    const handleSave = async (text) => {
        try {
            await update({ registrationId: id, executiveSummaryUpdate: true, invoiceTestDetails: testDetailsData, discount: packageDiscount }).unwrap();
            toast.success(text + "Successfully");
        } catch (error) {
            console.log("handle");
        }

    }

    function handleInputChange(value, index, field) {
        setTestDetailsData(prev => {
            let newData = structuredClone(prev);
            newData[index][field] = value;
            return newData
        })
    }

    useEffect(() => {
        if (!data?.data) return
        let testData;
        if (data?.data?.invoiceTestDetails.length > 0) {
            testData = data?.data?.invoiceTestDetails
        }
        else {
            testData = data?.data?.testDetails
        }

        setTestDetailsData(testData)
    }, [data, setTestDetailsData])

    if (!data?.data) {
        return <Loader />
    }

    const imageWidth = "200px"
    const imageHeight = "150px";

    const image = data?.data?.authorizedSignature ? data?.data?.authorizedSignature : ""

    function isCalculateSurchargePrice(testPrice) {
        let surChargeAmount = 0;
        let FindUrgentPrice = data?.data?.service?.includes("URGENT")
        let FindExpressPrice = data?.data?.service?.includes("EXPRESS")

        if (FindUrgentPrice) {
            return surChargeAmount = testPrice + testPrice
        }
        else if (FindExpressPrice) {
            return surChargeAmount = ((parseFloat(40) / parseFloat(100)) * parseFloat(testPrice || 0))
        }
        else {
            return surChargeAmount = 0
        }

    }


    return (
        <>
            {/* <button className='bg-red-400 w-24 rounded-xl p-1' onClick={() => generatePDF(componentRef)}>Send Mail</button> */}
            {/* <div className='hidden'>
                <ViewOnly data={data} testDetailsData={testDetailsData} componentRef={componentRef} />
            </div> */}

            <Modal isOpen={profomaInvoiceDocument} onClose={() => setProfomaInvoiceDocument(false)} widthClass={" h-[90%] w-[90%] bg-white p-2"}>

                <DocumentProfomaInvoice id={id} />

            </Modal>

            <div id='print' className="font-times w-full bg-white shadow-md p-5 rounded-md grid grid-cols-1 place-content-center">
                <div>
                    <Header />
                </div>

                <div>
                    <h2 className="text-md font-semibold text-center mt-2 mb-3">PROFORMA INVOICE</h2>
                </div>

                <div className='grid grid-cols-2 gap-y-1 text-xs'>
                    {data?.data?.profomaInvoiceNo
                        ?
                        <p className='text-xs'><strong>Invoice No:</strong> {data?.data.profomaInvoiceNo}</p>
                        :
                        <GenerateProfomaInvoiceNumber registrationId={data?.data.registrationId} />
                    }
                    <p className='text-xs'><strong>Invoice.Date:</strong> {moment.utc(data?.data?.profomaInvoiceDate).format("DD-MM-YYYY")}</p>
                    <p className='text-xs'><strong>STATE CODE: 33</strong> </p>
                    <p className='text-xs'><strong>HSN CODE: 998346</strong> </p>
                </div>

                <div className='grid grid-cols-1 mt-2 text-xs'>
                    <h1 >To : {data?.data?.toCompany?.name} ---{data?.data?.toCompany?.address}</h1>
                    <h1 className='mt-2'  >ContactName : {data?.data?.toCompany?.contactDetails?.map(val => val.contactPersonName).join("/")}</h1>
                    <h1>Email.Id : {data?.data?.toCompany?.contactDetails?.map(val => val.email).join("/")}</h1>
                </div>



                <div className='mt-3 mb-2'>

                    <table className='w-full mt-1 border border-gray-500 table-fixed p-2'>

                        <tbody>
                            <tr>
                                <td className='text-xs border border-gray-600 p-1 whitespace-normal break-words'>
                                    BuyerName: <span className='ml-2'>{data?.data?.buyerName ? data?.data?.buyerName : ""}</span>
                                </td >
                                <td className='text-xs border border-gray-600 p-1 whitespace-normal break-words'>
                                    Test Report No: <span className='ml-2'></span> {data?.data?.testReportNo ? data?.data?.testReportNo : ""}
                                </td >
                            </tr>
                            <tr>
                                <td className='text-xs border border-gray-600 p-1 whitespace-normal break-words'>
                                    Color:  <span className='ml-2'> {data?.data?.color ? data?.data?.color : ""}</span>
                                </td >
                                <td className='text-xs border border-gray-600 p-1 whitespace-normal break-words'>
                                    Reg.Date: <span className='ml-2'>  {moment.utc(data?.data?.regDate ? data?.data?.regDate : "").format("DD-MM-YYYY")}</span>
                                </td >
                            </tr>
                            <tr>
                                <td className='text-xs border border-gray-600 p-1 whitespace-normal break-words'>
                                    Service:  <span className='ml-2'> {data?.data?.service ? data?.data?.service : ""} Days </span>
                                </td >
                                <td className='text-xs border border-gray-600 p-1 whitespace-normal break-words'>
                                    Description: <span className='ml-2'> {data?.data?.sampleDescription ? data?.data?.sampleDescription : ""} </span>
                                </td >
                            </tr>
                            <tr>
                                <td className='text-xs border border-gray-600 p-1 whitespace-normal break-words'>
                                    Style. No: <span className='ml-2'> {data?.data?.styleNo ? data?.data?.styleNo : ""} </span>
                                </td >
                                <td className='text-xs border border-gray-600 p-1 whitespace-normal break-words'>
                                    Batch. No:  <span className='ml-2'>{data?.data?.batchNo ? data?.data?.batchNo : ""} </span>
                                </td >
                            </tr>
                        </tbody>
                    </table>

                </div>



                {
                    data?.data?.typeOfInvoice == "PACKAGE" ?

                        <table className="table-fixed w-full mt-2">
                            <thead>
                                <tr>
                                    <th className="border border-gray-400 text-xs p-1 w-9">S.No</th>

                                    <th className="border border-gray-400 text-xs p-1  w-60">Package Name</th>
                                    <th className="border border-gray-400 text-xs p-1 w-32">Quantity</th>
                                    <th className="border border-gray-400 text-xs p-1 w-32">Price</th>
                                    <th className="border border-gray-400 text-xs p-1 ">SurChargePrice</th>

                                    <th className="border border-gray-400 text-xs p-1 w-32">Discount</th>

                                    <th className="border border-gray-400 text-xs p-1 w-32">Amount</th>


                                </tr>
                            </thead>
                            <tbody>
                                {data?.data?.packageDetails.map((item, index) =>
                                    <tr key={index}>
                                        <td className="border border-gray-400 text-xs p-1 ">{index + 1}</td>

                                        <td className="border border-gray-400 text-xs p-1">{item.packageName}</td>
                                        <td className="border border-gray-400 text-xs p-1 text-right ">
                                            {item?.qty || 0}
                                        </td>
                                        <td className="border border-gray-400 text-xs p-1 text-right ">{item.price || 0}</td>
                                        <td className="border border-gray-400 text-xs p-1 text-right ">{item?.surChargePrice ? item?.surChargePrice : isCalculateSurchargePrice(item.price)}</td>

                                        <td className="border border-gray-400 text-xs p-1 text-right ">

                                            <input type="number" className='p-1 text-right w-full' value={packageDiscount} onChange={(e) => {
                                                setPackageDiscount(e.target.value)
                                            }} />


                                        </td>
                                        <td className="border border-gray-400 text-xs p-1 text-right">{(!item.qty || !item.price) ? 0 : (((parseFloat(item.qty) * parseFloat(item.price)) + parseFloat(item?.surChargePrice || isCalculateSurchargePrice(item.price))) - (item.discount ? item.discount : 0)).toFixed(2)}</td>


                                    </tr>
                                )}
                            </tbody>
                        </table>
                        : ""

                }




                {
                    testDetailsData.length > 0 ? (

                        <>
                            <div className='mt-7'>
                                <table className="table-fixed w-full">
                                    <thead>
                                        <tr>
                                            <th className="border border-gray-400 text-xs p-1 w-9">S.No</th>

                                            <th className="border border-gray-400 text-xs p-1 w-60">Test</th>
                                            <th className="border border-gray-400 text-xs p-1 w-32">Quantity</th>
                                            <th className="border border-gray-400 text-xs p-1 w-32">Rate</th>
                                            <th className="border border-gray-400 text-xs p-1 w-32">SurChargePrice</th>

                                            <th className="border border-gray-400 text-xs p-1 w-32">Discount</th>

                                            <th className="border border-gray-400 text-xs p-1 w-32">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {testDetailsData.map((item, index) => (
                                            <tr key={index}>
                                                <td className="border border-gray-400 text-xs p-1 ">{index + 1}</td>

                                                <td className="border border-gray-400 text-xs p-1">{item.testName}</td>
                                                <td className="border border-gray-400 text-xs p-1 ">
                                                    <input autoFocus type="number" className='p-1 w-full text-right ' value={item?.qty ? item?.qty : 0} onChange={(e) => {
                                                        handleInputChange(e.target.value, index, 'qty')
                                                    }} />
                                                </td>
                                                <td className="border border-gray-400 text-xs p-1 text-right ">{item.price || 0}</td>
                                                <td className="border border-gray-400 text-xs p-1 text-right">{item.surChargePrice ? item.surChargePrice : isCalculateSurchargePrice(item.price)}</td>

                                                <td className="border border-gray-400 text-xs p-1 ">
                                                    <input type="number" className='p-1 text-right w-full' value={item?.discount ? item?.discount : 0} onChange={(e) => {
                                                        handleInputChange(e.target.value, index, 'discount')
                                                    }} />
                                                </td>

                                                <td className="border border-gray-400 text-xs p-1 text-right">{(!item.qty || !item.price) ? 0 : (((parseFloat(item.qty) * parseFloat(item.price)) + parseFloat(item?.surChargePrice || isCalculateSurchargePrice(item.price))) - (item.discount ? item.discount : 0)).toFixed(2)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>


                            </div>

                            <div className='flex justify-end '>
                                <button

                                    className='bg-green-400 rounded-lg border border-black text-xs w-16 h-9 p-1 mt-5'
                                    onClick={() => { handleSave("updated") }}
                                >Save

                                </button>
                            </div>

                        </>
                    )


                        : ""


                }


                <div className='flex align-items-end justify-end mr-32 mt-7 text-xs'>

                    <div className='grid grid-cols-1'>
                        <div className='mb-2'>
                            Analysis Charge:
                        </div>
                        {data?.data?.taxDetails?.map(val =>
                            <h1>
                                {`${val?.taxName} @ ${val.taxPercent} % & ${"TDS"} @ ${val?.tdsPercent} % : ${parseFloat(val?.taxAmount).toFixed(2)}`}
                            </h1>

                        )}

                        <div className='mb-2'>
                            RoundOff:  {data?.data?.totalTaxAmount ? parseFloat(data?.data?.totalTaxAmount).toFixed(2) : 0.00}

                        </div>

                        <h1>
                            NetCharge : {data?.data?.netAmount ? parseFloat(data?.data?.netAmount).toFixed(2) : 0.00}
                        </h1>
                        <h1>
                            Grand Total : {data?.data?.netAmount ? parseFloat(data?.data?.netAmount).toFixed(2) : 0.00}
                        </h1>


                    </div>

                </div>


                <div className='mt-3'>
                    <h1 className='text-sm font-bold'>
                        TERMS AND CONDITIONS
                    </h1>
                    <ol className='text-xs'>

                        <li>
                            1. Attached is the Proforma -Invoice for your approval. Kindly note, that this not a final invoice and the value could change depending on the nature of rendered job.
                        </li>
                        <li>
                            2. Our Services will be subject to TDS @ 2% under section 194J of the Income Tax Act 1961
                        </li>
                        <li>
                            3. Validity: 15 working days after the date of this Proforma invoice issued.
                        </li>
                        <li>
                            4. Any dispute arising out of this transaction / Contract will be referred to institutional arbitration council of Tirupur as per the rules and regulations of Arbitration
                            Council of Tirupur and the award passed will be binding on us.
                        </li>


                    </ol>

                </div>

                <div className='mt-2 text-xs'>
                    <h1 className='font-bold'>
                        OUR BANK DETAILS
                    </h1>
                    <h1 className='mt-1'>
                        <strong> ACCOUNT NAME:</strong> {data?.data?.bankDetails?.accountName ? data?.data?.bankDetails?.accountName : ""}    </h1>
                    <h1><strong> ACCOUNT NO:</strong>  {data?.data?.bankDetails?.accountNo ? data?.data?.bankDetails?.accountNo : ""}   </h1>
                    <h1><strong> IFSC.CODE:</strong>  {data?.data?.bankDetails?.ifsc ? data?.data?.bankDetails?.ifsc : ""}    </h1>
                    <h1><strong> BRANCH:</strong>  {data?.data?.bankDetails?.branch ? data?.data?.bankDetails?.branch : ""}
                    </h1>

                    <h1><strong> GST NO :</strong>  33AADCS8162K1Z4 </h1>

                </div>


                <div className="grid justify-end mt-2 mr-20 ml-20 text-xs">
                    <div className='w-ful mt-2 mb-2'>
                        {Boolean(image) ?
                            <img style={{ height: imageHeight, width: imageWidth, objectFit: 'fit' }}
                                src={getImageUrlPath(image)}
                            />
                            :
                            <img src={empty} className='w-full text-center' />
                        }
                    </div>
                    <h1>
                        Verified By &   Authorized By
                    </h1>
                    {/* <h1>
                    Authorized By
                </h1> */}
                </div>


                <div className='text-xs mt-5'>
                    <FactoryAddress />
                </div>


            </div>
        </>
    );
};

export default PrintProformaInvoice;
