import { Text, View } from '@react-pdf/renderer'
import React from 'react'
import tw from '../../../../Utils/tailwind-react-pdf'

const ResultValue = ({ item, testIndex, test, reportData }) => {

  function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  function checkIsData(testId, allResults) {
    let findData = allResults?.find(i => parseInt(i.testId) === parseInt(testId))?.data

    return findData
  }

  if (item.isRemovalForce) return (
    <View style={tw('w-full flex flex-row gap-x-3 overflow-auto ')}>
      <Text style={tw('capitalize w-[25%]')}>
        {test?.typeOfComponent}
      </Text>
      <Text style={tw('capitalize w-[25%]')}>
        {test?.areaOfComponent}
      </Text>
      <Text style={tw('capitalize w-[25%]')}>
        {test?.reportValue}{(!(test?.result && test?.isResult)) ? "*" : ""}
      </Text>
      <Text style={tw('capitalize w-[25%]')}>

        {test?.modeOfFailure}
      </Text>
    </View>
  )
  if (item.isDimensionalStablity) return (
    <View style={tw('w-full flex flex-row gap-x-3 overflow-auto ')}>
      <Text style={tw('capitalize w-[25%] text-center')}>
      </Text>
      <Text style={tw('capitalize w-[25%] text-center')}>
        {test?.original}
      </Text>
      <Text style={tw('capitalize w-[25%] text-center')}>
        {test?.afterWash}
      </Text>
      <Text style={tw('capitalize w-[25%] text-center')}>
        {test?.reportValue} {(!(test?.result) && (test?.isResult) && (!checkIsData(item?.testId, reportData?.testDetailsSummary?.allResults))) ? "*" : ""}

        {/* {test?.reportValue}{(!(test?.result && test?.isResult)) ? "*" : ""} */}
      </Text>
    </View>
  )
  return (
    <Text style={tw(`${(!(test?.result) && (test?.isResult) && (!checkIsData(item?.testId, reportData?.testDetailsSummary?.allResults))) ? "font-bold" : ""} capitalize`)} >
      {(test?.decimalValue && isNumeric(test?.reportValue)) ? parseFloat(test?.reportValue).toFixed(test?.decimalValue) : test?.reportValue}
      {test?.isResult ? ((!(test?.result) && (!checkIsData(item?.testId, reportData?.testDetailsSummary?.allResults))) ? "*" : "") : ""}
    </Text >

  )
}

export default ResultValue