import React from 'react'
import { useGetBlendMasterQuery } from '../../../redux/ErpServices/BlendMasterServices';
import secureLocalStorage from 'react-secure-storage';
import { findFromList } from '../../../Utils/helper';

const BlendResultInput = ({ singleParameter, result, resultIndex, item, itemIndex, handleChangeBlend, labTestRequirementDetailsId, isEditable, childRecord }) => {
    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),

    };


    const { data: blendDatas } = useGetBlendMasterQuery({ params });
    return (
        <>

            <td key={resultIndex}>
                <table>
                    <thead>
                        <tr>
                            <th className='border border-gray-500 text-xs'>
                                Blend
                            </th>
                            <th className='border border-gray-500 text-xs '>
                                Percentage
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(result?.claimedValue ? result.claimedValue : []).map((item, itemIndex) =>
                            <tr key={resultIndex}>
                                <td className='border border-gray-500 p-0.5 text-xs ' key={resultIndex}>
                                    {findFromList(item?.blendId, blendDatas?.data, "name")}
                                </td>
                                <td className='border border-gray-500  p-1 ' key={resultIndex}>
                                    <input type="text" className="ml-4 text-center w-40 focus:outline-none " value={item?.percentage}
                                        onChange={(e) => handleChangeBlend(e.target.value, labTestRequirementDetailsId, resultIndex, itemIndex, "percentage")}
                                        readOnly={!isEditable} disabled={(childRecord.current > 0)} />
                                </td>

                            </tr>
                        )}
                    </tbody>
                </table>
            </td>

        </>
    )
}

export default BlendResultInput
