import React, { useEffect, useState, useRef, useCallback } from "react";
import secureLocalStorage from "react-secure-storage";
import {
    useGetLabTestNameMasterQuery,
    useGetLabTestNameMasterByIdQuery,
    useAddLabTestNameMasterMutation,
    useUpdateLabTestNameMasterMutation,
    useDeleteLabTestNameMasterMutation,
} from "../../../redux//LabServices/LabTestNameMasterServices";
import { useGetLabTestGroupMasterQuery } from "../../../redux/LabServices/LabTestGroupMasterServices"
import { useGetLabTestSubGroupMasterQuery } from "../../../redux/LabServices/LabTestSubGroupMasterServices"
import FormHeader from "../../../Basic/components/FormHeader";
import FormReport from "../../../Basic/components/FormReportTemplate";
import { toast } from "react-toastify";
import { CheckBox, DropdownInput, LongTextInput } from "../../../Inputs";
import ReportTemplate from '../../../Basic/components/ReportTemplate'
import { dropDownListObject } from "../../../Utils/contructObject";
import { findFromList } from "../../../Utils/helper";
import { Loader } from "../../../Basic/components";

const MODEL = "Lab Test Master";

export default function Form() {
    const [form, setForm] = useState(false);

    const [readOnly, setReadOnly] = useState(false);
    const [id, setId] = useState("");
    const [groupId, setGroupId] = useState("");
    const [subGroupId, setSubGroupId] = useState("");
    const [name, setName] = useState("");
    const [active, setActive] = useState(true);
    const [isRemovalForce, setIsRemovalForce] = useState(false);
    const [isDimensionalStablity, setIsDimensionalStablity] = useState(false);




    const [searchValue, setSearchValue] = useState("");
    const childRecord = useRef(0);

    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
    };

    const { data: labTestGroupList } =
        useGetLabTestGroupMasterQuery({ params });


    const { data: labTestSubGroupList } =
        useGetLabTestSubGroupMasterQuery({ params });



    const { data: allData, isLoading, isFetching } = useGetLabTestNameMasterQuery({ params, searchParams: searchValue });
    const {
        data: singleData,
        isFetching: isSingleFetching,
        isLoading: isSingleLoading,
    } = useGetLabTestNameMasterByIdQuery(id, { skip: !id });


    const [addData] = useAddLabTestNameMasterMutation();
    const [updateData] = useUpdateLabTestNameMasterMutation();
    const [removeData] = useDeleteLabTestNameMasterMutation();


    const syncFormWithDb = useCallback(
        (data) => {
            function getGroupId(subGroupId) {
                return findFromList(subGroupId, labTestSubGroupList.data, "groupId");
            }
            if (id) setReadOnly(true);
            else setReadOnly(false)
            setName(data?.name ? data.name : "");
            setSubGroupId(data?.subGroupId ? data.subGroupId : "");
            setGroupId(data?.subGroupId ? getGroupId(data.subGroupId) : "");
            setActive(id ? (data?.active ? data.active : false) : true);
            setIsRemovalForce(data?.isRemovalForce ? data.isRemovalForce : false);
            setIsDimensionalStablity(data?.isDimensionalStablity ? data.isDimensionalStablity : false);
        },
        [id, labTestSubGroupList]
    );

    useEffect(() => {
        syncFormWithDb(singleData?.data);
    }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

    const data = {
        id, name, isRemovalForce, isDimensionalStablity, subGroupId, groupId, active, companyId: secureLocalStorage.getItem(sessionStorage.getItem("sessionId") + "userCompanyId")
    }

    const validateData = (data) => {
        if (data.name && data.subGroupId && data.groupId) {
            return true;
        }
        return false;
    }

    const handleSubmitCustom = async (callback, data, text) => {
        try {
            let returnData = await callback(data).unwrap();
            setId("")
            syncFormWithDb(undefined);
            toast.success(text + "Successfully");
        } catch (error) {
            console.log("handle");
        }
    };

    const saveData = () => {
        if (!validateData(data)) {
            toast.info("Please fill all required fields...!", {
                position: "top-center",
            });
            return;
        }
        if (!window.confirm("Are you sure save the details ...?")) {
            return;
        }
        if (id) {
            handleSubmitCustom(updateData, data, "Updated");
        } else {
            handleSubmitCustom(addData, data, "Added");
        }
    };

    const deleteData = async () => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return;
            }
            try {
                await removeData(id)
                setId("");
                toast.success("Deleted Successfully");
            } catch (error) {
                toast.error("something went wrong");
            }
        }
    };

    const handleKeyDown = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === "s") {
            event.preventDefault();
            saveData();
        }
    };

    const onNew = () => {
        setId("");
        setForm(true);
        setSearchValue("");
        syncFormWithDb(undefined)
        setReadOnly(false);
    };

    function onDataClick(id) {
        setId(id);
        setForm(true);
    }



    const tableHeaders = [
        "Name", "Status"
    ]
    const tableDataNames = ["dataObj.name", 'dataObj.active ? ACTIVE : INACTIVE']

    if (!labTestGroupList || !labTestSubGroupList) return <Loader />

    if (!form)
        return (
            <ReportTemplate
                heading={MODEL}
                tableHeaders={tableHeaders}
                tableDataNames={tableDataNames}
                loading={
                    isLoading || isFetching
                }
                setForm={setForm}
                data={allData?.data}
                onClick={onDataClick}
                onNew={onNew}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
        );

    return (
        <div
            onKeyDown={handleKeyDown}
            className="md:items-start md:justify-items-center grid h-full bg-theme"
        >
            <div className="flex flex-col frame w-full h-full">
                <FormHeader
                    onNew={onNew}
                    onClose={() => {
                        setForm(false);
                        setSearchValue("");
                    }}
                    model={MODEL}
                    saveData={saveData}
                    setReadOnly={setReadOnly}
                    deleteData={deleteData}

                />
                <div className="flex-1 grid grid-cols-1 md:grid-cols-4 gap-x-2 overflow-clip">
                    <div className="col-span-3 border overflow-auto">
                        <div className='col-span-3 overflow-auto'>
                            <div className='mr-1 md:ml-2 '>
                                <fieldset className='frame my-1'>
                                    <legend className='sub-heading'>Test Name Info</legend>
                                    <div className='grid grid-cols-4 my-2'>
                                        <DropdownInput name="Test Group" options={dropDownListObject(id ? labTestGroupList.data : labTestGroupList.data.filter(item => item.active), "name", "id")} value={groupId} setValue={setGroupId} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <DropdownInput name="Test Sub Group"
                                            options={dropDownListObject((id ?
                                                labTestSubGroupList.data.filter(item => parseInt(groupId) === parseInt(item.groupId))
                                                : labTestSubGroupList.data.filter(item => parseInt(groupId) === parseInt(item.groupId)).filter(item => item.active)),
                                                "name", "id")} value={subGroupId} setValue={setSubGroupId} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />
                                        <div>
                                            <CheckBox name="Active" readOnly={readOnly} value={active} setValue={setActive} />
                                            <CheckBox name="Attached Component Strength" readOnly={readOnly} value={isRemovalForce} setValue={setIsRemovalForce} />
                                            <CheckBox name="Dimensional Stability" readOnly={readOnly} value={isDimensionalStablity} setValue={setIsDimensionalStablity} />
                                        </div>
                                    </div>
                                    <div className="">
                                        <LongTextInput className={"h-6 border border-gray-600 rounded w-9/12"} name="Name" type="text" value={name} setValue={setName} required={true} readOnly={readOnly} disabled={(childRecord.current > 0)} />



                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div className="frame hidden md:block overflow-x-hidden">
                        <FormReport
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            setId={setId}
                            tableHeaders={tableHeaders}
                            tableDataNames={tableDataNames}
                            data={allData?.data}
                            loading={
                                isLoading || isFetching
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
