import React from 'react'


const TestAllParameters = ({ item, val, dataObj }) => {




    return (
        <>

            <span className=''>
                *{item?.parameterName || ""}<br />
            </span>





        </>
    )
}

export default TestAllParameters