import React from 'react'


const TestAllReq = ({ item, val, dataObj }) => {




    return (
        <>

            <span>
                {item?.resultValue[0]?.requirementValue || ""}<br />
            </span>





        </>
    )
}

export default TestAllReq