import React from 'react'
import { View, Image } from '@react-pdf/renderer';
import logo from "../assets/SRG company logo.png"
import tw from '../Utils/tailwind-react-pdf'
const WaterMarkSymbol = () => {
    return <View fixed style={{
        position: 'absolute',
        top: '50%',
        left: '30%',
        transform: 'translate(-50%, -50%) rotate(-45deg)',
        width: "300px",
        zIndex: -1,
        opacity: 0.2,
    }}>
        <Image style={tw("h-full w-full")} src={logo} />
    </View>
}

export default WaterMarkSymbol
