import React from 'react'
import { handleMailSendWithMultipleAttachments } from '../../../Utils/helper';
import logo from "../../../../src/assets/srgmaillogo.png"
import { DELETE } from '../../../icons';
import { useUpdateRegistrationMutation } from '../../../redux/LabServices/RegistrationServices';

const MailAttachment = ({
    washCareData, testComments, setTestComments, splitTest, passFail, reportData, attachments, setAttachments, activeRegistrationId
}) => {

    let testReportNo = reportData?.data?.testReportNo ? reportData?.data?.testReportNo : ""
    if (splitTest === "ACCREDITATION") {
        testReportNo = testReportNo.concat("A");
    } else if (splitTest === "NONACCREDITATION") {
        testReportNo = testReportNo.concat("B");
    }
    let companyEmail = reportData?.data?.email ? reportData?.data?.email : "";

    const [updateData] = useUpdateRegistrationMutation();


    const handleSubmitCustom = async (callback, mailData, text, isDataClear = false) => {
        try {
            await callback(mailData).unwrap();

        } catch (error) {
            console.log("handle");
        }
    };

    const handlUpdateMail = (isDataClear = false) => {

        handleSubmitCustom(updateData, { registrationId: activeRegistrationId, mailUpdate: true }, "Updated", isDataClear);
    };




    return (
        <div className='flex justify-end
        '>
            <div className='p-6 font-times bg-gray-200  w-[300px]'>
                <div className='text-xl font-bold text-center'>Mail</div>
                <table>
                    <thead>
                        <tr className='border border-black'>
                            <th className='border border-black'>Result</th>
                            <th className='border border-black'> Accreditation</th>
                            <th className='border border-black'>Buyer</th>
                            <th className='border border-black'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {attachments.map((file, index) =>
                            <tr key={index}>
                                <td className='border border-black'>{file.result}</td>
                                <td className='border border-black'>{file.accredidation}</td>
                                <td className='border border-black'>{file.buyer}</td>
                                <td className='border border-black' onClick={() => { setAttachments(prev => prev.filter((_, i) => i !== index)) }}>
                                    {DELETE}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <div className='flex justify-end mt-2'>
                    <button className='bg-green-400 p-1 rounded-md'
                        onClick={() => {
                            handleMailSendWithMultipleAttachments(attachments, companyEmail, `${"Test Report  No :"}${reportData?.data?.testReportNo}/${reportData?.data?.testStage || ""}/${reportData?.data?.styleNo || ""}/${reportData?.data?.companyName || ""}/${reportData?.data?.buyerName || ""}`, reportData?.data?.testReportNo, logo, testReportNo);
                            handlUpdateMail()
                        }}>Send Mail</button>
                </div>
            </div>
        </div>

    )
}

export default MailAttachment