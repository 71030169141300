import React from 'react'
import logo from "../../../assets/SRG.png"

import { Document, Page, Text, View, StyleSheet, Image, PDFViewer } from '@react-pdf/renderer';
const Header = ({}) => {

    // const styles = StyleSheet.create({
    //     page: {
    //         flexDirection: 'column',
    //         backgroundColor: '#ffffff',
    //         padding: 20,
    //         fontFamily: 'Helvetica',
    //         fontSize: 20,
    //     },
    //     content: {
    //         flexGrow: 1,
    //     },
    //     footer: {
    //         position: 'absolute',
    //         bottom: 20,
    //         left: 0,
    //         right: 0,
    //         textAlign: 'center',
    //         fontSize: 10,
    //     },
    //     header:{
    //         position: 'absolute',
    //         top: 10,
    //         left: 0,
    //         right: 0,
    //         textAlign: 'center',
    //         fontSize: 10,
    //         flex: 1,

    //     },
    //     text:{
    //         textAlign: 'center',
    //     },
    //     image:{
    //         width:80,
    //         height:80,
    //         backgroundColor:"gray",
    //         padding:2
    //     }
    // });


  return (
    
    //     <>
    // <div className='flex gap-x-5 justify-center'>
    // <div className="">
    //       <img className="bg-gray-400 rounded-xl   w-36" src={logo} />
    //       </div> 
    //       <div className="text-center mt-3 text-md font-bold">
    //       TEXTILE TESTING LABORATORY
    //       </div>
    // </div>

    // </>


<>
   
    <Image style={{
  borderRadius: "5",
  width: "100",
  marginLeft:20,
  backgroundColor: "#9CA3AF"
}} src={logo} />
    
        <Text style={{
  marginTop: "1",
  fontWeight: 500,
  fontSize:15,
  textAlign: "center"
}}>
        TEXTILE TESTING LABORATORY
        </Text>
         
         
    </>
    
    
  )
}

export default Header