import React from 'react'

import CardWrapper from './CardWrapper';

const NumericCard = ({ misData, allData, adminDataLength, allDataLength }) => {
    let adminWithOutSaveDataLen = 0;
    let adminWithSaveDataLen = 0;

    allDataLength?.adminData?.forEach(element => {
        let forAdminFilterArray = element?.RegistrationSampleDetails?.flatMap(i => i.ResultEntry)
        forAdminFilterArray = forAdminFilterArray[forAdminFilterArray?.length - 1]?.LabTestResultDetails?.filter(val => !val.isDeleted)
        let resultEntryLength = forAdminFilterArray?.flatMap(k => k.resultValue)
        resultEntryLength = resultEntryLength?.some(i =>
            Object.values(i).some(value => value === null))
        if (Boolean(resultEntryLength)) {
            adminWithOutSaveDataLen = adminWithOutSaveDataLen + 1
        }
        else {
            adminWithSaveDataLen = adminWithSaveDataLen + 1
        }
    });




    const totalRegistration = allData?.filter(val => !(val.AmendedRegistration))?.length;
    // const totalRegistration = allData?.length;
    const totalAdminCompleted = allData?.filter(val => !(val.AmendedRegistration))?.filter(val => (val.isFinalReportVerification && val.resultEntriesCompleted))?.length;
    // const resultEntryPartial = allDataLength?.resultDataPartialLength;
    const resultEntryPartial = allData?.filter(val => !(val.AmendedRegistration))?.filter(val => !(val.resultEntriesCompleted))?.length
    const totalMailWip = allData?.filter(val => !(val.AmendedRegistration))?.filter(val => !(val.isMailSended) && (val.resultEntriesCompleted) && (val.isFinalReportVerification) && (val.isRegistrationCompleted))?.length;
    const totalWipInvoice = allData?.filter(val => !(val.AmendedRegistration))?.filter(val => !(val.isInvoiceMailSended) && (val.isMailSended))?.length;
    const totalInvoiceSended = allData?.filter(val => !(val.AmendedRegistration))?.filter(val => val.isInvoiceMailSended)?.length;
    const totalFinalWip = allData?.filter(val => !(val.AmendedRegistration))?.filter(val => (!(val.isRegistrationCompleted) && (val.isFinalReportVerification) && (val.resultEntriesCompleted)))?.length;
    const totalFinalCompleted = allData?.filter(val => !(val.AmendedRegistration))?.filter(val => ((val.isRegistrationCompleted) && (val.isFinalReportVerification) && (val.resultEntriesCompleted)))?.length;
    // const resultEntryCompleted = parseInt(allDataLength?.resultDataCompletedLength);
    const resultEntryCompleted = allData?.filter(val => !(val.AmendedRegistration))?.filter(val => (val.resultEntriesCompleted))?.length;
    const totalMailSended = allData?.filter(val => !(val.AmendedRegistration))?.filter(val => val.isMailSended)?.length;
    const totalTurnOver = misData?.data?.totalTurnOver;
    const profit = misData?.data?.profit;
    const newCustomers = misData?.data?.newCustomers;
    const topCustomers = misData?.data?.topCustomers;
    const loss = misData?.data?.loss;
    const data = [
        {
            name: "Total Registration",
            borderColor: "#1F588B",
            Total: `${(totalRegistration || 0).toLocaleString()}`,

            qty: `${(parseInt(resultEntryPartial || 0)).toLocaleString()}`,

            value: `${(resultEntryCompleted || 0).toLocaleString()}`,
            previousValue: `₹${(totalTurnOver?.prevValue || 0).toLocaleString()}`,
            previousQty: `₹${(totalTurnOver?.prevQty || 0).toLocaleString()}`,

        },
        {
            name: "Admin",
            borderColor: "#62AAA3",

            Total: `${(resultEntryCompleted || 0).toLocaleString()}`,
            qty: `${parseInt((adminWithOutSaveDataLen || 0) + (adminWithSaveDataLen || 0)).toLocaleString()}`,
            value: `${parseInt((totalAdminCompleted || 0)).toLocaleString()}`,

            previousValue: `₹${(profit?.prevValue || 0).toLocaleString()}`,
            previousQty: `₹${(profit?.prevQty || 0).toLocaleString()}`,


        },
        {
            name: "Final",
            borderColor: "border-[#96A669]",

            Total: `${(totalAdminCompleted || 0).toLocaleString()}`,
            value: `${parseInt(totalFinalCompleted || 0).toLocaleString()}`,
            qty: `${parseInt(totalFinalWip || 0).toLocaleString()}`,
            previousValue: `₹${(newCustomers?.prevValue || 0).toLocaleString()}`,
            previousQty: `₹${(newCustomers?.prevQty || 0).toLocaleString()}`,
        },
        {
            name: "Mail",
            borderColor: "border-[#D49B37]",
            Total: `${parseInt(totalFinalCompleted || 0).toLocaleString()}`,
            value: `${(totalMailSended || 0).toLocaleString().toLocaleString()}`,
            qty: `${parseInt(totalMailWip || 0).toLocaleString()}`,
            previousValue: `₹${(topCustomers?.prevValue || 0).toLocaleString()}`,
            previousQty: `₹${(topCustomers?.prevQty || 0).toLocaleString()}`,
        },
        {
            name: "Invoice",
            borderColor: "border-[#D49B37]",
            Total: `${(totalMailSended || 0).toLocaleString()}`,
            value: `${(totalInvoiceSended || 0).toLocaleString()}`,
            qty: `${(totalWipInvoice || 0).toLocaleString()}`,
            previousValue: `₹${(loss?.prevValue || 0).toLocaleString()}`,
            previousQty: `₹${(loss?.prevQty || 0).toLocaleString()}`,
        },
    ]
    return (
        <div className='flex justify-around w-full'>
            {data.map((val, i) =>
                <div key={i} className='w-[24.5%] text-center '>
                    <CardWrapper name={val.name} >
                        <div className={`h-full flex flex-col justify-between items-between  border-4 cuttedBorder${i + 1} bgBorder${i + 1} pt-2 `}
                        >



                            <div className='flex justify-center px-4'>
                                <div>
                                    <h1 className='text-sm font-semibold'>Total</h1>
                                    <span className='text-lg font-bold'>
                                        {val.Total}
                                    </span></div>

                            </div>
                            {/* <div className=' h-[2rem] flex items-center'>  <CanvasJSChart options={val.name === 'Orders' ? options1 : val.name === 'Shipped' ? options2 : val.name === 'OCR Pending' ? options3 : val.name === 'WIP' ? options4 : val.name === 'Pre Budget' ? options5 : null} />

                            </div> */}

                            <div className='flex  justify-between px-4'>
                                <div>
                                    <h1 className='text-sm font-semibold'>{val.name === "Total Registration" ? "Result Entry Wip" : "WIP"}</h1>
                                    <span className='text-lg font-bold'>
                                        {val.qty}
                                    </span></div>
                                <span className='text-lg font-bold'>
                                    <h1 className='text-sm font-semibold'>Completed</h1>
                                    {val.value}
                                </span>
                            </div>






                            {/* <div className='flex justify-between px-4 text-gray-800 text-[12px]'>
                                <span>
                                    <div className='font-medium '>
                                        Prev Qty
                                    </div>
                                    <div>
                                        {val.previousQty}
                                    </div>
                                </span>
                                <span>
                                    <div className='font-medium '>Prev Val</div>
                                    <div>{val.previousValue}</div>
                                </span>

                            </div> */}
                        </div>
                    </CardWrapper>
                </div>
            )}
        </div>

    )
}
export default NumericCard;