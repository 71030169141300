import React, { useEffect, useState } from 'react'
import CommonDashboard from './DashBoardCommon'
import NumericCard from './NumericCard'
import { DropdownInput } from '../../../Inputs'
import { filterDays } from '../../../Utils/DropdownData'
import { useGetFinYearQuery } from '../../../redux/services/FinYearMasterService'
import { dropDownListObject } from '../../../Utils/contructObject'
import secureLocalStorage from 'react-secure-storage'
import { getYearShortCode } from '../../../Utils/helper'
import { useGetRegistrationQuery } from '../../../redux/LabServices/RegistrationServices'
import moment from 'moment'
import Modal from "../../../UiComponents/Modal";
import SelectionModel from './SelectionModel'
import { Loader } from '../../../Basic/components'

export default function Form() {

    const [isSelectionModel, setIsSelectionModel] = useState(false)
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [filterByDays, setFilterByDays] = useState("")
    const [finYearId, setFinYearId] = useState();
    const [finYearDatas, setFinYearDatas] = useState([])

    const branchId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "currentBranchId"
    )


    const companyId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userCompanyId"
    )
    const params = {
        branchId, companyId
    };
    const { data: finYearData } = useGetFinYearQuery({ params });
    const { data: allData, isLoading, isFetching } = useGetRegistrationQuery({ params: { branchId, fromDate, toDate, isOverAllReport: true } });



    useEffect(() => {
        let newArray = [];
        finYearData?.data?.forEach(element => {
            newArray?.push({
                shortCode: getYearShortCode(element?.from, element?.to),
                from: element?.from,
                to: element?.to,
                id: element?.id,
                active: element?.active,
                companyId: element?.companyId,

            })
        });
        setFinYearDatas(newArray)
    }, [finYearData])


    useEffect(() => {
        if (finYearId) return
        setFinYearId(finYearDatas?.find(val => val.active)?.id);
        setFromDate(finYearDatas?.find(val => val.active)?.from);
        setToDate(finYearDatas?.find(val => val.active)?.to);
    }, [finYearData, finYearDatas])

    useEffect(() => {
        setFromDate(finYearDatas?.find(val => val.id == finYearId)?.from);
        setToDate(finYearDatas?.find(val => val.id == finYearId)?.to);
    }, [finYearId])

    if (isLoading || isFetching) return <Loader />
    return (
        <div>
            <Modal isOpen={isSelectionModel} onClose={() => setIsSelectionModel(false)} widthClass={"px-2 h-[40%] w-[25%] bg-white p-1"}>

                <SelectionModel fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} finYearId={finYearId} finYearDatas={finYearDatas} setFinYearId={setFinYearId}
                    filterByDays={filterByDays} setFilterByDays={setFilterByDays}
                />
            </Modal>
            <div className='flex justify-end pr-5 pb-1'>
                <button className="bg-blue-400 text-white rounded-md p-2  w-32 text-xs tracking-widest"
                    onClick={() => {
                        setIsSelectionModel(true)
                    }}
                >Filter</button>
            </div>
            <NumericCard allData={allData?.data} adminDataLength={allData?.adminDataLength} allDataLength={allData} />
            <CommonDashboard fromDate={fromDate} toDate={toDate} isOverAllReport={true} key={[fromDate, toDate]} />
        </div>
    )
}


