import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { findFromList, groupBy } from '../../../Utils/helper'
import { useGetLabTestMethodMasterQuery } from '../../../redux/LabServices/LabTestMethodMasterServices';
import { useGetLabTestNameMasterQuery } from '../../../redux/LabServices/LabTestNameMasterServices';
import secureLocalStorage from 'react-secure-storage';
import { useGetLabTestConditionMasterQuery } from '../../../redux/LabServices/LabTestConditionMasterServices';
import { useGetLabTestRequirementMasterQuery } from '../../../redux/LabServices/LabTestRequirementMasterServices';
import { useGetLabTestAnalyteMasterByIdQuery, useGetLabTestAnalyteMasterQuery } from '../../../redux/LabServices/LabTestAnalyteMasterServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faUserPlus } from '@fortawesome/free-solid-svg-icons';

import { useGetFormulaMasterQuery } from '../../../redux/LabServices/FormulaMasterServices';
import ResultInput from './ResultInput';
import { useGetFiberContentMasterByIdQuery } from '../../../redux/ErpServices/FiberContentMasterServices';
import { TICK_ICON } from '../../../icons';
import RemovalForceComponent from './RemovalForceComponent';
import ModeOfFailure from './ModeOfFailure';
import { GenerateButton } from '../../../Buttons';
import ResultHeadingForDimensional from './ResultHeadingForDimensional';

const SampleTestDetails = ({ registrationId, isLastResultExist, requirementList, setRequirementList, isFiberContent, setIsFiberContent, setFiberIndex, fiberIndex, setFiberHeading, fiberHeading, setFiberContentId, fiberContentId, sampleTwoId, id, setId, setResultDetails, resultDetails, item, itemIndex, singleRegistrationData, matchingSampleDetails, registerId, registrationSampleDetailsId, readOnly, childRecord, results, resultDatas }) => {

    const [subHeading, setSubHeading] = useState([]);
    const [isRemovalForce, setIsRemovalForce] = useState(false);
    const [lastSubHeading, setLastSubHeading] = useState("");
    const [isDimensionalStability, setIsDimensionalStability] = useState(false);
    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),

    };

    const { data: labTestRequirementList, isLoading: isLabRequirementLoading, isFetching: isLabRequirementFetching } =
        useGetLabTestRequirementMasterQuery({
            params: {
                ...params,
                testId: item?.testId ? item.testId : undefined,
                partyId: singleRegistrationData?.data?.buyerId ? singleRegistrationData.data.buyerId : undefined,
                sampleTypeOneId: matchingSampleDetails?.sampleOneId ? matchingSampleDetails.sampleOneId : undefined,
                sampleTypeThreeId: matchingSampleDetails?.sampleThreeId ? matchingSampleDetails.sampleThreeId : undefined,
                sampleTypeFourId: matchingSampleDetails?.sampleFourId ? matchingSampleDetails.sampleFourId : undefined,
                testConditionId: item?.conditionId ? item.conditionId : undefined,
            }
        });


    const { data: labTestParameterList } =
        useGetLabTestAnalyteMasterQuery({ params });

    const parameterList = labTestParameterList?.data.find((items) => ((parseInt(items.testId) === parseInt(item?.testId)) && (parseInt(items.SampleDescriptionOne.id) === parseInt(matchingSampleDetails?.sampleOneId)) && (items?.active)))



    const parameterListId = parameterList?.id ? parameterList.id : ""
    const { data: singleLabTestParameterList, isLoading: isLabParameterLoading, isFetching: isLabParameterFetching } =
        useGetLabTestAnalyteMasterByIdQuery(parameterListId, { skip: !parameterListId });


    useEffect(() => {
        const selectedOption = singleLabTestParameterList?.data?.selectedOption ? singleLabTestParameterList?.data?.selectedOption : "";
        setIsFiberContent(selectedOption === "CLAIMEDFIBERCONTENT");
    }, [singleLabTestParameterList, isLabParameterLoading, isLabParameterFetching, setIsFiberContent])

    const { data: labTestNameList, isLoading: isLabTestLoading, isFetching: isLabTestFetching } =
        useGetLabTestNameMasterQuery({ params });


    const { data: labTestMethodList } =
        useGetLabTestMethodMasterQuery({ params });

    const { data: formulaList } = useGetFormulaMasterQuery({ params });

    const { data: labTestConditionList } =
        useGetLabTestConditionMasterQuery({ params });

    const {
        data: singleFiber,
        isFetching: isSingleFiberFetching,
        isLoading: isSingleFiberLoading,
    } = useGetFiberContentMasterByIdQuery(fiberContentId, { skip: !fiberContentId });



    const requirementId = useMemo(() => (resultDatas || [])
        .filter(i => parseInt(i.testId) === parseInt(item.testId))[0]?.LabTestRequirementDetails?.labTestRequirementId, [resultDatas, item])





    useEffect(() => {

        if (!isFiberContent) return
        if (isLabRequirementLoading || isLabRequirementFetching || !labTestRequirementList?.data) return

        setResultDetails(prev => {
            let labTestRequirementDetailsTest;
            if (requirementId) {
                labTestRequirementDetailsTest = (labTestRequirementList?.data || []).find(i => parseInt(i.id) === parseInt(requirementId));
            } else {
                labTestRequirementDetailsTest = labTestRequirementList.data.filter(i => i.active)[0]
            }
            const newResults = structuredClone(prev)

            labTestRequirementDetailsTest?.LabTestRequirementDetails?.filter(item => item.labTestAnalyteDetailsParameters?.sampleTypeTwoId == sampleTwoId).forEach(parameter => {
                let prevIndex = prev.findIndex(i => parseInt(i.labTestRequirementDetailsId) === parseInt(parameter.id))


                let isResult = results.find(i => parseInt(i.labTestRequirementDetailsId) === parseInt(parameter.id));
                let resultValue = isResult ? isResult?.resultValue :
                    [{ subHeading: lastSubHeading, value: "", claimedValue: singleFiber?.data?.BlendDetails.map(item => { return { blendId: item.blendId, percentage: "", claimedPercentage: item.percentage } }) }]
                if (prevIndex === -1) {
                    newResults.push({
                        labTestRequirementDetailsId: parameter.id,
                        isDeleted: false,
                        parameterName: parameter?.labTestAnalyteDetailsParameters.name,
                        testId: item.testId,
                        resultValue
                    })
                } else {


                    let lastSubHeadingIndex = newResults[prevIndex]["resultValue"].findIndex(i => i.subHeading === lastSubHeading);
                    if (lastSubHeadingIndex === -1) {
                        newResults[prevIndex]["resultValue"] = resultValue
                    } else {

                        newResults[prevIndex]["resultValue"][lastSubHeadingIndex]["claimedValue"] = singleFiber?.data?.BlendDetails.map(item => { return { blendId: item.blendId, percentage: "", claimedPercentage: item.percentage } })
                    }
                }
            })
            return newResults
        })
    }, [labTestRequirementList, isLabRequirementLoading, isLabRequirementFetching, results, item, setResultDetails,
        fiberContentId, singleFiber, isSingleFiberFetching, isSingleFiberLoading, lastSubHeading, isFiberContent, sampleTwoId, requirementId])




    useEffect(() => {
        if (isFiberContent) return
        if (isLabRequirementLoading || isLabRequirementFetching || !labTestRequirementList?.data) return

        setResultDetails(prev => {
            let labTestRequirementDetailsTest;
            if (requirementId) {

                labTestRequirementDetailsTest = (labTestRequirementList?.data || []).find(i => parseInt(i.id) === parseInt(requirementId));

            } else {

                labTestRequirementDetailsTest = labTestRequirementList.data.filter(i => i.active)[0]
            }



            const newResults = structuredClone(prev)
            labTestRequirementDetailsTest?.LabTestRequirementDetails?.filter(item => parseInt(item.labTestAnalyteDetailsParameters?.sampleTypeTwoId) === parseInt(sampleTwoId))
                .forEach((parameter, index) => {
                    let prevIndex = prev.findIndex(i => parseInt(i.labTestRequirementDetailsId) === parseInt(parameter.id))
                    let isResult = results.find(i => parseInt(i.labTestRequirementDetailsId) === parseInt(parameter.id));
                    let resultValue;
                    resultValue = isResult ? isResult?.resultValue
                        : [{ subHeading: "Base", value: parameter?.labTestAnalyteDetailsParameters?.value ? parameter?.labTestAnalyteDetailsParameters.value : "", claimedValue: [] }]
                    let typeOfComponent = isResult && isResult?.typeOfComponent ? isResult.typeOfComponent : "";
                    let modeOfFailure = isResult && isResult?.modeOfFailure ? isResult?.modeOfFailure : "";
                    let areaOfComponent = isResult && isResult?.areaOfComponent ? isResult?.areaOfComponent : "";
                    if (prevIndex !== -1) {
                        newResults[prevIndex]["resultValue"] = resultValue;
                        newResults[prevIndex]["typeOfComponent"] = typeOfComponent;
                        newResults[prevIndex]["modeOfFailure"] = modeOfFailure;
                        newResults[prevIndex]["areaOfComponent"] = areaOfComponent;
                        newResults[prevIndex]["isDeleted"] = isResult?.isDeleted;
                    } else {
                        newResults.push({
                            labTestRequirementDetailsId: parameter.id,
                            isDeleted: isResult?.isDeleted,
                            labTestRequirementId: parameter.labTestRequirementId,
                            parameterName: parameter?.labTestAnalyteDetailsParameters.name,
                            testId: item.testId,
                            headingId: parameter.headingId,
                            typeOfComponent,
                            modeOfFailure,
                            areaOfComponent,
                            resultValue
                        })
                    }
                })
            return newResults
        })
    }, [labTestRequirementList, isLabRequirementLoading, isLabRequirementFetching, setResultDetails, results, item, isFiberContent, isLastResultExist, sampleTwoId, requirementId])


    // useEffect(() => {
    //     if (isFiberContent) return
    //     if (isLabRequirementLoading || isLabRequirementFetching || !labTestRequirementList?.data) return

    //     setResultDetails(prev => {
    //         let labTestRequirementDetailsTest;
    //         if (requirementId) {

    //             labTestRequirementDetailsTest = (labTestRequirementList?.data || []).find(i => parseInt(i.id) === parseInt(requirementId));

    //         } else {

    //             labTestRequirementDetailsTest = labTestRequirementList.data.filter(i => i.active)[0]
    //         }

    //         const newResults = structuredClone(prev)
    //         labTestRequirementDetailsTest?.LabTestRequirementDetails?.filter(item => parseInt(item.labTestAnalyteDetailsParameters?.sampleTypeTwoId) === parseInt(sampleTwoId))
    //             .forEach((parameter, index) => {
    //                 let prevIndex = prev.findIndex(i => parseInt(i.labTestRequirementDetailsId) === parseInt(parameter.id))
    //                 let isResult = results.find(i => parseInt(i.labTestRequirementDetailsId) === parseInt(parameter.id));
    //                 let resultValue;
    //                 resultValue = isResult ? isResult?.resultValue
    //                     : [{ subHeading: "Base", value: parameter?.labTestAnalyteDetailsParameters?.value ? parameter?.labTestAnalyteDetailsParameters.value : "", claimedValue: [] }]
    //                 let typeOfComponent = isResult && isResult?.typeOfComponent ? isResult.typeOfComponent : "";
    //                 let modeOfFailure = isResult && isResult?.modeOfFailure ? isResult?.modeOfFailure : "";
    //                 let areaOfComponent = isResult && isResult?.areaOfComponent ? isResult?.areaOfComponent : "";
    //                 if (prevIndex !== -1) {
    //                     newResults[prevIndex]["resultValue"] = resultValue;
    //                     newResults[prevIndex]["typeOfComponent"] = typeOfComponent;
    //                     newResults[prevIndex]["modeOfFailure"] = modeOfFailure;
    //                     newResults[prevIndex]["areaOfComponent"] = areaOfComponent;
    //                     newResults[prevIndex]["isDeleted"] = isResult?.isDeleted;
    //                 } else {
    //                     newResults.push({
    //                         labTestRequirementDetailsId: parameter.id,
    //                         isDeleted: isResult?.isDeleted,
    //                         labTestRequirementId: parameter.labTestRequirementId,
    //                         parameterName: parameter?.labTestAnalyteDetailsParameters.name,
    //                         testId: item.testId,
    //                         headingId: parameter.headingId,
    //                         typeOfComponent,
    //                         modeOfFailure,
    //                         areaOfComponent,
    //                         resultValue
    //                     })
    //                 }
    //             })
    //         return newResults
    //     })
    // }, [labTestRequirementList, isLabRequirementLoading, isLabRequirementFetching, setResultDetails, results, item, isFiberContent, isLastResultExist, sampleTwoId, requirementId])

    function findClaimedFiber(subHeading) {
        setLastSubHeading(subHeading);
        setFiberContentId(matchingSampleDetails["RegistrationSampleDetailsClaimedFiberContent"]?.find(item => item?.subHeading?.toLowerCase() == subHeading?.toLowerCase()).fiberContentId)
    }

    useEffect(() => {
        const isRemovalForce = findFromList(item.testId, labTestNameList?.data, "isRemovalForce")
        const isDimensionalStability = findFromList(item.testId, labTestNameList?.data, "isDimensionalStablity")
        setIsRemovalForce(isRemovalForce)
        setIsDimensionalStability(isDimensionalStability)
    }, [setIsRemovalForce, item.testId, labTestNameList, isLabTestLoading, isLabTestFetching, setIsDimensionalStability])

    function handleChangeBlend(newValue, labTestRequirementDetailsId, resultIndex, itemIndex, field) {
        const index = resultDetails.findIndex(obj => parseInt(obj.labTestRequirementDetailsId) === parseInt(labTestRequirementDetailsId));

        setResultDetails(prev => {
            let newReq = structuredClone(prev);
            newReq[index]["resultValue"][resultIndex]["claimedValue"][itemIndex][field] = newValue;
            return newReq
        })
    }

    let matchingResultDetails = resultDetails?.find(value => value?.testId === item?.testId);

    let testParametersHeadings = labTestRequirementList?.data?.flatMap(item => item.LabTestRequirementDetails)

    function findHeading(headingId) {
        return testParametersHeadings?.find(item => item.headingId == headingId).labTestAnalyteDetailsParameters?.LabTestAnalyteDetails?.heading
    }

    if (!singleLabTestParameterList || isLabParameterLoading || isLabParameterFetching || !labTestNameList || !labTestMethodList || !labTestConditionList || !labTestRequirementList || !labTestParameterList
        || isLabRequirementFetching || isLabRequirementLoading || !formulaList
    ) return null

    return (
        <>
            <div className="p-4 w-full overflow-x-auto">
                <div key={itemIndex} className='text-xs table-input'>
                    <div className=' p-3 flex w-full justify-between font-bold'>
                        <div className='grid grid-rows-2'>
                            <div className='text-start font-bold'>
                                {itemIndex + 1} . {findFromList(item.testId, (labTestNameList?.data || []), "name")}
                            </div>
                            <div className='flex gap-x-3'>{findFromList(item.methodId, labTestMethodList?.data || [], "name")} <span>
                                [ {findFromList(item.conditionId, labTestConditionList?.data || [], "name")} ]
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
                <table className='w-full table-auto'>
                    <thead>
                        <tr className="border border-gray-500 table-input text-xs">
                            <th className='w-9 border border-gray-500 p-2 capitalize '>
                                s.no
                            </th>
                            <th className="w-64  border border-gray-500">Parameter</th>


                            {
                                isRemovalForce ?
                                    <>
                                        <th className="w-40  border border-gray-500">Type Of Component</th>
                                        <th className="w-40  border border-gray-500">Area Of Component</th>
                                    </>
                                    : ""

                            }
                            <th className="border border-gray-500">
                                <div className='flex justify-between items-center '>
                                    <div className='text-center w-full'>
                                        Test Entry
                                    </div>
                                    <button
                                        type='button' className={`text-green-700 p-2 `}
                                        onClick={() => {
                                            let onlyTestRequirementsNotMatching;
                                            let onlyTestRequirementsMatching;
                                            singleLabTestParameterList?.data?.selectedOption === "CLAIMEDFIBERCONTENT" ?
                                                setResultDetails(prev => {
                                                    let newPrev = structuredClone(prev);
                                                    onlyTestRequirementsNotMatching = newPrev.filter(prevItem => prevItem.testId !== item.testId)
                                                    onlyTestRequirementsMatching = newPrev.filter(prevItem => prevItem.testId === item.testId)
                                                    onlyTestRequirementsMatching = onlyTestRequirementsMatching.map((item) => {
                                                        let parameter = labTestRequirementList?.data[0]?.LabTestRequirementDetails.find(i => parseInt(i.id) === parseInt(item.labTestRequirementDetailsId))
                                                        return {
                                                            ...item,
                                                            resultValue: [...item.resultValue, {
                                                                subHeading: "",
                                                                claimedValue: [],
                                                                value: ""
                                                            }
                                                            ]
                                                        }
                                                    })
                                                    return [...onlyTestRequirementsMatching, ...onlyTestRequirementsNotMatching]
                                                })
                                                :
                                                setResultDetails(prev => {
                                                    let newPrev = structuredClone(prev);
                                                    onlyTestRequirementsNotMatching = newPrev.filter(prevItem => prevItem.testId != item.testId)
                                                    onlyTestRequirementsMatching = newPrev.filter(prevItem => prevItem.testId == item.testId)
                                                    onlyTestRequirementsMatching = onlyTestRequirementsMatching.map((item) => {
                                                        let labTestRequirementDetailsTest;
                                                        if (requirementId) {
                                                            labTestRequirementDetailsTest = (labTestRequirementList?.data || []).find(i => parseInt(i.id) === parseInt(requirementId));
                                                        } else {
                                                            labTestRequirementDetailsTest = labTestRequirementList.data.filter(i => i.active)[0]
                                                        }
                                                        let parameter = labTestRequirementDetailsTest?.LabTestRequirementDetails.find(i => parseInt(i.id) === parseInt(item.labTestRequirementDetailsId))
                                                        return {
                                                            ...item,
                                                            resultValue: [...(item?.resultValue || []), {
                                                                subHeading: "",
                                                                value: parameter?.labTestAnalyteDetailsParameters?.value ? parameter.labTestAnalyteDetailsParameters.value : "",
                                                                claimedValue: []
                                                            }
                                                            ]
                                                        }
                                                    })
                                                    return [...onlyTestRequirementsMatching, ...onlyTestRequirementsNotMatching]
                                                })
                                        }}>
                                        {<FontAwesomeIcon icon={faUserPlus} />}
                                    </button>
                                </div>
                            </th>


                            {
                                isRemovalForce ?

                                    <th className="w-40  border border-gray-500">Mode Of Failure</th>

                                    : ""
                            }

                            <th className='w-9 border border-gray-500 p-2 capitalize '>
                                <GenerateButton name='' onClick={() => {
                                    setResultDetails(prev => {
                                        let newPrev = structuredClone(prev);
                                        const notMatchingResults = newPrev.filter(i => parseInt(i.testId) !== parseInt(item.testId))
                                        const matchingResults = newPrev.filter(i => parseInt(i.testId) === parseInt(item.testId)).map(i => ({ ...i, isDeleted: false }))
                                        return [...notMatchingResults, ...matchingResults]
                                    })
                                }} />
                            </th>
                        </tr>
                    </thead>
                    {
                        (resultDetails.length === 0) || (((labTestRequirementList?.data || []).filter(i => i.active)?.length) === 0 && (!requirementId)) ?

                            <tbody>
                                <tr className='border border-gray-500 '>
                                    <td>
                                    </td>
                                    <td>
                                    </td>
                                    <td className="text-center font-bold text-blue-600 text-capitalize">
                                        <span className='text-red-500 mr-5'>
                                            No Record Found....
                                        </span>  Please Check The Registration Or Parameter Or Requirement
                                    </td>

                                </tr>
                            </tbody>
                            :
                            <tbody>
                                <tr className='border border-gray-500'>
                                    <td className='border border-gray-500'></td>
                                    <td className='border border-gray-500'></td>
                                    {
                                        isRemovalForce ?
                                            <td className='border border-gray-500' colSpan={2}></td>
                                            : ""
                                    }
                                    <td className='border border-gray-500 '>
                                        {singleLabTestParameterList?.data?.selectedOption === "CLAIMEDFIBERCONTENT" ?
                                            matchingResultDetails?.resultValue?.map((sub, i) =>
                                                <>
                                                    <input colSpan={sub} value={sub?.subHeading}
                                                        className='border border-gray-500 p-0.5 w-24  text-xs capitalize text-center'
                                                        onChange={(e) => {
                                                            setResultDetails(prev => {
                                                                let newPrev = structuredClone(prev);
                                                                const heading = e.target.value;
                                                                return newPrev.map((item) => {
                                                                    if (!item["resultValue"]) return item
                                                                    if (!item["resultValue"][i]) return item
                                                                    if (matchingResultDetails?.testId === item?.testId) {
                                                                        item["resultValue"][i]["subHeading"] = heading;
                                                                    }
                                                                    return item
                                                                })
                                                            })
                                                        }}
                                                    >
                                                    </input>
                                                    <button className='bg-green-200'
                                                        onClick={() => findClaimedFiber(sub?.subHeading)}
                                                    >
                                                        {TICK_ICON}
                                                    </button>
                                                </>
                                            )
                                            :
                                            matchingResultDetails?.resultValue?.map((sub, i) =>
                                                <>
                                                    <Fragment className='w-52'>
                                                        <input colSpan={sub} value={sub?.subHeading}
                                                            className='border border-gray-500 p-0.5 w-52 ml-2 text-xs capitalize text-center'
                                                            onChange={(e) => {
                                                                setResultDetails(prev => {
                                                                    let newPrev = structuredClone(prev);
                                                                    const heading = e.target.value;
                                                                    return newPrev.map((item) => {
                                                                        if (!item["resultValue"]) return item
                                                                        if (!item["resultValue"][i]) return item
                                                                        if (matchingResultDetails?.testId === item?.testId) {
                                                                            item["resultValue"][i]["subHeading"] = heading;
                                                                        }
                                                                        return item
                                                                    })
                                                                })
                                                            }
                                                            }
                                                        >
                                                        </input>
                                                        <button
                                                            type='button'
                                                            onClick={() => {
                                                                setResultDetails(prev => {
                                                                    let newPrev = structuredClone(prev);
                                                                    let onlyTestRequirementsNotMatching = newPrev.filter(prevItem => prevItem.testId !== item.testId)
                                                                    let onlyTestRequirementsMatching = newPrev.filter(prevItem => prevItem.testId === item.testId)
                                                                    onlyTestRequirementsMatching = onlyTestRequirementsMatching.map((item) => {
                                                                        return {
                                                                            ...item,
                                                                            resultValue: [...item.resultValue.filter((item, itemIndex) => i !== itemIndex)

                                                                            ]
                                                                        }
                                                                    })
                                                                    return [...onlyTestRequirementsMatching, ...onlyTestRequirementsNotMatching]
                                                                })

                                                            }}
                                                            className=' w-4 text-xs text-red-600'>{<FontAwesomeIcon icon={faTrashCan} />}
                                                        </button>
                                                    </Fragment>

                                                </>
                                            )
                                        }

                                    </td>


                                </tr>
                                {isDimensionalStability &&
                                    <>
                                        {(labTestRequirementList?.data || []).filter((i, paramIndex) => (requirementId ? (parseInt(i.id) === parseInt(requirementId)) : i?.active)).map((reqItem, itemIndex) =>
                                            <Fragment key={itemIndex}>
                                                {(reqItem.LabTestRequirementDetails ? Object.entries(groupBy(reqItem.LabTestRequirementDetails.filter((val, paramIndex) => (paramIndex == 0) && resultDetails.find(i => i.headingId == val.headingId)).filter(item => item.labTestAnalyteDetailsParameters?.sampleTypeTwoId == sampleTwoId), "headingId")) : [])
                                                    .filter(([headingId, parameters]) =>
                                                        parameters?.filter(item => resultDetails.find(val => (val.labTestRequirementDetailsId == item.id) && !val.isDeleted)).length > 0
                                                    )
                                                    .map(([headingId, parameters], valueIndex) =>
                                                        <>
                                                            {parameters?.filter((item, paramindex) => (paramindex === 0) && resultDetails.find(val => (val.labTestRequirementDetailsId == item.id) && !val.isDeleted)).map((parameter, index) =>
                                                                <tr key={index} className='text-xs'  >
                                                                    <td className='border border-gray-500 p-1'></td>
                                                                    <td className='border border-gray-500 p-1 capitalize text-xs'>  </td>
                                                                    <td className='border border-gray-500 capitalize text-xs'>
                                                                        <ResultHeadingForDimensional
                                                                            setResultDetails={setResultDetails}
                                                                            parameter={parameter}
                                                                            handleChangeBlend={handleChangeBlend}
                                                                            fiberContentId={fiberContentId}
                                                                            singleParameter={singleLabTestParameterList.data}
                                                                            parameters={singleLabTestParameterList?.data?.LabTestAnalyteDetails}
                                                                            requirementList={labTestRequirementList?.data[0]}
                                                                            labTestRequirementDetailsId={parameter.id}
                                                                            isEditable={parameter?.labTestAnalyteDetailsParameters?.isEditable}
                                                                            isResult={parameter?.labTestAnalyteDetailsParameters?.isResult}
                                                                            results={results} testItem={item} setSubHeading={setSubHeading}
                                                                            subHeading={subHeading} setId={setId} readOnly={readOnly}
                                                                            childRecord={childRecord} registrationSampleDetailsId={registrationSampleDetailsId}
                                                                            index={index}
                                                                            resultDetails={resultDetails} registerId={registerId}
                                                                            matchingSampleDetails={matchingSampleDetails} isDimensionalStability={isDimensionalStability} />
                                                                    </td>
                                                                    <td className='border border-gray-500 p-1 capitalize text-xs text-center mr-1'>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </>
                                                    )}
                                            </Fragment>
                                        )}
                                    </>}
                                {(labTestRequirementList?.data || []).filter(i => requirementId ? (parseInt(i.id) === parseInt(requirementId)) : i?.active).map((reqItem, itemIndex) =>

                                    <Fragment key={itemIndex}>
                                        {(reqItem.LabTestRequirementDetails ? Object.entries(groupBy(reqItem.LabTestRequirementDetails.filter(val => resultDetails.find(i => i.headingId == val.headingId)).filter(item => item.labTestAnalyteDetailsParameters?.sampleTypeTwoId == sampleTwoId), "headingId")) : [])
                                            .filter(([headingId, parameters]) =>
                                                parameters?.filter(item => resultDetails.find(val => (val.labTestRequirementDetailsId == item.id) && !val.isDeleted)).length > 0
                                            )
                                            .map(([headingId, parameters], valueIndex) =>
                                                <>
                                                    <tr className='border border-gray-500'>
                                                        <td className=' border border-gray-500'></td>
                                                        <td className=' border border-gray-500'></td>
                                                        <td className='h-7  text-center font-bold p-1 text-xs capitalize'> {findHeading(headingId)}</td>
                                                        <td
                                                            type='button' tabIndex={-1}
                                                            onClick={() => {
                                                                setResultDetails(prev => {
                                                                    let newPrev = structuredClone(prev);
                                                                    for (let index = 0; index < parameters.length; index++) {
                                                                        const parameter = parameters[index];
                                                                        let findIndex = newPrev.findIndex(val => val.labTestRequirementDetailsId == parameter.id)
                                                                        if (newPrev[findIndex]) {
                                                                            newPrev[findIndex]["isDeleted"] = true;
                                                                        }
                                                                    }
                                                                    return newPrev
                                                                })
                                                            }}
                                                            className='text-sm text-center text-red-600 mt-2 ml-2'>
                                                            {<FontAwesomeIcon icon={faTrashCan} />}
                                                        </td>
                                                    </tr>
                                                    {parameters?.filter(item => resultDetails.find(val => (val.labTestRequirementDetailsId == item.id) && !val.isDeleted)).map((parameter, index) =>
                                                        <tr key={index} className='text-xs'>
                                                            <td className='border border-gray-500 p-1'>{index + 1}</td>
                                                            <td className='border border-gray-500 p-1 capitalize text-xs'>  {parameter?.labTestAnalyteDetailsParameters?.name} </td>
                                                            {
                                                                isRemovalForce ?
                                                                    <RemovalForceComponent resultDetails={resultDetails} isResult={parameter?.labTestAnalyteDetailsParameters?.isResult} labTestRequirementDetailsId={parameter.id} setResultDetails={setResultDetails} readOnly={readOnly} childRecord={childRecord} parameter={parameter} />
                                                                    : ""
                                                            }


                                                            <td className='border border-gray-500 capitalize text-xs'>
                                                                <ResultInput
                                                                    setResultDetails={setResultDetails}
                                                                    parameter={parameter}
                                                                    handleChangeBlend={handleChangeBlend}
                                                                    fiberContentId={fiberContentId}
                                                                    singleParameter={singleLabTestParameterList.data}
                                                                    parameters={singleLabTestParameterList?.data?.LabTestAnalyteDetails}
                                                                    requirementList={labTestRequirementList?.data[0]}
                                                                    labTestRequirementDetailsId={parameter.id}
                                                                    isEditable={parameter?.labTestAnalyteDetailsParameters?.isEditable}
                                                                    isResult={parameter?.labTestAnalyteDetailsParameters?.isResult}
                                                                    results={results} testItem={item} setSubHeading={setSubHeading}
                                                                    subHeading={subHeading} setId={setId} readOnly={readOnly}
                                                                    childRecord={childRecord} registrationSampleDetailsId={registrationSampleDetailsId}
                                                                    index={index}
                                                                    resultDetails={resultDetails} registerId={registerId}
                                                                    matchingSampleDetails={matchingSampleDetails} isDimensionalStability={isDimensionalStability} />
                                                            </td>

                                                            {
                                                                isRemovalForce ?

                                                                    <ModeOfFailure resultDetails={resultDetails} isResult={parameter?.labTestAnalyteDetailsParameters?.isResult} labTestRequirementDetailsId={parameter.id} setResultDetails={setResultDetails} readOnly={readOnly} childRecord={childRecord} parameter={parameter} />
                                                                    : ""
                                                            }


                                                            <td className='border border-gray-500 p-1 capitalize text-xs text-center mr-1'>
                                                                <button
                                                                    type='button'
                                                                    onClick={() => {
                                                                        setResultDetails(prev => {
                                                                            let newPrev = structuredClone(prev);
                                                                            let findIndex = newPrev.findIndex(val => val.labTestRequirementDetailsId == parameter.id)
                                                                            newPrev[findIndex]["isDeleted"] = true;
                                                                            return newPrev
                                                                        })

                                                                    }}
                                                                    className=' w-3 text-xs text-red-600'>
                                                                    {<FontAwesomeIcon icon={faTrashCan} />}
                                                                </button>



                                                            </td>

                                                        </tr>
                                                    )}
                                                </>
                                            )}
                                    </Fragment>

                                )}


                            </tbody>
                    }

                </table>

            </div>

        </>
    )
}

export default SampleTestDetails