import React, { useEffect } from 'react'
import { CLOSE_ICON, TICK_ICON } from '../../../icons'
import { getClaimedValue } from '../../../Utils/helper'

const ResultInputEntryLevel = ({ paraItem, resultDetailsWithResult, isSelectedOption, isTextBased, requirementList, resultIndex, setResultDetailsWithResult, isResult, isEditable, result, getResult, getRegex, handleOnChange, childRecord, parameter, loopsubHeading, matchingSampleDetails, decimalValue, getEval, labTestRequirementDetailsId, resultDetails, isDimensionalStability }) => {



    useEffect(() => {


        const resIndex = resultDetails.findIndex(obj => parseInt(obj.labTestRequirementDetailsId) === parseInt(labTestRequirementDetailsId));
        const results = resultDetails[resIndex]['resultValue'].find((_, i) => i === resultIndex)
        setResultDetailsWithResult(prev => {
            const reqIndex = prev.findIndex(obj => parseInt(obj.labTestRequirementDetailsId) === parseInt(labTestRequirementDetailsId));

            if (reqIndex === -1) return prev;
            let newReq = structuredClone(prev);


            const subHeadingIndex = newReq[reqIndex]["resultValue"].findIndex(i => i.subHeading.toLowerCase() == result.subHeading.toLowerCase())



            if (subHeadingIndex === -1) return prev;

            newReq[reqIndex]["resultValue"][subHeadingIndex]["value"] = results?.value ? results?.value : '0';
            newReq[reqIndex]["resultValue"][subHeadingIndex]["original"] = results?.original || '0';
            newReq[reqIndex]["resultValue"][subHeadingIndex]["afterWash"] = results?.afterWash || '0';


            if (isResult) {
                newReq[reqIndex]["resultValue"][subHeadingIndex]["result"] = getResult(parameter?.labTestAnalyteDetailsParameters?.LabTestAnalyteDetails?.LabTestAnalyte?.selectedOption, matchingSampleDetails, parameter, results, loopsubHeading, parameter?.labTestAnalyteDetailsParameters?.walesCourse);
                newReq[reqIndex]["resultValue"][subHeadingIndex]["finalClaimedValue"] = getClaimedValue(parameter?.labTestAnalyteDetailsParameters?.LabTestAnalyteDetails?.LabTestAnalyte?.selectedOption, matchingSampleDetails, loopsubHeading, parameter?.labTestAnalyteDetailsParameters?.walesCourse)
                // newReq[reqIndex]["resultValue"][subHeadingIndex]["reportValue"] = newReq[reqIndex]["resultValue"][subHeadingIndex]["value"] ? getEval(getRegex(newReq[reqIndex]["resultValue"][subHeadingIndex]["value"]), result.subHeading).toString() : ""
                newReq[reqIndex]["resultValue"][subHeadingIndex]["requirementValue"] = (parameter?.requirement?.find(item => item.subHeading.toLowerCase() == loopsubHeading.toLowerCase())?.formula.map(i => `${i.Formula?.name}${i.value}`).join(" "))
            }
            if (isEditable) {
                newReq[reqIndex]["resultValue"][subHeadingIndex]["reportValue"] = result?.value ? result?.value.toString() : ""
            } else {
                newReq[reqIndex]["resultValue"][subHeadingIndex]["reportValue"] = result?.value ? getEval(getRegex(result["value"], result.subHeading)).toString() : ""
            }
            // else {
            //     newReq[reqIndex]["resultValue"][subHeadingIndex]["result"] = true
            // }

            return newReq
        })
    }, [resultDetails, resultIndex])



    function getResultFromWithResultDetail() {
        const reqIndex = resultDetailsWithResult.findIndex(obj => obj.labTestRequirementDetailsId === labTestRequirementDetailsId);
        if (reqIndex === -1) return false;

        const subHeadingIndex = resultDetailsWithResult[reqIndex]["resultValue"].findIndex(i => i.subHeading.toLowerCase() == result.subHeading.toLowerCase())
        if (subHeadingIndex === -1) return false;
        return resultDetailsWithResult[reqIndex]["resultValue"][subHeadingIndex]["result"]
    }
    function setResultFromWithResultDetail() {
        setResultDetailsWithResult(prev => {
            let newPrev = structuredClone(prev);
            const reqIndex = newPrev.findIndex(obj => obj.labTestRequirementDetailsId === labTestRequirementDetailsId);
            if (reqIndex === -1) return newPrev;
            const subHeadingIndex = newPrev[reqIndex]["resultValue"].findIndex(i => i.subHeading.toLowerCase() == result.subHeading.toLowerCase())
            if (subHeadingIndex === -1) return newPrev;

            newPrev[reqIndex]["resultValue"][subHeadingIndex]["result"] = !newPrev[reqIndex]["resultValue"][subHeadingIndex]["result"]
            return newPrev
        })
    }

    return (
        <td className='border border-gray-500 ml-0.5 p-1' key={resultIndex}>
            <div className='flex'>
                <input type="text" className=" text-center w-36 -ml-1 focus:outline-none "

                    value={((isDimensionalStability || isEditable)) ? result?.value ? result?.value : "" : parseFloat(getEval(getRegex(result["value"], result.subHeading))).toFixed(decimalValue || 2)}

                    onChange={(e) => handleOnChange(e.target.value, result.subHeading, "value", resultIndex)}
                    readOnly={!(isDimensionalStability || isEditable)} disabled={(childRecord.current > 0)} />
                {isResult ?
                    <div onClick={() => {
                        if (isTextBased) {
                            setResultFromWithResultDetail()
                        }
                    }} >
                        {

                            paraItem?.isData ?
                                <span className='bg-green-500 text-white p-0.5'>{TICK_ICON}</span>
                                :
                                getResultFromWithResultDetail()
                                    ?
                                    <span className='bg-green-500 text-white p-0.5'>{TICK_ICON}</span>
                                    :
                                    <span className='bg-red-500 text-white px-1 py-0.5'>{CLOSE_ICON}</span>
                        }
                    </div>
                    :
                    <>
                    </>
                }
            </div>
        </td>
    )
}

export default ResultInputEntryLevel