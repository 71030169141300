import React, { useEffect, useState } from 'react'
import { useAmendRegistrationMutation } from '../../../redux/LabServices/RegistrationServices'
import { DropdownInput, TextArea, TextInput } from '../../../Inputs'
import { toast } from 'react-toastify'

const AmendmentForm = ({ isValidReport, registrationId, onClose, docId }) => {
    const options = [
        { show: "INTERNAL", value: "INTERNAL" },
        { show: "EXTERNAL", value: "EXTERNAL" },
    ]
    const optionsPurpose = [
        { show: "TECHNICAL", value: "TECHNICAL" },
        { show: "NON-TECHNICAL", value: "NON-TECHNICAL" },
    ]
    const subTypeOptions = [
        { show: "COMPLAINT", value: "COMPLAINT" },
        { show: "NON-CONFIRM", value: "NON-CONFIRM" },
    ]
    const [amendmentReason, setAmendmentReason] = useState("");
    const [externalRequest, setExternalRequest] = useState()
    const [amendmentType, setAmendmentType] = useState("");
    const [amendmentSubType, setAmendmentSubType] = useState("");
    const [amendmentPurpose, setAmendmentPurpose] = useState("");
    const [amendRegistration] = useAmendRegistrationMutation();
    const [processStage, setProcessStage] = useState("");
    const [toWhomDone, setToWhomDone] = useState("");
    const [personnalName, setPersonnalName] = useState("")

    async function handleAmendRegistration() {
        if (!amendmentReason) return toast.info("Type Reason")
        if (!amendmentType) return toast.info("Select Type")
        if (!amendmentPurpose) return toast.info("Select Purpose")
        if (!amendmentType) return toast.info("Select Type")
        if (amendmentType == "INTERNAL") {
            if (!amendmentSubType) return toast.info("Select SubType")
        }
        else if (amendmentType == "EXTERNAL") {
            if (!externalRequest) return toast.info("Type Request")
        }
        if (!processStage) return toast.info("Select Process Stage")
        if (!toWhomDone) return toast.info("Select To Whom Done")
        if (!personnalName) return toast.info("Select Personnal Name")
        const returnData = await amendRegistration({ id: registrationId, amendmentReason, amendmentType, amendmentPurpose, amendmentSubType, externalRequest, processStage, toWhomDone, personnalName }).unwrap()
        if (returnData.statusCode === 0) {
            onClose()
            return toast.success("Amendment Successfull")
        } else {
            return toast.error("Amendment Failed")
        }
    }

    useEffect(() => {

        let samTypeSub = "Request"
        if (amendmentType === "EXTERNAL") {
            setExternalRequest(samTypeSub)
        }
        else {
            setExternalRequest("")
        }
    }, [amendmentType])
    return (
        <>
            {
                isValidReport ?


                    <div className='flex flex-col gap-5'>
                        <span className='text-xl text-center'>Amendment {docId}</span>
                        <TextArea name='Reason' value={amendmentReason} setValue={setAmendmentReason} required={true} />

                        <div className='grid grid-cols-2'>
                            <DropdownInput name='Type' value={amendmentType} setValue={setAmendmentType} options={options} required={true} />
                            {
                                amendmentType === "INTERNAL" &&
                                <DropdownInput name='Sub.Type' value={amendmentSubType} setValue={setAmendmentSubType} options={subTypeOptions} required={true} />

                            }
                            {
                                amendmentType === "EXTERNAL" &&
                                <TextArea name='Sub.Type' value={externalRequest} setValue={setExternalRequest} required={true} />

                            }
                            <DropdownInput name='Purpose' value={amendmentPurpose} setValue={setAmendmentPurpose} options={optionsPurpose} required={true} />

                            <TextInput name="Process.Stage" type="text" value={processStage} setValue={setProcessStage} required={true} />
                            <TextInput name="To.Whom.Done" type="text" value={toWhomDone} setValue={setToWhomDone} required={true} />
                            <TextInput name="Personnal.Name" type="text" value={personnalName} setValue={setPersonnalName} required={true} />
                        </div>
                        <span className='text-red-700'>
                            This amendment cannot be undone...!
                        </span>
                        <div className='w-full flex justify-end'>
                            <button
                                onClick={handleAmendRegistration}
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                type="button"
                            >
                                Amend
                            </button>
                        </div>
                    </div>
                    :
                    <>
                        <div className='flex justify-center items-center text-red-500 mt-[25%] text-xl'>Amendment  Time Is Over!......</div>
                    </>

            }

        </>
    )
}

export default AmendmentForm
