import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push, remove } from "../../../redux/features/opentabs";
// import { push, remove } from "../../../redux/features/additionalTabs";
import {
  CountryMaster, PageMaster, StateMaster, CityMaster,
  DepartmentMaster, EmployeeCategoryMaster, FinYearMaster, UserAndRolesMaster, PageGroupMaster,
  AccountSettings, ControlPanel, EmployeeMaster
} from "../../components";

import {
  PartyCategoryMaster, CurrencyMaster, PartyMaster, ContentMaster,
  YarnTypeMaster, CountsMaster, HsnMaster, YarnBlendMaster, YarnMaster,
  FabricTypeMaster, FabricMaster, AccessoryGroupMaster, AccessoryItemMaster,
  AccessoryMaster, ColorMaster, PurchaseOrder, TaxTermMaster, TaxTemplate,
  UnitOfMeasurementMaster, PayTermMaster, LocationMaster,
  GaugeMaster, LoopLengthMaster, GsmMaster, DiaMaster, DesignMaster,
  SizeMaster,
  PurchaseInwardReturn,
  PurchaseOrderCancel,
  YarnPurchaseRegistry,
  StockRegistry,
  StyleMaster,
  YarnToKnitting,
  ProcessMaster,
  BlendMaster,
  FiberContentMaster,
} from "../../../ERP/Components";

import {
  BrandMaster, FinishMaster, LabTestConditionMaster, LabTestGroupMaster, LabTestNameMaster,
  LabTestSubGroupMaster, Registration, SampleTypeOneMaster, SampleTypeThreeMaster,
  SampleTypeTwoMaster, ServiceLevelMaster, TestStageMaster, WashCareMaster, WashMaster,
  SubmissionTypeMaster, OnHoldReasonMaster, EndUseMaster, MillOrFactoryMaster,
  LabTestStandardMaster, LabTestMethodMaster, WashCareGroupMaster, SampleReceiptConfirmation,
  Dashboard,
  PackageNameMaster,
  ProductCategoryMaster,
  LabCodeMaster,
  TestParameterMaster,
  RequirementMaster,
  FormulaMaster,
  SampleTypeFourMaster,
  ResultEntry,
  AdminResultEntry,
  SampleEmail,
  FinalReportVerification,
  InvoiceReport,
  PartySummary,
  RegistrationLoginSummary,
  LogOutSummary,
  InvoiceSummary,
  ParameterRequirementSummary,
  AmendmentHistorySummary,
  CommonDashboard,
  PassFailSummary,


} from "../../../Laboratory/Components";


import { CLOSE_ICON, DOUBLE_NEXT_ICON } from "../../../icons";
import CompanyMaster from "../CompanyMaster";
import HolidayCalendar from "../../../Laboratory/Components/HolidayForm";
import useOutsideClick from "../../../CustomHooks/handleOutsideClick";
import SampleDocument from "../SampleDocument";
import MyPDFViewerComponent from "../SampleDocument2";
import TestReportDocument from "../../../Laboratory/Components/DashBoard/TestReportContainerPreviewReactPdf";
import SampleReceiptDocument from "../../../Laboratory/Components/DashBoard/SampleReceiptDocument";
import ResultEntrySummary from "../../../Laboratory/Components/ResultEntrySummary";
import AdminResultEntrySummary from "../../../Laboratory/Components/AdminResultEntrySummary";
import PartyExcel from "../../../Laboratory/Components/PartySummary/PartyExcel";
import { useGetUserByIdQuery } from "../../../redux/services/UsersMasterService";
import secureLocalStorage from "react-secure-storage";

const ActiveTabList = () => {
  const openTabs = useSelector((state) => state.openTabs);
  // const additionalTabs=useSelector((state)=>state.additionalTabs);
  // console.log(additionalTabs,"additionalTabs")
  const dispatch = useDispatch();
  const [showHidden, setShowHidden] = useState(false);

  const ref = useOutsideClick(() => { setShowHidden(false) })

  const tabs = {
    "PAGE MASTER": <PageMaster />,
    "COUNTRY MASTER": <CountryMaster />,
    "STATE MASTER": <StateMaster />,
    "CITY MASTER": <CityMaster />,
    "EMPLOYEE DEPARTMENT MASTER": <DepartmentMaster />,
    "EMPLOYEE DESIGNATION MASTER": <EmployeeCategoryMaster />,
    "FIN YEAR MASTER": <FinYearMaster />,
    "USERS & ROLES": <UserAndRolesMaster />,
    "ACCOUNT SETTINGS": <AccountSettings />,
    "CONTROL PANEL": <ControlPanel />,
    "EMPLOYEE MASTER": <EmployeeMaster />,
    "COMPANY MASTER": <CompanyMaster />,
    "PARTY CATEGORY MASTER": <PartyCategoryMaster />,
    "CURRENCY MASTER": <CurrencyMaster />,
    "PARTY MASTER": <PartyMaster />,
    "CONTENT MASTER": <ContentMaster />,
    "YARN TYPE MASTER": <YarnTypeMaster />,
    "COUNTS MASTER": <CountsMaster />,
    "HSN MASTER": <HsnMaster />,
    "BLEND MASTER": <BlendMaster />,
    "YARN MASTER": <YarnMaster />,
    "FABRIC TYPE MASTER": <FabricTypeMaster />,
    "FABRIC MASTER": <FabricMaster />,
    "PAGE GROUP MASTER": <PageGroupMaster />,
    "ACCESSORY GROUP MASTER": <AccessoryGroupMaster />,
    "ACCESSORY ITEM MASTER": <AccessoryItemMaster />,
    "ACCESSORY MASTER": <AccessoryMaster />,
    "COLOR MASTER": <ColorMaster />,
    "PURCHASE ORDER": <PurchaseOrder />,
    "UNIT OF MEASUREMENT MASTER": <UnitOfMeasurementMaster />,
    "PAY TERM MASTER": <PayTermMaster />,
    "TAX TERM MASTER": <TaxTermMaster />,
    "TAX TEMPLATE": <TaxTemplate />,
    "GSM MASTER": <GsmMaster />,
    "GAUGE MASTER": <GaugeMaster />,
    "LOOP LENGTH MASTER": <LoopLengthMaster />,
    "DIA MASTER": <DiaMaster />,
    "DESIGN MASTER": <DesignMaster />,
    "SIZE MASTER": <SizeMaster />,
    "PURCHASE INWARD/RETURN": <PurchaseInwardReturn />,
    "PURCHASE ORDER CANCEL": <PurchaseOrderCancel />,
    "YARN PURCHASE REGISTRY": <YarnPurchaseRegistry />,
    "STOCK REGISTRY": <StockRegistry />,
    "LOCATION MASTER": <LocationMaster />,
    "STYLE MASTER": <StyleMaster />,
    "YARN DELIVERY TO KNITTING / YARN RETURN FROM KNITTING": <YarnToKnitting />,
    "PROCESS MASTER": <ProcessMaster />,

    "LAB TEST GROUP MASTER": <LabTestGroupMaster />,
    "LAB TEST SUB GROUP MASTER": <LabTestSubGroupMaster />,
    "LAB TEST MASTER": <LabTestNameMaster />,
    "LAB TEST STANDARD MASTER": <LabTestStandardMaster />,
    "LAB TEST CONDITION MASTER": <LabTestConditionMaster />,
    "WASH CARE MASTER": <WashCareMaster />,
    "SAMPLE TYPE 1 MASTER": <SampleTypeOneMaster />,
    "SAMPLE TYPE 2 MASTER": <SampleTypeTwoMaster />,
    "SAMPLE TYPE 3 MASTER": <SampleTypeThreeMaster />,
    "SAMPLE TYPE 4 MASTER": <SampleTypeFourMaster />,

    "BRAND MASTER": <BrandMaster />,
    "WASH MASTER": <WashMaster />,
    "FINISH MASTER": <FinishMaster />,
    "REGISTRATION": <Registration />,
    "SERVICE LEVEL MASTER": <ServiceLevelMaster />,
    "SUBMISSION TYPE MASTER": <SubmissionTypeMaster />,
    "ON HOLD REASON MASTER": <OnHoldReasonMaster />,
    "END USE MASTER": <EndUseMaster />,
    "MILL / FACTORY MASTER": <MillOrFactoryMaster />,
    "PACKAGE NAME MASTER": <PackageNameMaster />,
    "LAB TEST METHOD MASTER": <LabTestMethodMaster />,
    "WASH CARE GROUP MASTER": <WashCareGroupMaster />,
    "SAMPLE RECEIPT CONFIRMATION": <SampleReceiptConfirmation />,
    "DASHBOARD": <Dashboard />,
    "DASHBOARD ADMIN": <Dashboard isAdmin />,
    "PRODUCT CATEGORY MASTER": <ProductCategoryMaster />,
    "LAB CODE MASTER": <LabCodeMaster />,
    "HOLIDAY CALENDER": <HolidayCalendar />,
    "TEST PARAMETER MASTER": <TestParameterMaster />,
    "LAB REQUIREMENT MASTER": <RequirementMaster />,
    "FORMULA MASTER": <FormulaMaster />,
    "FIBER CONTENT MASTER": <FiberContentMaster />,
    "RESULT ENTRY": <ResultEntry />,
    "ADMIN RESULT ENTRY": <AdminResultEntry />,
    "SAMPLEEMAIL": <SampleEmail />,
    "SAMPLE DOCUMENT": <MyPDFViewerComponent />,
    "FINAL REPORT VERIFICATION": <FinalReportVerification />,
    "INVOICE REPORT": < InvoiceReport />,
    "RESULT ENTRY SUMMARY": <ResultEntrySummary />,
    "ADMIN RESULT ENTRY SUMMARY": <AdminResultEntrySummary />,
    "REGISTRATION LOGIN SUMMARY": <RegistrationLoginSummary />,
    "PARTY SUMMARY": <PartySummary />,
    "LOGOUT SUMMARY": <LogOutSummary />,
    "INVOICE SUMMARY": <InvoiceSummary />,
    "PARAMETER REQUIREMENT SUMMARY": <ParameterRequirementSummary />,
    "AMENDMENT HISTORY SUMMARY": <AmendmentHistorySummary />,
    "COMMON DASHBOARD": <CommonDashboard />,
    "PASSFAIL SUMMARY": <PassFailSummary />

  };
  // const innerWidth = window.innerWidth;
  // const itemsToShow = innerWidth / 130;

  // const currentShowingTabs = openTabs.tabs.slice(0, parseInt(itemsToShow));
  // const hiddenTabs = openTabs.tabs.slice(parseInt(itemsToShow));


  const innerWidth = window.innerWidth;
  const itemsToShow = innerWidth / 130;



  let currentShowingTabs = openTabs.tabs.slice(0, parseInt(itemsToShow));


  const hiddenTabs = openTabs.tabs.slice(parseInt(itemsToShow));
  const userId = secureLocalStorage.getItem(
    sessionStorage.getItem("sessionId") + "userId"
  )



  const {
    data: singleUserData,
    isFetching: isSingleUserFetching,
    isLoading: isSingleUserLoading,
  } = useGetUserByIdQuery(userId, { skip: !userId });




  function initialTab() {
    if ((currentShowingTabs?.length == 0) && (singleUserData?.data?.roleId)) {

      dispatch(push(
        {
          active: true,
          id: 93,
          name: "COMMON DASHBOARD",
          projectId: "",
          projectForm: false,
        }
      ));
    }
    else {
      return
    }
  }



  return (
    <div className="relative">{console.log(currentShowingTabs, "currentShowingTabs")}
      <div className="flex justify-between">
        <div className="flex gap-2">
          {(currentShowingTabs?.length == 0 ? initialTab() : currentShowingTabs)?.map((tab, index) => (
            <div
              key={index}
              className={`p-1 rounded-t-md text-[10px] flex justify-center gap-1 mt-3 ${tab.active ? "bg-red-300" : "bg-white"
                }`}
            >
              <button
                onClick={() => {
                  dispatch(push({ name: tab.name }));
                  // dispatch(push({ id: tab.id }));
                }}
              >
                {tab.name}
              </button>
              <button className="px-1 rounded-xs transition"
                onClick={() => {
                  dispatch(remove({ name: tab.name }));
                  // dispatch(remove({ id: tab.id }));
                }}
              >
                {CLOSE_ICON}
              </button>
            </div>
          ))}
        </div>
        <div>
          {(hiddenTabs.length !== 0) &&
            <button onClick={() => setShowHidden(true)}>
              {DOUBLE_NEXT_ICON}
            </button>
          }
        </div>
        {showHidden &&
          <ul ref={ref} className="absolute right-0 top-5 bg-gray-200 z-50 text-xs p-1">
            {hiddenTabs.map(tab =>
              <li key={tab.name} className={`flex justify-between  ${tab.active ? "bg-green-300" : "bg-gray-300"
                } `}>
                <button
                  onClick={() => {
                    dispatch(push({ name: tab.name }));
                    // dispatch(push({ id: tab.id }));
                  }}
                >
                  {tab.name}
                </button>
                <button className="hover:bg-red-400 px-1 rounded-xs transition"
                  onClick={() => {
                    dispatch(remove({ name: tab.name }));
                    // dispatch(remove({ id: tab.id }));
                  }}
                >
                  {CLOSE_ICON}
                </button>
              </li>
            )}
          </ul>
        }
      </div>

      {(openTabs?.tabs?.length === 0 ? initialTab() : openTabs?.tabs)?.map((tab, index) => (
        <div key={index} className={`${tab.active ? "block" : "hidden"}`}>
          {tabs[tab.name]}
        </div>
      ))}
    </div>
  );
};

export default ActiveTabList;
