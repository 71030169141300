import moment from 'moment'
import React from 'react'
import { dropDownListObject } from '../../../Utils/contructObject'
import { DropdownInput } from '../../../Inputs'
import { filterDays } from '../../../Utils/DropdownData'

const SelectionModel = ({ fromDate, setFromDate, toDate, setToDate, finYearDatas, finYearId, setFinYearId, filterByDays, setFilterByDays }) => {
    return (
        <div>


            <div className='w-full flex flex-col justify-center items-center '  >

                <div className='w-3/4'>
                    {/* <DropdownInput
                        name="FilterData"
                        options={filterDays}
                        value={filterByDays}
                        setValue={setFilterByDays}


                    /> */}
                </div>




                <div className=' grid-cols-1 md:grid-cols-3 items-center md:py-1 md:px-1 data w-3/4'>
                    <label htmlFor="id" className={`md:text-start flex`}>
                        From :
                    </label>
                    <input
                        type={"date"}
                        className={`input-field focus:outline-none md:col-span-2 border border-gray-500 text-gray-600 rounded  p-1 text-sm `}
                        id='id'
                        value={fromDate ? moment.utc(fromDate).format('YYYY-MM-DD') : ""}
                        onChange={(e) => setFromDate(e.target.value)}

                    />
                </div>

                <div className=' grid-cols-1 md:grid-cols-3 items-center md:py-1 md:px-1 data w-3/4'>
                    <label htmlFor="id" className={`md:text-start flex`}>
                        To :
                    </label>
                    <input
                        type={"date"}
                        className={`input-field focus:outline-none md:col-span-2 border border-gray-500 text-gray-600 rounded  p-1 text-sm `}
                        id='id'
                        value={toDate ? moment.utc(toDate).format('YYYY-MM-DD') : ""}
                        onChange={(e) => setToDate(e.target.value)}

                    />
                </div>

                <div className='w-2/4'>
                    <DropdownInput
                        name="Fin Year"
                        options={dropDownListObject(finYearDatas, "shortCode", "id")}
                        value={finYearId}
                        setValue={setFinYearId}

                        className="w-full text-sm"
                    />

                </div>
            </div>


        </div>
    )
}

export default SelectionModel