import React from 'react';
import BlendResultInput from './BlendResultInput';
import ResultInputEntryLevel from './ResultInputEntryLevel';

const ResultInput = ({ resultDetailsWithResult, paraItem, item, getAllResultTestLevel, isSelectedOption, getTextBasedResult, isTextBased, setResultDetailsWithResult, handleChangeBlend, fiberContentId, singleParameter, parameters, parameter, getResult, labTestRequirementDetailsId, resultDetails, setResultDetails, matchingSampleDetails, childRecord, loopsubHeading, testItem, requirementList, isResult, isEditable, getRegex, isDimensionalStability, readOnly }) => {

  const datas = resultDetails ? resultDetails.find(item => item?.labTestRequirementDetailsId === labTestRequirementDetailsId) : []
  const parameterName = (datas?.parameterName)?.toLowerCase();
  let testParameters = parameters.flatMap(item => item.labTestAnalyteDetailsParameters)

  const findDecimalValue = (testParameters?.find(item => item?.name.toLowerCase() == parameterName))?.decimalValue
  const decimalValue = findDecimalValue ? findDecimalValue : 0;


  const resultData = datas?.resultValue ? datas?.resultValue : []
  function handleOnChange(newValue, subHeading, field, resultIndex) {
    setResultDetails(prev => {
      const reqIndex = prev.findIndex(obj => obj.labTestRequirementDetailsId === labTestRequirementDetailsId);
      let newReq = structuredClone(prev);
      const subHeadingIndex = newReq[reqIndex]["resultValue"].findIndex(i => (i?.subHeading || '')?.toLowerCase() === (subHeading || '')?.toLowerCase())
      newReq[reqIndex]["resultValue"][subHeadingIndex][field] = newValue;
      if (field !== 'value') {
        let original = parseFloat(newReq[reqIndex]["resultValue"][subHeadingIndex]['original'] || 0);
        let afterWash = parseFloat(newReq[reqIndex]["resultValue"][subHeadingIndex]['afterWash'] || 0);
        let out = (original > 0 && afterWash > 0) ? parseFloat(((afterWash - original) / original) * 100).toFixed(1) : 0;
        newReq[reqIndex]["resultValue"][subHeadingIndex]['value'] = out;
      }
      return newReq
    })

  }


  function getEval(value) {
    // return eval(value)
    try {
      return eval(value)
    } catch (err) {
      return value
    }
  }


  return (
    <>
      {
        singleParameter?.selectedOption === "CLAIMEDFIBERCONTENT" ?
          <tr >
            {
              resultData?.filter(result => result.requirementGroup.toLowerCase() == loopsubHeading.toLowerCase()).map((result, resultIndex) =>
                <BlendResultInput getRegex={getRegex} parameter={parameter} loopsubHeading={loopsubHeading} matchingSampleDetails={matchingSampleDetails} decimalValue={decimalValue} getEval={getEval}
                  getResult={getResult} isResult={isResult} setResultDetailsWithResult={setResultDetailsWithResult} resultDetailsWithResult={resultDetailsWithResult} resultDetails={resultDetails} key={result.id} singleParameter={singleParameter} result={result} resultIndex={resultIndex} handleChangeBlend={handleChangeBlend} labTestRequirementDetailsId={labTestRequirementDetailsId}
                  isEditable={isEditable} childRecord={childRecord} />
              )}
          </tr>
          :
          resultData?.filter(result => result?.requirementGroup?.toLowerCase() === loopsubHeading.toLowerCase()).map((result, resultIndex) =>
            <>
              {
                isDimensionalStability ?
                  <>
                    <td className='border border-gray-500 p-0.5' >
                      <input type="text" className=" text-center p-1 w-full focus:outline-none border border-gray-500"
                        value={result?.original}
                        onChange={(e) => handleOnChange(e.target.value, result?.subHeading || '', "original", resultIndex)}
                        readOnly={readOnly} disabled={(childRecord.current > 0)} />
                    </td>
                    <td className='border border-gray-500 p-0.5' >
                      <input type="text" className=" text-center w-full p-1 focus:outline-none border border-gray-500"
                        value={result?.afterWash}
                        onChange={(e) => handleOnChange(e.target.value, result?.subHeading || '', "afterWash", resultIndex)}
                        readOnly={readOnly} disabled={(childRecord.current > 0)} />
                    </td>
                  </>
                  : <></>
              }
              <ResultInputEntryLevel paraItem={paraItem} resultDetailsWithResult={resultDetailsWithResult} key={resultIndex} setResultDetails={setResultDetails} resultDetails={resultDetails} item={item} getAllResultTestLevel={getAllResultTestLevel} isSelectedOption={isSelectedOption} getTextBasedResult={getTextBasedResult} isTextBased={isTextBased} requirementList={requirementList} setResultDetailsWithResult={setResultDetailsWithResult} labTestRequirementDetailsId={labTestRequirementDetailsId}
                resultIndex={resultIndex} isResult={isResult} isEditable={isEditable} result={result} getResult={getResult} getRegex={getRegex} handleOnChange={handleOnChange} childRecord={childRecord} parameter={parameter} loopsubHeading={loopsubHeading} matchingSampleDetails={matchingSampleDetails} decimalValue={decimalValue} getEval={getEval
                } isDimensionalStability={isDimensionalStability}
              />
            </>
          )}
    </>
  );
}

export default ResultInput;
