import React from 'react'
import { useUpdateLabTestResultEntryMutation } from '../../../redux/LabServices/LabTestResultEntryServices';
import { TICK_ICON } from '../../../icons';
import { CLOSE_ICON } from '../../../icons';
import { toast } from 'react-toastify';

const TestLists = ({ activeRegistrationId, testComments, setTestComments, passFail, test, testIndex, splitTest }) => {
  const [updateData] = useUpdateLabTestResultEntryMutation();
  const handleSubmitCustom = async (callback, commentsData, text, isDataClear = false) => {
    try {
      await callback(commentsData).unwrap();
      toast.success(text + "Successfully");
    } catch (error) {
      console.log("handle");
    }
  };
  const saveData = (testId, comments, isDataClear = false) => {

    if (!window.confirm("Are you sure save the details ...?")) {
      return;
    }
    handleSubmitCustom(updateData, { testId, comments, registrationId: activeRegistrationId, reportType: splitTest, isTestComments: true }, "Updated", isDataClear);
  };

  const handleInputChange = (event, testId, field, testIndex) => {
    const value = event.target.value;
    let newBlend;
    newBlend = structuredClone(testComments)
    if (testIndex === -1) return
    newBlend[testIndex][field] = value;
    setTestComments(newBlend);
  };


  return (
    <>

      {

        passFail == "PASS" && test?.pass ?
          <>
            <td className='border border-black text-xs text-center'>
              {`${test?.testName.toLowerCase() || ""} (${[test?.testSamples?.map(value => value).join("&")]})`}

            </td>
            <td className='border border-black text-xs text-center'>

              {CLOSE_ICON}
            </td>
            <td className='border border-black text-xs text-center'>

            </td>
            <td className='border border-black text-xs text-center'>
              { }
            </td>
            <td className='border border-black text-xs text-center'>
              <input className="h-full w-full p-2  text-center uppercase" name="decimalValue"
                type="text"
                value={test?.comments.toLowerCase() || ""}
                onChange={(e) => handleInputChange(e, test.testId, "comments", testIndex)}
              />
            </td>
            <td className='border border-black text-xs text-center'>
              <button

                className='bg-green-400 rounded-lg p-1 w-9' onClick={() => { saveData(test?.testId, test?.comments) }}>Save
              </button>
            </td>

          </>

          :
          passFail == "FAIL" && test?.fail ?

            <>

              <td className='border border-black text-xs text-center'>
                {/* {`${test?.testName} [    ${test?.testSamples?.length > 1 ? test?.testSamples?.map(value => value).join("&"):""}   ]`} */}
                {`${test?.testName.toLowerCase() || ""} (${[test?.testSamples?.map(value => value).join("&")]})`}

              </td>
              <td className='border border-black text-xs text-center'>
                { }
              </td>
              <td className='border border-black text-xs text-center'>

                {CLOSE_ICON}

              </td>
              <td className='border border-black text-xs text-center'>
                { }
              </td>
              <td className='border border-black text-xs text-center'>
                <input className="h-full w-full p-2  text-center uppercase" name="decimalValue"
                  type="text"
                  value={test?.comments.toLowerCase() || ""}
                  onChange={(e) => handleInputChange(e, test.testId, "comments", testIndex)}
                />
              </td>
              <td className='border border-black text-xs text-center'>
                <button

                  className='bg-green-400 rounded-lg p-1 w-9' onClick={() => { saveData(test?.testId, test?.comments) }}>Save
                </button>
              </td>
            </>
            :
            passFail == "ALL" ?
              <>
                <td className='border border-black text-xs text-center'>
                  {/* {`${test?.testName} [${test?.testSamples?.length > 1 ? test?.testSamples?.map(value => value).join("&"):""}]`} */}
                  {`${test?.testName.toLowerCase() || ""} (${[test?.testSamples?.map(value => value).join("&")]})`}

                </td>
                <td className='border border-black text-xs text-center'>
                  {test?.pass ? CLOSE_ICON : ""}
                </td>
                <td className='border border-black text-xs text-center'>
                  {test?.fail ? CLOSE_ICON : ""}
                </td>
                <td className='border border-black text-xs text-center'>
                  {test?.data ? CLOSE_ICON : ""}
                </td>
                <td className='border border-black text-xs text-center'>
                  <input className="h-full w-full p-2  text-center uppercase" name="decimalValue"
                    type="text"
                    value={test?.comments.toLowerCase() || ""}
                    onChange={(e) => handleInputChange(e, test.testId, "comments", testIndex)}
                  />
                </td>
                <td className='border border-black text-xs text-center'>
                  <button

                    className='bg-green-400 rounded-lg p-1 w-9' onClick={() => { saveData(test?.testId, test?.comments) }}>Save
                  </button>
                </td>

              </>
              : ""

      }


    </>
  )
}

export default TestLists