import React, { useState } from "react";

import accreditationLogo from "../../../assets/aggrelogo.png"

import moment from "moment";

import HeaderLogo from "../PrintFormat-TestReport/HeaderLogo";
import FactoryAddress from "../PrintFormat-TestReport/FactoryAddress";
import SampleImage from "./SampleImage";
import SampleDetails from "./SampleDetails";
import TestResultsList from "./TestResultsList";
import SampleTestParameters from "./SampleTestParameters";
import TestwiseFailureImages from "./TestwiseFailureImages";
import SamplewiseTestedImages from "./SamplewiseTestedImages";
import ColourFastnessLegend from "./ColourFastnessLegend";


export default function TestReportPreview({ headingOptions, washCareData, componentRef, testComments, setTestComments, splitTest, activeRegistrationId, reportData, passFail }) {


  let testResults = reportData?.data?.testResultDetails;
  return (
    <div className="font-times p-4 text-xs capitalize bg-white " style={{ lineHeight: 2 }}>{console.log(reportData, "reportttt")}


      <div className="ml-5 page">{console.log(reportData, "report")}
        <HeaderLogo data={reportData?.data} headingOptions={headingOptions} />
        <div className='gap-y-2 grid grid-cols-1 place-items-start w-full place-content-center p-0.5 '>
          {
            splitTest == "ACCREDITATION" ?

              <h1> <strong>Test Report No :</strong>{reportData?.data?.testReportNo} - A</h1>
              :
              splitTest == "NONACCREDITATION" ?
                <h1> <strong>Test Report No :</strong>{reportData?.data?.testReportNo} - B</h1>
                :
                <h1> <strong>Test Report No :</strong>{reportData?.data?.testReportNo}</h1>
          }
          <h1>  {splitTest == "ACCREDITATION" && reportData?.data?.testDetailsSummary?.accredidatedResults.length > 0 && reportData?.data?.ulrNo != null ? `${reportData?.data?.ulrNo || ""}${"F"}` : splitTest == "NONACCREDITATION" ? "--" : splitTest == "ALL" && reportData?.data?.testDetailsSummary?.nonAccredidatedResults.length > 0 && reportData?.data?.ulrNo != null ? `${reportData?.data?.ulrNo}${"P"}` : reportData?.data?.ulrNo ? `${reportData?.data?.ulrNo}${"F"}` : "--"}</h1>
          <h1><strong>Date Out :</strong>{moment.utc(reportData?.data?.logoutDate).format("DD-MM-YYYY")}  </h1>
          {reportData?.data?.amendmentReason &&
            <>
              <h1><strong>Amendment <span>{reportData?.data?.testReportNo?.slice(-1)}</span> Date :</strong>{moment.utc(reportData?.data?.amendmentLogOutDate).format("DD-MM-YYYY")}  </h1>
              {/* <h1><strong>Amendment Reason :</strong>{reportData?.data?.amendmentReason}  </h1> */}
            </>
          }
        </div>
        <div className='text-center font-bold text-sm'>
          Original Sample Image
        </div>
        {(reportData?.data?.sampleImages ? reportData?.data.sampleImages : []).map((sampleItem, index) => {
          return <SampleImage sampleItem={sampleItem} key={index} reportData={reportData?.data} splitTest={splitTest} />
        })}
        <div className="mt-5">
          <FactoryAddress />
        </div>

      </div>
      <div className="mt-5">
        <HeaderLogo data={reportData?.data} headingOptions={headingOptions} />

      </div>
      <SampleDetails key={reportData?.data} washCareData={washCareData} data={reportData?.data} splitTest={splitTest} activeRegistrationId={activeRegistrationId} />

      <div className="mt-5">
        <FactoryAddress />
      </div>
      <div className="mt-5">
        <HeaderLogo data={reportData?.data} headingOptions={headingOptions} />

      </div>
      <TestResultsList activeRegistrationId={activeRegistrationId} reportData={reportData?.data} testComments={testComments} setTestComments={setTestComments} passFail={passFail} splitTest={splitTest} />
      <div className="mt-5">
        <FactoryAddress />
      </div>
      <div className="mt-5">
        <HeaderLogo data={reportData?.data} headingOptions={headingOptions} />

      </div>

      <div className='gap-y-3 grid grid-cols-1 place-items-start w-full place-content-center p-1'>
        {
          splitTest == "ACCREDITATION" ?

            <h1><strong>Test Report No :</strong>{reportData?.data?.testReportNo} A</h1>
            :
            splitTest == "NONACCREDITATION" ?
              <h1><strong>Test Report No :</strong>{reportData?.data?.testReportNo} B</h1>
              :
              <h1><strong>Test Report No :</strong>{reportData?.data?.testReportNo}</h1>

        }
        <h1>{splitTest == "ACCREDITATION" && reportData?.data?.testDetailsSummary?.accredidatedResults.length > 0 && reportData?.data?.ulrNo ? `${reportData?.data?.ulrNo}${"F"}` : splitTest == "NONACCREDITATION" ? "" : splitTest == "ALL" && reportData?.data?.testDetailsSummary?.nonAccredidatedResults.length > 0 && reportData?.data?.ulrNo ? `${reportData?.data?.ulrNo}${"P"}` : reportData?.data?.ulrNo ? `${reportData?.data?.ulrNo}${"F"}` : "--"}</h1>
        <h1><strong>Date Out :</strong>{moment.utc(reportData?.data?.logoutDate).format("DD-MM-YYYY")}  </h1>
        {reportData?.data?.amendmentReason &&
          <>
            <h1><strong>Amendment  <span>{reportData?.data?.testReportNo?.slice(-1)}</span>  Date :</strong>{moment.utc(reportData?.data?.amendmentLogOutDate).format("DD-MM-YYYY")}  </h1>

          </>
        }
      </div>
      <div className="grid place-items-end mr-9 -mt-20">
        {(splitTest == "ACCREDITATION" || splitTest == "ALL") && (reportData?.data?.testResultDetails?.accredidationTestResults?.flatMap(i => i.passTestResults)?.length > 0 || reportData?.data?.testResultDetails?.accredidationTestResults?.flatMap(i => i.failTestResults).length > 0) ?
          <img className="bg-gray-400 rounded-xl   w-36" src={accreditationLogo} />
          : ""
        }
      </div>
      <div className='ml-16 flex  w-full  mt-24 font-bold '>
        <div className="w-[30%]">
          Test Name
        </div>
        <div className='pl-40 w-[50%]'>
          Test Result
        </div>
        <div className=' w-[20%]'>
          Requirement
        </div>
      </div>
      {
        passFail == "PASS" && splitTest == "ACCREDITATION" ? (
          <div>
            {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
              return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index} reportData={reportData?.data} />
            })}

            <ColourFastnessLegend testResults={testResults} />

            {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(val => !val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
              return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index}
                reportData={reportData?.data} />
            })}
          </div>
        )
          :
          passFail == "PASS" && splitTest == "NONACCREDITATION" ? (
            <div>
              {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
                return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index} reportData={reportData?.data} />
              })}

              <ColourFastnessLegend testResults={testResults} />

              {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(val => !val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
                return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index} reportData={reportData?.data} />
              })}
            </div>
          )
            :
            passFail == "PASS" && splitTest == "ALL" ? (
              <div>
                {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
                  return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index} reportData={reportData?.data} />
                })}

                {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
                  return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index} reportData={reportData?.data} />
                })}

                <ColourFastnessLegend testResults={testResults} />

                {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(val => !val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
                  return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index} reportData={reportData?.data} />
                })}

                {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(val => !val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
                  return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index} reportData={reportData?.data} />
                })}
              </div>
            )
              :
              passFail == "FAIL" && splitTest == "ACCREDITATION" ? (
                <div>
                  {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.failTestResults)?.map((item, index) => {
                    return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index} reportData={reportData?.data} />
                  })}

                  <ColourFastnessLegend testResults={testResults} />

                  {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(val => !val.isColourFastness)?.flatMap(i => i.failTestResults)?.map((item, index) => {
                    return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index} reportData={reportData?.data} />
                  })}
                </div>
              )
                :
                passFail == "FAIL" && splitTest == "NONACCREDITATION" ? (
                  <div>
                    {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.failTestResults)?.map((item, index) => {
                      return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index} reportData={reportData?.data} />
                    })}

                    <ColourFastnessLegend testResults={testResults} />
                    {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(val => !val.isColourFastness)?.flatMap(i => i.failTestResults)?.map((item, index) => {
                      return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index} reportData={reportData?.data} />
                    })}
                  </div>
                )
                  :

                  passFail == "FAIL" && splitTest == "ALL" ? (

                    <div>
                      {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.failTestResults)?.map((item, index) => {
                        return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index} reportData={reportData?.data} />
                      })}

                      {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.failTestResults)?.map((item, index) => {
                        return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index} reportData={reportData?.data} />
                      })}

                      <ColourFastnessLegend testResults={testResults} />

                      {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(val => !val.isColourFastness)?.flatMap(i => i.failTestResults)?.map((item, index) => {
                        return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index} reportData={reportData?.data} />
                      })}



                      {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(val => !val.isColourFastness)?.flatMap(i => i.failTestResults)?.map((item, index) => {
                        return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index} reportData={reportData?.data} />
                      })}
                    </div>
                  )
                    :

                    passFail == "ALL" && splitTest == "ACCREDITATION" ? (
                      <div>

                        {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
                          return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index} reportData={reportData?.data} />
                        })}


                        {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.failTestResults)?.map((item, index) => {
                          return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index} reportData={reportData?.data} />
                        })}

                        <ColourFastnessLegend testResults={testResults} />

                        {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(val => !val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
                          return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index} reportData={reportData?.data} />
                        })}



                        {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(val => !val.isColourFastness)?.flatMap(i => i.failTestResults)?.map((item, index) => {
                          return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index} reportData={reportData?.data} />
                        })}
                      </div>
                    )
                      :
                      passFail == "ALL" && splitTest == "NONACCREDITATION" ? (
                        <div>
                          {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
                            return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index} reportData={reportData?.data} />
                          })}

                          {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.failTestResults)?.map((item, index) => {
                            return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index} reportData={reportData?.data} />
                          })}

                          <ColourFastnessLegend testResults={testResults} />

                          {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(val => !val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
                            return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index} reportData={reportData?.data} />
                          })}



                          {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(val => !val.isColourFastness)?.flatMap(i => i.failTestResults)?.map((item, index) => {
                            return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index} reportData={reportData?.data} />
                          })}
                        </div>
                      )
                        :

                        passFail == "ALL" && splitTest == "ALL" ? (

                          <div>


                            {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
                              return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index} reportData={reportData?.data} />
                            })}

                            {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(j => j.isColourFastness).flatMap(i => i.failTestResults)?.map((item, index) => {
                              return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index} reportData={reportData?.data} />
                            })}

                            {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(val => val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
                              return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index} reportData={reportData?.data} />
                            })}



                            {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(j => j.isColourFastness).flatMap(i => i.failTestResults)?.map((item, index) => {
                              return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index} reportData={reportData?.data} />
                            })}

                            <ColourFastnessLegend testResults={testResults} />

                            {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(val => !val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
                              return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index} reportData={reportData?.data} />
                            })}

                            {reportData?.data?.testResultDetails?.accredidationTestResults?.filter(j => !j.isColourFastness).flatMap(i => i.failTestResults)?.map((item, index) => {
                              return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index} reportData={reportData?.data} />
                            })}



                            {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(val => !val.isColourFastness)?.flatMap(i => i.passTestResults)?.map((item, index) => {
                              return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index} reportData={reportData?.data} />
                            })}


                            {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.filter(j => !j.isColourFastness).flatMap(i => i.failTestResults)?.map((item, index) => {
                              return <SampleTestParameters activeRegistrationId={activeRegistrationId} key={index} passFail={passFail} item={item} index={index} reportData={reportData?.data} />
                            })}
                          </div>
                        )
                          : ""

      }


      <div className="mt-5">
        <FactoryAddress />
      </div>


      <div className="page">

        {

          (reportData?.data?.testResultDetails?.accredidationTestResults?.flatMap(val => val.failTestResults?.flatMap(item => item.failureImages)).length > 0)
            || (reportData?.data?.testResultDetails?.nonAccredidationTestResults?.flatMap(val => val.failTestResults?.flatMap(item => item.failureImages)).length > 0)

            ?
            (



              <div>
                <div className="mt-5">
                  <HeaderLogo data={reportData?.data} headingOptions={headingOptions} />

                </div>

                <div className='gap-y-3 grid grid-cols-1 place-items-start w-full place-content-center p-1 ml-2'>



                  {
                    splitTest == "ACCREDITATION" ?

                      <h1><strong>Test Report No :</strong>{reportData?.data?.testReportNo} -A</h1>
                      :
                      splitTest == "NONACCREDITATION" ?
                        <h1><strong>Test Report No :</strong>{reportData?.data?.testReportNo} -B</h1>
                        :
                        <h1><strong>Test Report No :</strong>{reportData?.data?.testReportNo}</h1>

                  }
                  <h1> {splitTest == "ACCREDITATION" && reportData?.data?.testDetailsSummary?.accredidatedResults.length > 0 && reportData?.data?.ulrNo ? `${reportData?.data?.ulrNo}${"F"}` : splitTest == "NONACCREDITATION" ? "" : splitTest == "ALL" && reportData?.data?.testDetailsSummary?.nonAccredidatedResults.length > 0 && reportData?.data?.ulrNo ? `${reportData?.data?.ulrNo}${"P"}` : `${reportData?.data?.ulrNo}${"F"}`}</h1>

                  <h1><strong>Date Out :</strong>{moment.utc(reportData?.data?.logoutDate).format("DD-MM-YYYY")}  </h1>
                  {reportData?.data?.amendmentReason &&
                    <>
                      <h1><strong>Amendment  <span>{reportData?.data?.testReportNo?.slice(-1)}</span>  Date :</strong>{moment.utc(reportData?.data?.amendmentLogOutDate).format("DD-MM-YYYY")}  </h1>

                    </>
                  }
                </div>



                {
                  passFail == "FAIL" && splitTest == "ACCREDITATION" || passFail == "ALL" && splitTest == "ACCREDITATION" ? (
                    <div className="text-center">
                      {reportData?.data?.testResultDetails?.accredidationTestResults?.flatMap(i => i.failTestResults)?.map((item, index) => {
                        return <TestwiseFailureImages item={item} index={index} reportData={reportData?.data} />
                      })}


                    </div>
                  )
                    :
                    passFail == "FAIL" && splitTest == "NONACCREDITATION" || passFail == "ALL" && splitTest == "NONACCREDITATION" ? (
                      <div className="text-center">
                        {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.flatMap(i => i.failTestResults)?.map((item, index) => {
                          return <TestwiseFailureImages item={item} index={index} reportData={reportData?.data} />

                        })}



                      </div>
                    )
                      :

                      passFail == "FAIL" && splitTest == "ALL" || passFail == "ALL" && splitTest == "ALL" ? (

                        <div className="text-center">
                          {reportData?.data?.testResultDetails?.accredidationTestResults?.flatMap(i => i.failTestResults)?.map((item, index) => {
                            return <TestwiseFailureImages item={item} index={index} reportData={reportData?.data} />

                          })}



                          {reportData?.data?.testResultDetails?.nonAccredidationTestResults?.flatMap(i => i.failTestResults)?.map((item, index) => {
                            return <TestwiseFailureImages item={item} index={index} reportData={reportData?.data} />

                          })}


                        </div>
                      )
                        : ""




                }
                <div className="mt-5">
                  <FactoryAddress />
                </div>

              </div>
            ) : ""
        }





        {

          reportData?.data?.testedImages?.length > 0

            ?
            (

              <div>
                <div className="mt-5">
                  <HeaderLogo data={reportData?.data} headingOptions={headingOptions} />

                </div>

                <div className='gap-y-3 grid grid-cols-1 place-items-start w-full place-content-center p-1 ml-2'>

                  {
                    splitTest == "ACCREDITATION" ?

                      <h1><strong>Test Report No :</strong>{reportData?.data?.testReportNo} -A</h1>
                      :
                      splitTest == "NONACCREDITATION" ?
                        <h1><strong>Test Report No :</strong>{reportData?.data?.testReportNo} -B</h1>
                        :
                        <h1><strong>Test Report No :</strong>{reportData?.data?.testReportNo}</h1>

                  }
                  <h1> {splitTest == "ACCREDITATION" && reportData?.data?.testDetailsSummary?.accredidatedResults.length > 0 && reportData?.data?.ulrNo ? `${reportData?.data?.ulrNo}${"F"}` : splitTest == "NONACCREDITATION" ? "" : splitTest == "ALL" && reportData?.data?.testDetailsSummary?.nonAccredidatedResults.length > 0 && reportData?.data?.ulrNo ? `${reportData?.data?.ulrNo}${"P"}` : `${reportData?.data?.ulrNo}${"F"}`}</h1>
                  <h1><strong>Date Out :</strong>{moment.utc(reportData?.data?.logoutDate).format("DD-MM-YYYY")}  </h1>
                  {reportData?.data?.amendmentReason &&
                    <>
                      <h1><strong>Amendment  <span>{reportData?.data?.testReportNo?.slice(-1)}</span>  Date :</strong>{moment.utc(reportData?.data?.amendmentLogOutDate).format("DD-MM-YYYY")}  </h1>

                    </>
                  }
                  <div className=' mt-5 underline uppercase text-center w-full font-bold'>
                    Test Sample Images
                  </div>
                  <div className="text-center w-full">

                    {
                      reportData?.data?.testedImages?.map((testImg, testImgIndex) => {
                        return <SamplewiseTestedImages passFail={passFail} key={testImgIndex} testImg={testImg} testImgIndex={testImgIndex} splitTest={splitTest} />
                      })
                    }
                  </div>


                  <div className="mt-5">
                    <FactoryAddress />
                  </div>
                </div>
              </div>
            ) : ""
        }
      </div>
      <div className="text-center w-full mt-5 font-bold">
        --- End of Test Report ---
      </div>
    </div>

  );
}
