import React from 'react'
import { useGetLabTestRequirementMasterQuery } from '../../../redux/LabServices/LabTestRequirementMasterServices';
import secureLocalStorage from 'react-secure-storage';
import TestAllParameters from './TestAllParameters';

const TestParametersLists = ({ val, dataObj, item }) => {




    return (
        <>
            {
                val?.testParameters?.length > 1 &&
                <span>
                    {
                        val?.testParameters?.length > 1 ? item?.sampleId?.charAt(item?.sampleId?.length - 1) : ""
                    }<br />
                </span>
            }

            {
                item?.currentResultValue?.filter(j => j.LabTestRequirementDetails?.LabTestRequirement?.testId == val.testId)?.filter(i => i.LabTestRequirementDetails?.labTestAnalyteDetailsParameters?.isReport)?.map((item, itemIndex) =>
                    <TestAllParameters key={itemIndex} item={item} val={val} dataObj={dataObj} />
                )
            }





        </>
    )
}

export default TestParametersLists