import React from 'react'
import { COPY_ICON, TICK_ICON } from '../../../icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { walesCourseList } from '../../../Utils/DropdownData'

const ParameterItem = ({ selectedOption, parameterIndex, parameter, index, handleInputChangeParameter, sampleTypeTwoList, id, readOnly, childRecord, activeCurrentIndex, onKeyDown, handleCopyPaste, setActiveCurrentIndex, setLabTestAnalyteDetails, childRecordExists }) => {

    return (
        <tr className="items-center" >
            <td className="border border-gray-500 text-xs text-center">{parameterIndex + 1}</td>
            <td className=" text-xs text-center border border-gray-500">


                <select
                    className='text-left w-full rounded h-8 py-2 focus:outline-none'
                    value={parameter.sampleTypeTwoId}
                    onBlur={(e) => {
                        handleInputChangeParameter(e.target.value, index, parameterIndex, "sampleTypeTwoId")
                    }
                    }
                    onChange={(e) => handleInputChangeParameter(e.target.value, index, parameterIndex, "sampleTypeTwoId")}
                    disabled={readOnly || childRecordExists}
                >
                    <option>
                        Select
                    </option>

                    {(id ? sampleTypeTwoList.data : sampleTypeTwoList.data.filter(item => item.active)).map((value) =>
                        <option value={value.id} key={value.id} >
                            {value.name}
                        </option>
                    )}
                </select>
            </td>
            <td className=" text-xs text-center border border-gray-500 w-12">
                <input type='checkbox' value={"isEdit"}
                    checked={parameter.isEditable}
                    disabled={readOnly || childRecordExists}
                    onChange={(e) => {
                        handleInputChangeParameter(e.target.checked, index, parameterIndex, "isEditable")

                    }} />

            </td>
            <td className=" text-xs text-center border border-gray-500 w-12">
                <input type='checkbox' value={"isResult"} checked={parameter.isResult}
                    disabled={readOnly || childRecordExists}
                    onChange={(e) => {
                        handleInputChangeParameter(e.target.checked, index, parameterIndex, "isResult")

                    }} />

            </td>
            <td className=" text-xs text-center border border-gray-500 w-12">
                <input type='checkbox' value={"isReport"} checked={parameter.isReport}
                    disabled={readOnly || childRecordExists}
                    onChange={(e) => {
                        handleInputChangeParameter(e.target.checked, index, parameterIndex, "isReport")

                    }} />

            </td>
            <td className=" text-xs text-center border border-gray-500">
                <input type='checkbox' value={"textBased"} checked={parameter.isTextBased}
                    disabled={readOnly || childRecordExists}
                    onChange={(e) => {
                        handleInputChangeParameter(e.target.checked, index, parameterIndex, "isTextBased")

                    }} />

            </td>
            {/* <td className=" text-xs text-center border border-gray-500">
                <input type='text' value={parameter.displayLineGroup}
                    disabled={readOnly || childRecordExists}
                    onChange={(e) => {
                        handleInputChangeParameter(e.target.value, index, parameterIndex, "displayLineGroup")
                    }} />
            </td> */}
            <td className="border border-gray-500 text-xs" >
                <div className="flex items-center gap-x-5">
                    <input className="h-full p-1 w-full uppercase" name="parameters" onKeyDown={(e) => onKeyDown(e, index)}
                        disabled={readOnly || childRecordExists}
                        type="text" value={parameter.name}
                        onChange={(e) => handleInputChangeParameter(e.target.value, index, parameterIndex, "name")}
                        readOnly={readOnly} />
                    <button className='w-12'
                        onClick={() => {
                            handleCopyPaste(parameter.name);
                        }}>{COPY_ICON}</button>
                </div>
            </td>
            {
                selectedOption == "CLAIMEDFABRICCOUNT" &&
                <>
                    <td className="border border-gray-500 text-xs" >

                        <select
                            className='text-left w-full rounded py-0.5 focus:outline-none'
                            value={parameter.walesCourse}
                            disabled={readOnly || childRecordExists}
                            onChange={(e) => handleInputChangeParameter(e.target.value, index, parameterIndex, "walesCourse")}
                        >
                            <option className='hidden'>
                                Select
                            </option>

                            {walesCourseList.map((value) =>
                                <option value={value.value} key={value.value} className='text-xs'>
                                    {value.show}
                                </option>
                            )}
                        </select>
                    </td>
                </>
            }
            <td className="border border-gray-500 text-xs text-center w-6">
                <input className="h-full w-full p-2  text-center uppercase" name="decimalValue" onKeyDown={(e) => onKeyDown(e, parameterIndex)}
                    type="number" value={parameter.decimalValue}
                    onBlur={(e) => {
                        handleInputChangeParameter(e.target.value, index, parameterIndex, "decimalValue")
                    }
                    }
                    disabled={readOnly || childRecordExists}
                    onChange={(e) => handleInputChangeParameter(e.target.value, index, parameterIndex, "decimalValue")}
                    readOnly={readOnly} />
            </td>


            <td className="border border-gray-500 text-xs"
            >
                <div className='flex gap-3'>
                    <input className="w-full h-full p-1 uppercase" name="value" onKeyDown={(e) => onKeyDown(e, parameterIndex)}
                        disabled={readOnly || childRecordExists}
                        type="text" value={parameter.value}
                        onClick={(e) =>
                            setActiveCurrentIndex({ e, parameterIndex })
                        }
                        onFocus={(e) => {
                            setActiveCurrentIndex({ e, parameterIndex });
                        }}
                        onBlur={() => {
                            if (Number.isInteger(activeCurrentIndex.parameterIndex)) {
                                activeCurrentIndex.e.target.focus()
                            }
                        }
                        }
                        onChange={(e) => handleInputChangeParameter(e.target.value, index, parameterIndex, "value")}
                        readOnly={readOnly} />
                    <button className='bg-green-200'
                        onClick={() => setActiveCurrentIndex({})}
                        disabled={readOnly || childRecordExists}
                    >
                        {TICK_ICON}
                    </button>
                </div>

            </td>
            <td className="border border-gray-500 p-1 text-xs text-center" >
                <button
                    type='button' tabIndex={-1}
                    disabled={readOnly || childRecordExists}
                    onClick={() => {
                        setLabTestAnalyteDetails(prev => {
                            let newPrev = structuredClone(prev);
                            newPrev[index]["labTestAnalyteDetailsParameters"] = newPrev[index]["labTestAnalyteDetailsParameters"].filter((_, i) => i !== parameterIndex)

                            return newPrev
                        })
                    }}
                    className='text-md text-red-600 ml-1'>{<FontAwesomeIcon icon={faTrashCan} />}</button>
            </td>
            <td className="text-center border border-gray-500 text-xs p-1">
                <button type='button'
                    disabled={readOnly || childRecordExists}
                    className="text-green-700" onClick={() => {
                        setLabTestAnalyteDetails(prev => {
                            let newPrev = structuredClone(prev);
                            newPrev[index]["labTestAnalyteDetailsParameters"] = [...newPrev[index]["labTestAnalyteDetailsParameters"],
                            {
                                sampleTypeTwoId: "",
                                isEditable: false,
                                isResult: false,
                                isTextBased: false,
                                name: "",
                                decimalValue: 0,
                                value: ""
                            }]
                            return newPrev
                        })

                    }}> {<FontAwesomeIcon icon={faUserPlus} />}
                </button>
            </td>

        </tr>
    )
}

export default ParameterItem
