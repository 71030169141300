import React from 'react'
import Result from './Result'
import ResultReq from './ResultReq'
import { Text, View } from '@react-pdf/renderer'
import tw from '../../../../Utils/tailwind-react-pdf'



const SampleTest = ({ item, test, testIndex, reportData, valIndex, val }) => {
    let reqIndex = test?.result?.findIndex(val => val?.result?.length > 0);


    function isDisplayOrNot(item, val) {

        let filterTestResults = item?.filter(i => i?.sampleId?.slice(-1) == val)

        let result = filterTestResults?.findIndex(val => val.result?.length > 0) == -1 ? false : true

        return result
    }

    if (!isDisplayOrNot(test?.result, val)) return <></>
    if (item.isDimensionalStablity) return (
        <View style={tw("flex flex-row gap-x-5 w-full")}>
            <View style={tw("w-[20%]  capitalize")}>
                <Text style={tw('capitalize text-xs text-left mt-3')}>
                    {/* {test?.result?.flatMap(val => val.result)?.length > 0 ? (test?.aliasname?.toLowerCase() || "") : ""} */}

                    {test?.aliasname.toLowerCase() || ""}
                </Text>
            </View>

            <View style={tw('capitalize text-xs text-left w-[60%] mt-3')}>
                {
                    test?.result
                        .filter((i) =>
                            reportData?.samples.length > 1
                                ?
                                i.sampleId.split("-")[1] === val
                                : true
                        )?.map((i, testIndex) => {
                            return <Result key={valIndex} item={item} result={i} reportData={reportData} />
                        }
                        )
                }

            </View>
            <View style={tw("w-[20%]")}>
                <Text style={tw('text-xs text-left mt-3')}>


                    {`${test?.result[reqIndex]?.result[0]?.finalClaimedValue ? test?.result[reqIndex]?.result[0]?.finalClaimedValue : ""} ${test?.result[reqIndex]?.result[0]?.finalClaimedValue ? "" : ""}  ${test?.result[reqIndex]?.result[0]?.formulaAliasName ? test?.result[reqIndex]?.result[0]?.formulaAliasName.toLowerCase() : (test?.result[reqIndex]?.result[0]?.requirementValue || "")}`}

                </Text>
            </View>


        </View>
    )
    return (
        <>

            {
                item?.isRemovalForce ? (
                    <View style={tw("flex flex-row w-full ")}>

                        <View style={tw("w-[20%] capitalize")}>
                            <Text style={tw('capitalize text-xs text-left mt-2')}>
                                {/* {test?.result?.flatMap(val => val.result)?.length > 0 ? (test?.aliasname?.toLowerCase() || "") : ""} */}

                                {test?.aliasname.toLowerCase() || ""}
                            </Text>
                        </View>

                        <View style={tw('capitalize text-xs text-center w-[60%] mt-2')}>
                            {
                                test?.result
                                    .filter((i) =>
                                        reportData?.samples.length > 1
                                            ?
                                            i.sampleId.split("-")[1] === val
                                            : true
                                    )
                                    .map((i, testIndex) => {
                                        return <Result key={valIndex} item={item} result={i} reportData={reportData} />
                                    }
                                    )
                            }
                            {/* <Result key={valIndex} item={item} result={test?.result[valIndex]} reportData={reportData} /> */}

                        </View>
                        <View style={tw('text-xs text-center w-[20%] mt-2')}>
                            <ResultReq key={valIndex} item={item} req={test?.result[reqIndex]} reportData={reportData} />

                        </View>
                    </View>
                )
                    :
                    <View wrap={false} style={tw("flex flex-row gap-x-5 w-full ")}>
                        <View style={tw("w-[40%]  capitalize")}>
                            <Text style={tw('capitalize text-xs text-left mt-3')}>
                                {/* {test?.result?.flatMap(val => val.result)?.length > 0 ? (test?.aliasname?.toLowerCase() || "") : ""} */}

                                {test?.aliasname.toLowerCase() || ""}
                            </Text>
                        </View>

                        <View style={tw('capitalize text-xs text-left w-[40%] mt-3')}>
                            {
                                test?.result
                                    .filter((i) =>
                                        reportData?.samples.length > 1
                                            ?
                                            i.sampleId.split("-")[1] === val
                                            : true
                                    )?.map((i, testIndex) => {
                                        return <Result key={valIndex} item={item} result={i} reportData={reportData} />
                                    }
                                    )
                            }

                        </View>
                        <View style={tw("w-[20%]")}>
                            <Text style={tw('text-xs text-left mt-3')}>


                                {`${test?.result[reqIndex]?.result[0]?.finalClaimedValue ? test?.result[reqIndex]?.result[0]?.finalClaimedValue : ""} ${test?.result[reqIndex]?.result[0]?.finalClaimedValue ? "" : ""}  ${test?.result[reqIndex]?.result[0]?.formulaAliasName ? test?.result[reqIndex]?.result[0]?.formulaAliasName.toLowerCase() : (test?.result[reqIndex]?.result[0]?.requirementValue || "")}`}

                            </Text>
                        </View>


                    </View>
            }















































            {/* {
                item?.isRemovalForce ? (
                    <View style={tw("flex flex-row  w-full ")}>

                        <View style={tw(" w-[40%]")}>
                            <Text style={tw('capitalize text-xs text-left mt-2')}>
                                {test?.aliasname}
                            </Text>
                        </View>

                        <View style={tw('capitalize text-xs  flex flex-row gap-x-9 justify-center items-center w-[40%]')}>

                            {
                                test?.result?.map((result, resultIndex) => {
                                    return <Result key={resultIndex} item={item} result={result} resultIndex={resultIndex} reportData={reportData} />
                                })
                            }
                        </View>
                        <View style={tw('text-xs text-center w-[20%]')}>
                            {
                                test?.result?.map((req, reqIndex) => {
                                    return <ResultReq key={reqIndex} item={item} req={req} reqIndex={reqIndex} reportData={reportData} />
                                })
                            }
                        </View>
                    </View>
                )
                    :
                    <View style={tw("flex flex-row gap-x-5 w-full ")}>
                        <View style={tw("w-[40%]  capitalize")}>
                            <Text style={tw('capitalize text-xs text-left mt-3')}>
                                {test?.aliasname}
                            </Text>
                        </View>

                        <View style={tw('capitalize text-xs flex flex-row gap-x-9 justify-left items-center w-[40%] ')}>
                            {
                                test?.result?.map((result, resultIndex) => {
                                    return <Result key={resultIndex} item={item} result={result} resultIndex={resultIndex} reportData={reportData} />
                                })
                            }
                        </View>
                        <View style={tw("w-[20%]")}>
                            <Text style={tw('text-xs text-left mt-3')}>
                              

                                {`${test?.result[0]?.result[0]?.finalClaimedValue ? test?.result[0]?.result[0]?.finalClaimedValue : ""} ${test?.result[0]?.result[0]?.finalClaimedValue ? "-----" : ""}       ${test?.result[0]?.result[0]?.formulaAliasName ? test?.result[0]?.result[0]?.formulaAliasName : (test?.result[0]?.result[0]?.requirementValue || "")}`}

                            </Text>
                        </View>


                    </View>
            } */}

        </>

    )
}

export default SampleTest