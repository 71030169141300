import React from 'react'
import { useEffect, useState } from "react";
import { useGetPartyQuery } from "../../../redux/ErpServices/PartyMasterServices"
import { Loader } from "../../../Basic/components";
import { showEntries } from '../../../Utils/DropdownData';
import secureLocalStorage from 'react-secure-storage';
import { findFromList, getDateFromDateTimeToDisplay, pageNumberToReactPaginateIndex, reactPaginateIndexToPageNumber } from '../../../Utils/helper';
import ReactPaginate from 'react-paginate';
import { useGetRegistrationQuery, useUpdateRegistrationMutation } from '../../../redux/LabServices/RegistrationServices';
import { useGetPackageMasterQuery } from '../../../redux/LabServices/PackageMasterServices';
import { useGetLabTestResultEntryQuery } from '../../../redux/LabServices/LabTestResultEntryServices';
import { DateInput, DropdownInput, Modal } from '../../../Inputs';
import TestReportContainerPreview from '../DashBoard/TestReportContainerPreview';
import { BACKWARD_ICON_PDF, VIEW } from '../../../icons';
import { SIGNOUT_ICON_PDF } from '../../../icons';
import { toast } from 'react-toastify';
import TestReportDocument from '../DashBoard/TestReportContainerPreviewReactPdf';
import TestReportRevert from '../AdminResultEntry/TestReportRevert';
import { useGetUserByIdQuery } from '../../../redux/services/UsersMasterService';


import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import excelIcon from "../../../assets/icons8-microsoft-excel-48.png"
import TestParameters from './TestParameters';
import { dropDownListObject } from '../../../Utils/contructObject';
import TestParametersLists from './TestParametersLists';
import TestAllParameters from './TestAllParameters';
import TestParametersResult from './TestParameterResult';
import TestParametersReq from './TestParametersReq';
import TestOverAllResult from './TestOverAllResult';



const PassFailExcel = ({
    heading = "PassFail Summary",
    dataClick
}) => {
    const branchId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "currentBranchId"
    )
    const [searchDocId, setSearchDocId] = useState("");

    const [searchDueDate, setSearchDueDate] = useState("");
    const [searchSupplierName, setSearchSupplierName] = useState("");
    const [searchBuyerName, setSearchBuyerName] = useState("");
    const [searchPackageName, setSearchPackageName] = useState("");
    const [dataPerPage, setDataPerPage] = useState("10");
    const [totalCount, setTotalCount] = useState(0);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [searchSampleLoginDate, setSearchSampleLoginDate] = useState();
    const [isSearchVisible, setIsSearchVisible] = useState(false)
    const [buyerId, setBuyerId] = useState("");
    const [packageId, setPackageId] = useState("");
    const [testArray, setTestArray] = useState([])
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")

    const handleOnclick = (e) => {
        setCurrentPageNumber(reactPaginateIndexToPageNumber(e.selected));
    }
    const searchFields = { searchDocId, searchSupplierName, searchBuyerName, searchDueDate, searchPackageName, searchSampleLoginDate }

    useEffect(() => { setCurrentPageNumber(1) }, [dataPerPage, searchDocId, searchSupplierName, searchBuyerName, searchDueDate, searchPackageName])

    const companyId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "userCompanyId"
    )
    const params = {
        branchId, companyId
    };

    const userId = {
        userId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userId"
        ),
    };

    const {
        data: singleUserData,

    } = useGetUserByIdQuery(userId.userId, { skip: !userId.userId });

    const { data: allData, isLoading, isFetching } = useGetRegistrationQuery({ params: { branchId, ...searchFields, fromDate: startDate, toDate: endDate, isPassFailSummary: true, buyerId, packageId } }, { skip: !(endDate && startDate) });
    const { data: packageList, isLoading: isPackageLoading, isFetching: isPackageFetching } = useGetPackageMasterQuery({ params })
    const { data: supplierList, isLoading: isSupplierLoading, isFetching: isSupplierFetching } =
        useGetPartyQuery({ params: { ...params } });




    // useEffect(() => {
    //     if (allData?.totalCount) {
    //         setTotalCount(allData?.totalCount)
    //     }
    // }, [allData, isLoading, isFetching])


    useEffect(() => {
        let registrationFormReportComponent = document.getElementById("registrationFormReport");
        registrationFormReportComponent.addEventListener('keydown', function (ev) {
            var focusableElementsString = '[tabindex="0"]';
            let ol = document.querySelectorAll(focusableElementsString);
            if (ev.key === "ArrowDown") {
                for (let i = 0; i < ol.length; i++) {
                    if (ol[i] === ev.target) {
                        let o = i < ol.length - 1 ? ol[i + 1] : ol[0];
                        o.focus(); break;
                    }
                }
                ev.preventDefault();
            } else if (ev.key === "ArrowUp") {
                for (let i = 0; i < ol.length; i++) {
                    if (ol[i] === ev.target) {
                        let o = ol[i - 1];
                        o.focus(); break;
                    }
                }
                ev.preventDefault();
            }
        });
        return () => {
            registrationFormReportComponent.removeEventListener('keydown', () => { });
        };
    }, []);




    function findUniqueTestArray() {
        if (!allData?.data) return
        let newArray = [];
        let registrationTestArray = allData?.data?.flatMap(val => val?.testResultDetails)

        let findMaxTestParameters = registrationTestArray?.flatMap(i => i.testParameters)



        findMaxTestParameters = findMaxTestParameters.reduce(
            (acc, obj) => {
                if (obj.currentResultValue.length > acc.maxLength) {
                    return { maxLength: obj.currentResultValue.length, maxObject: obj };
                }
                return acc;
            },
            { maxLength: 0, maxObject: null }
        );




        registrationTestArray = registrationTestArray?.filter(val => val.registrationId == findMaxTestParameters?.maxObject?.registrationId)


        // registrationTestArray = registrationTestArray.filter(
        //     (item, index, self) => self.findIndex(t => parseInt(t.testId) === parseInt(item.testId)) === index
        // );



        return registrationTestArray
    }


    function findTestParameters(testParameters, testId) {
        let allSampleTestParameters = testParameters?.flatMap(val => val.currentResultValue)
        let filterByTestId = allSampleTestParameters?.filter(val => val.LabTestRequirementDetails?.LabTestRequirement?.testId == testId)
        filterByTestId = filterByTestId.filter(
            (item, index, self) => self.findIndex(t => parseInt(t.LabTestRequirementDetails?.labTestAnalyteDetailsParameters?.id) === parseInt(item.LabTestRequirementDetails?.labTestAnalyteDetailsParameters?.id)) === index
        );



        return filterByTestId
    }


    function findTestParametersResult(testParameters, testId) {
        let allSampleTestParameters = testParameters?.flatMap(val => val.currentResultValue)
        let filterByTestId = allSampleTestParameters?.filter(val => val.LabTestRequirementDetails?.LabTestRequirement?.testId == testId)
        return filterByTestId
    }



    function findReportValue(parameterId, testParameters, testId) {
        let totalParameterResult = findTestParametersResult(testParameters, testId);
        totalParameterResult = totalParameterResult?.filter(item => item?.LabTestRequirementDetails?.labTestAnalyteDetailsParameters?.id == parameterId)

        let finalResultValue = totalParameterResult?.flatMap(val => val.resultValue)

        return finalResultValue
    }

    const columnWidthClass = "w-72";
    const columnWidthClasstr = "min-w-36";

    function allIntegersRegex(str) {

        return !isNaN(parseFloat(str)) && isFinite(str);
    }


    function getOverAllResultTestLevel(testId, testParameters) {

        return testParameters?.flatMap(i => i.currentResultValue)?.filter(j => j.LabTestRequirementDetails?.LabTestRequirement?.testId == testId)?.filter(i => i.LabTestRequirementDetails?.labTestAnalyteDetailsParameters?.isResult).every((datas) => {
            const resultData = datas?.resultValue ? datas?.resultValue : []

            return resultData.every((result) => {
                return result?.result
            })
        })
    }


    return (
        <>


            <div id='registrationFormReport' className="flex flex-col w-full h-[95%] overflow-auto  ">
                <div className="md:flex md:items-center md:justify-between page-heading p-1">
                    <div className="heading text-center md:mx-10">{heading}</div>
                    <div className=" sub-heading justify-center md:justify-start items-center">
                        <label className="text-white text-sm rounded-md m-1  border-none">Show Entries</label>
                        <select value={dataPerPage}
                            onChange={(e) => setDataPerPage(e.target.value)} className='h-6 w-40 border border-gray-500 rounded mr-9'>
                            {showEntries.map((option, index) => <option key={index} value={option.value} >{option.show}</option>)}
                        </select>
                    </div>
                </div>
                <>
                    <div
                        className="h-full overflow-auto"
                    >
                        <div className='grid grid-cols-6 gap-x-3 p-2 w-full'>


                            <DropdownInput name="Buyer Name" options={dropDownListObject(supplierList?.data ? supplierList?.data.filter(item => item.active && item.isBuyer) : [], "name", "id")} value={buyerId} setValue={setBuyerId} required={true} />
                            <DropdownInput name="Package" options={dropDownListObject(packageList?.data ? packageList?.data.filter(item => parseInt(buyerId) === parseInt(item.buyerNameId)).filter(item => item.active) : [], "name", "id")} value={packageId} setValue={setPackageId} required={true}
                            />

                            <DateInput inputHead={"font-bold text-sm"} name={"From :"} value={startDate} setValue={setStartDate} />
                            <DateInput inputHead={"font-bold text-sm"} name={"To :"} value={endDate} setValue={setEndDate} />


                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="download-table-xls-button text-white bg-blue-400 text-xs rounded-xl"
                                table="passFail-summary"
                                filename="tablexls"
                                sheet="tablexls"
                                buttonText="GoToExcel"

                            >
                            </ReactHTMLTableToExcel>


                        </div>






                        <table id="passFail-summary" style={{ display: 'none' }} className="table-auto text-center w-full border border-gray-400">
                            <thead className=" table-Excel-header">
                                <tr className='h-2 text-xs textExcel'>
                                    <th
                                        className="border-2  top-0 stick-bg w-12 textExcel p-1"
                                    >
                                        S. no.
                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel p-1"
                                    >
                                        <div>Lab.Report.No</div>
                                    </th>

                                    <th
                                        className="border-2  top-0 stick-bg textExcel p-1"
                                    >
                                        <div>LogIn.Date</div>
                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel p-1"
                                    >
                                        <div>Rep.Released.Date</div>
                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel p-1"
                                    >
                                        <div>Style.No</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel p-1"
                                    >
                                        <div>Batch.No</div>

                                    </th>
                                    <th
                                        className="border-2 top-0 stick-bg textExcel w-20 p-1"
                                    >
                                        Color.Name

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel p-1"
                                    >
                                        <div>TestStage</div>

                                    </th>
                                    <th

                                        className=" textExcel p-1"
                                    >
                                        <div>Supplier</div>
                                    </th>

                                    <th
                                        className="border-2  top-0 stick-bg textExcel p-1"
                                    >
                                        <div>Buyer</div>
                                    </th>
                                    {
                                        findUniqueTestArray()?.map((val, valIndex) =>
                                            <>
                                                <th
                                                    className="border-2  top-0 stick-bg textExcel min-w-72"
                                                >
                                                    Test

                                                </th>
                                                <th
                                                    className="border-2  top-0 stick-bg textExcel min-w-72"
                                                >

                                                    Parameters


                                                </th>
                                                <th
                                                    className="border-2  top-0 stick-bg textExcel w-16"
                                                >
                                                    <div>Result</div>

                                                </th>

                                                <th
                                                    className="border-2  top-0 stick-bg textExcel w-16"
                                                >
                                                    <div>Requirement</div>

                                                </th>
                                                <th
                                                    className="border-2  top-0 stick-bg textExcel w-16"
                                                >
                                                    <div>Conclusion</div>

                                                </th>
                                            </>
                                        )
                                    }



                                </tr>
                            </thead>

                            <tbody className="border-2">
                                {(allData?.data || []).map((dataObj, index) => (

                                    <tr
                                        key={dataObj.id}
                                        className=" table-Excel-row  cursor-pointer text-xs"                    >
                                        <td className=' textExcel w-12 p-1'> {(index + 1) + (dataPerPage * (currentPageNumber - 1))}</td>
                                        <td className=' textExcel p-1 min-w-32'> {dataObj.testReportNo}</td>
                                        <td className=' textExcel p-1 w-20'>{getDateFromDateTimeToDisplay(dataObj.sampleLoginDate)} </td>
                                        <td className=' textExcel p-1 w-20 '> {dataObj?.logoutDate ? getDateFromDateTimeToDisplay((dataObj?.logoutDate)) : ""}</td>
                                        <td className='p-1 textExcel min-w-32'> {dataObj.styleNo || ""}</td>
                                        <td className='p-1 textExcel w-20 '> {dataObj.batchNo || ""}</td>
                                        <td className='p-1  textExcel capitalize w-20 '>{dataObj.colorName || ""} </td>
                                        <td className='p-1  textExcel capitalize w-20'> {dataObj.testStage || ""}</td>
                                        <td className='p-1 textExcel capitalize w-20'>{dataObj.companyName || ""}</td>
                                        <td className='p-1  textExcel capitalize w-20 '>{dataObj?.buyerName || ""}     </td>


                                        {
                                            dataObj?.testResultDetails?.map((val, valIndex) =>
                                                <>
                                                    {
                                                        val.testName ?
                                                            <td className='textExcel text-left min-w-72 capitalize'>
                                                                <span className='w-72'>
                                                                    {val.testName ? val.testName?.toLowerCase()
                                                                        : ""}
                                                                </span>

                                                            </td>
                                                            :
                                                            <td className='textExcel text-left w-60 capitalize'>

                                                            </td>

                                                    }
                                                    <td className='textExcel text-left min-w-96 capitalize'>
                                                        {
                                                            val?.testParameters?.map((item, itemIndex) =>
                                                                <TestParametersLists key={itemIndex} item={item} val={val} dataObj={dataObj} />
                                                            )
                                                        }
                                                    </td>
                                                    <td className='textExcel text-left min-w-96 capitalize'>
                                                        {
                                                            val?.testParameters?.map((item, itemIndex) =>
                                                                <TestParametersResult key={itemIndex} item={item} val={val} dataObj={dataObj} />
                                                            )
                                                        }
                                                    </td>
                                                    <td className='textExcel text-left min-w-96 capitalize'>
                                                        {
                                                            val?.testParameters?.map((item, itemIndex) =>
                                                                <TestParametersReq key={itemIndex} item={item} val={val} dataObj={dataObj} />
                                                            )
                                                        }
                                                    </td>


                                                    {
                                                        val?.testParameters?.length > 1 ?
                                                            <td className='textExcel text-left min-w-96 capitalize'>
                                                                {
                                                                    getOverAllResultTestLevel(val.testId, val?.testParameters) ?
                                                                        <span className='text-black  p-1 rounded-md'>Pass</span>
                                                                        :
                                                                        <span className='text-black  p-1 rounded-md '>Fail</span>
                                                                }
                                                            </td>
                                                            :



                                                            <td className='textExcel text-left min-w-96 capitalize'>
                                                                {
                                                                    val?.testParameters?.map((item, itemIndex) =>
                                                                        <TestOverAllResult key={itemIndex} item={item} val={val} dataObj={dataObj} />
                                                                    )
                                                                }
                                                            </td>
                                                    }

                                                </>
                                            )
                                        }





                                    </tr>
                                ))}
                            </tbody>

                        </table>



                        <table id="original-table" className="table-auto text-center w-full border border-gray-400">
                            <thead className=" table-Excel-header">
                                <tr className='h-2 text-xs textExcel'>
                                    <th
                                        className="border-2  top-0 stick-bg w-20 textExcel p-1"
                                    >
                                        S. no.
                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel p-1 min-w-32"
                                    >
                                        <div>Lab.Report.No</div><input
                                            type="text"
                                            className="text-black h-6 focus:outline-none border  border-gray-400 rounded-lg w-24"
                                            placeholder="Search"
                                            value={searchDocId}
                                            onChange={(e) => {
                                                setSearchDocId(e.target.value);
                                            }}
                                        />
                                    </th>

                                    <th
                                        className="border-2  top-0 stick-bg textExcel p-1 w-20"
                                    >
                                        <div>LogIn.Date</div><input
                                            type="text"
                                            className="text-black h-6 focus:outline-none border  border-gray-400 rounded-lg w-24"
                                            placeholder="Search"
                                            value={searchSampleLoginDate}
                                            onChange={(e) => {
                                                setSearchSampleLoginDate(e.target.value);
                                            }}
                                        />
                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel p-1 w-20"
                                    >
                                        <div>Rep.Released.Date</div><input
                                            type="text"
                                            className="text-black h-6 focus:outline-none border  border-gray-400 rounded-lg w-24"
                                            placeholder="Search"
                                            value={searchDueDate}
                                            onChange={(e) => {
                                                setSearchDueDate(e.target.value);
                                            }}
                                        />
                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel p-1 min-w-32"
                                    >
                                        <div>Style.No</div>

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel p-1 min-w-28"
                                    >
                                        <div>Batch.No</div>

                                    </th>
                                    <th
                                        className="border-2 top-0 stick-bg textExcel min-w-32 p-1"
                                    >
                                        Color.Name

                                    </th>
                                    <th
                                        className="border-2  top-0 stick-bg textExcel p-1 min-w-32"
                                    >
                                        <div>TestStage</div>

                                    </th>
                                    <th

                                        className=" textExcel p-1 min-w-32"
                                    >
                                        <div>Supplier</div><input
                                            type="text"
                                            className="text-black  h-6 focus:outline-none border  border-gray-400 rounded-lg w-24"
                                            placeholder="Search"
                                            value={searchSupplierName}
                                            onChange={(e) => {
                                                setSearchSupplierName(e.target.value);
                                            }}
                                        />
                                    </th>

                                    <th
                                        className="border-2  top-0 stick-bg textExcel p-1 min-w-32"
                                    >
                                        <div>Buyer</div><input
                                            type="text"
                                            className="text-black h-6 focus:outline-none border  border-gray-400 rounded-lg w-24"
                                            placeholder="Search"
                                            value={searchBuyerName}
                                            onChange={(e) => {
                                                setSearchBuyerName(e.target.value);
                                            }}
                                        />
                                    </th>


                                    {
                                        findUniqueTestArray()?.map((val, valIndex) =>
                                            <>
                                                <th
                                                    className="border-2  top-0 stick-bg textExcel min-w-72"
                                                >
                                                    Test

                                                </th>
                                                <th
                                                    className="border-2  top-0 stick-bg textExcel min-w-72"
                                                >

                                                    Parameters


                                                </th>
                                                <th
                                                    className="border-2  top-0 stick-bg textExcel w-16"
                                                >
                                                    <div>Result</div>

                                                </th>

                                                <th
                                                    className="border-2  top-0 stick-bg textExcel w-16"
                                                >
                                                    <div>Requirement</div>

                                                </th>
                                                <th
                                                    className="border-2  top-0 stick-bg textExcel w-16"
                                                >
                                                    <div>Conclusion</div>

                                                </th>
                                            </>
                                        )
                                    }






                                </tr>
                            </thead>

                            <tbody className="border-2">{console.log(allData, "allData")}
                                {(allData?.data || []).map((dataObj, index) => (

                                    <tr
                                        key={dataObj.id}
                                        className=" table-Excel-row  cursor-pointer text-xs"                    >
                                        <td className=' textExcel w-12 p-1'> {(index + 1) + (dataPerPage * (currentPageNumber - 1))}</td>
                                        <td className=' textExcel p-1 min-w-32'> {dataObj.testReportNo}</td>
                                        <td className=' textExcel p-1 w-20'>{getDateFromDateTimeToDisplay(dataObj.sampleLoginDate)} </td>
                                        <td className=' textExcel p-1 w-20 '> {dataObj?.logoutDate ? getDateFromDateTimeToDisplay((dataObj?.logoutDate)) : ""}</td>
                                        <td className='p-1 textExcel min-w-32'> {dataObj.styleNo || ""}</td>
                                        <td className='p-1 textExcel w-20 '> {dataObj.batchNo || ""}</td>
                                        <td className='p-1  textExcel capitalize w-20 '>{dataObj.colorName || ""} </td>
                                        <td className='p-1  textExcel capitalize w-20'> {dataObj.testStage || ""}</td>
                                        <td className='p-1 textExcel capitalize w-20'>{dataObj.companyName || ""}</td>
                                        <td className='p-1  textExcel capitalize w-20 '>{dataObj?.buyerName || ""}     </td>


                                        {
                                            dataObj?.testResultDetails?.map((val, valIndex) =>
                                                <>
                                                    {
                                                        val.testName ?
                                                            <td className='textExcel text-left min-w-72 capitalize'>
                                                                <span className='w-72'>
                                                                    {val.testName ? val.testName?.toLowerCase()
                                                                        : ""}
                                                                </span>

                                                            </td>
                                                            :
                                                            <td className='textExcel text-left w-60 capitalize'>

                                                            </td>

                                                    }
                                                    <td className='textExcel text-left min-w-96 capitalize '>
                                                        {
                                                            val?.testParameters?.map((item, itemIndex) =>
                                                                <TestParametersLists key={itemIndex} item={item} val={val} dataObj={dataObj} />
                                                            )
                                                        }
                                                    </td>
                                                    <td className='textExcel text-left min-w-96 capitalize'>
                                                        {
                                                            val?.testParameters?.map((item, itemIndex) =>
                                                                <TestParametersResult key={itemIndex} item={item} val={val} dataObj={dataObj} />
                                                            )
                                                        }
                                                    </td>
                                                    <td className='textExcel text-left min-w-96 capitalize'>
                                                        {
                                                            val?.testParameters?.map((item, itemIndex) =>
                                                                <TestParametersReq key={itemIndex} item={item} val={val} dataObj={dataObj} />
                                                            )
                                                        }
                                                    </td>


                                                    {
                                                        val?.testParameters?.length > 1 ?
                                                            <td className='textExcel text-left min-w-96 capitalize'>
                                                                {
                                                                    getOverAllResultTestLevel(val.testId, val?.testParameters) ?
                                                                        <span className='text-black  p-1 rounded-md'>Pass</span>
                                                                        :
                                                                        <span className='text-black  p-1 rounded-md '>Fail</span>
                                                                }
                                                            </td>
                                                            :



                                                            <td className='textExcel text-left min-w-96 capitalize'>
                                                                {
                                                                    val?.testParameters?.map((item, itemIndex) =>
                                                                        <TestOverAllResult key={itemIndex} item={item} val={val} dataObj={dataObj} />
                                                                    )
                                                                }
                                                            </td>
                                                    }
                                                </>
                                            )
                                        }

                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    </div>
                </>


            </div>
        </>
    )
}

export default PassFailExcel