import React from 'react'
import Result from './Result'
import ResultReq from './ResultReq'



const SampleTest = ({ item, test, testIndex, reportData, valIndex, val }) => {
    let reqIndex = test?.result?.findIndex(val => val?.result?.length > 0);


    // for (let i = 0; i < test?.result?.length; i++) {
    //     const valueIndex = test?.result[i]?.result?.length
    //     if (valueIndex) {
    //         reqIndex = test?.result[i]
    //     }

    // }



    function isDisplayOrNot(item, val) {
        let filterTestResults = item?.filter(i => i?.sampleId?.slice(-1) == val)


        let result = filterTestResults?.findIndex(val => val.result?.length > 0) == -1 ? false : true


        return result
    }

    if (!isDisplayOrNot(test?.result, val)) return <></>

    if (item.isRemovalForce) return (
        <div className='flex w-full '>
            <div className='capitalize text-xs text-left w-[30%] mt-1 '>
                {test?.aliasname?.toLowerCase() || ""}
            </div>
            <div className='capitalize text-xs  text-center w-[50%]'>
                {
                    test?.result
                        .filter((i) =>
                            reportData?.samples.length > 1
                                ?
                                i.sampleId.split("-")[1] === val
                                : true
                        )
                        .map((i, testIndex) => {
                            return <Result key={valIndex} item={item} result={i} reportData={reportData} />
                        }
                        )
                }
            </div>
            <div className=' capitalize text-xs text-right  w-[20%]'>
                <ResultReq key={valIndex} item={item} req={test?.result[valIndex]} reportData={reportData} />
            </div>
        </div>)
    if (item.isDimensionalStablity) return (
        <div className='flex w-full '>
            <div className='capitalize text-xs text-left w-[30%] mt-1 '>
                {test?.aliasname?.toLowerCase() || ""}
            </div>
            <div className='capitalize text-xs  text-center w-[50%]'>
                {
                    test?.result
                        .filter((i) =>
                            reportData?.samples.length > 1
                                ?
                                i.sampleId.split("-")[1] === val
                                : true
                        )
                        .map((i, testIndex) => {
                            return <Result key={valIndex} item={item} result={i} reportData={reportData} />
                        }
                        )
                }
            </div>
            <div className=' capitalize text-xs text-right  w-[20%]'>
                <ResultReq key={valIndex} item={item} req={test?.result[valIndex]} reportData={reportData} />
            </div>
        </div>)
    return (
        <div className='flex w-full '>
            <div className='capitalize text-xs text-left w-[30%] mt-2'>
                {test?.aliasname?.toLowerCase() || ""}
            </div>
            <div className='capitalize text-xs  flex gap-x-9 justify-center items-center w-[50%]'>
                {
                    test?.result
                        .filter((i) =>
                            reportData?.samples.length > 1
                                ?
                                i.sampleId.split("-")[1] === val
                                : true
                        )
                        .map((i, testIndex) => {
                            return <Result key={valIndex} item={item} result={i} reportData={reportData} />
                        }
                        )
                }
            </div>
            <div className='capitalize text-xs text-center w-[20%] mt-1'>
                {`${test?.result[reqIndex]?.result[0]?.finalClaimedValue ? test?.result[reqIndex]?.result[0]?.finalClaimedValue : ""} ${test?.result[reqIndex]?.result[0]?.finalClaimedValue ? "" : ""}       ${test?.result[reqIndex]?.result[0]?.formulaAliasName ? (test?.result[reqIndex]?.result[0]?.formulaAliasName.toLowerCase() || "") : (test?.result[reqIndex]?.result[0]?.requirementValue || "")}`}
            </div>
        </div>

    )
}

export default SampleTest