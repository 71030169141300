import React, { useState } from "react";
import LogoutExcel from "./LogoutExcel";
import PassFailExcel from "./PassFailExcel";


export default function PassFailSummary() {

    return <PassFailExcel
        dataClick={(sampleId, registrationId) => {

        }}
    />
    // return <LogoutExcel
    //     dataClick={(sampleId, registrationId) => {

    //     }}
    // />
}