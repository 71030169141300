import React from 'react'


const TestAllResult = ({ item, val, dataObj }) => {
    function allIntegersRegex(str) {

        return !isNaN(parseFloat(str)) && isFinite(str);
    }



    return (
        <>

            <span className=''>
                {item?.resultValue[0]?.reportValue ? allIntegersRegex(item?.resultValue[0]?.reportValue) ? parseFloat(item?.resultValue[0]?.reportValue).toFixed(2) : item?.resultValue[0]?.reportValue : ""}<br />
            </span>





        </>
    )
}

export default TestAllResult