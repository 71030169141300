import React from 'react'
import logo from "../../../assets/SRG.png"
const Header = () => {
  return (
    <>
 <div className="w-full ml-48">
          <img className="bg-gray-400 rounded-xl   w-36" src={logo} />
          </div> 
    <div className='flex gap-x-5 justify-center'>
   
          <div className="text-center mt-1 text-xl font-bold">
          TEXTILE TESTING LABORATORY
          </div>
    </div>
    
   
          {/* <div className="text-center text-md font-bold">
         UNDER SRG APPARELS LIMITED (PROCESSING DIVISION)
          </div>  */}

   
    </>
  )
}

export default Header