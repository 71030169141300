import React, { useState } from 'react'
import { PreviewButtonOnly } from '../../../Buttons'
import { headingOption, passFailOption, splitTestOption } from '../../../Utils/DropdownData'
import { useGetLabTestResultEntryQuery } from '../../../redux/LabServices/LabTestResultEntryServices'
import secureLocalStorage from 'react-secure-storage'
import TestReportPreview from '../TestReportPreview'
import { Loader } from "../../../Basic/components";
import { useGetWashCareGroupMasterQuery } from "../../../redux/LabServices/WashCareGroupMasterServices";
import TestReportDocument from './TestReportContainerPreviewReactPdf'
import { Modal } from '../../../Inputs'
import MailAttachment from './MailAttachment'
import { BlobProvider, PDFViewer } from '@react-pdf/renderer'
import TestReport from '../Document-TestReport'
import { toast } from 'react-toastify'
import tw from '../../../Utils/tailwind-react-pdf'

const TestReportContainerPreview = ({ splitTest, setSplitTest, activeRegistrationId, passFail, setPassFail, isPreview = false, isDashBoard = false }) => {

    const [testComments, setTestComments] = useState([])
    const [testReportDocument, setTestReportDocument] = useState(false)
    const [headingOptions, setHeadingOptions] = useState("")
    const [attachments, setAttachments] = useState([])


    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
        branchId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "currentBranchId"
        ),

    };

    const { data: washCareData, isLoading: isWashCareGroupLoading, isFetching: isWashCareGroupFetching } =
        useGetWashCareGroupMasterQuery({ params: { ...params, includeWashCare: true } })


    const { data: reportData } = useGetLabTestResultEntryQuery({
        params: {
            ...params,
            registrationId: activeRegistrationId ? structuredClone(activeRegistrationId) : undefined,
            isFinalReport: true,
        }
    })

    let testReportNo = reportData?.data?.testReportNo ? reportData?.data?.testReportNo : ""
    if (splitTest === "ACCREDITATION") {
        testReportNo = testReportNo.concat("A");
    } else if (splitTest === "NONACCREDITATION") {
        testReportNo = testReportNo.concat("B");
    }
    if (isWashCareGroupLoading || isWashCareGroupFetching || !reportData) return <Loader />
    return (

        <div className='bg-white p-6 font-times '>

            <Modal isOpen={testReportDocument} onClose={() => setTestReportDocument(false)} widthClass={" h-[90%] w-[90%] bg-white p-2"}>

                <TestReportDocument headingOptions={headingOptions} splitTest={splitTest} setSplitTest={setSplitTest} activeRegistrationId={activeRegistrationId} passFail={passFail} setPassFail={setPassFail}
                    reportData={reportData} washCareData={washCareData} testComments={testComments}
                />

            </Modal>
            {
                isDashBoard ?
                    <MailAttachment attachments={attachments} setAttachments={setAttachments} washCareData={washCareData} testComments={testComments} setTestComments={setTestComments} splitTest={splitTest} setSplitTest={setSplitTest} activeRegistrationId={activeRegistrationId} passFail={passFail} setPassFail={setPassFail} reportData={reportData}
                    />
                    :
                    <></>
            }

            {
                isPreview &&
                <div className="flex w-full justify-end ">
                    {/* <PrintButtonOnly onClick={handlePrint} /> */}

                    <PreviewButtonOnly onClick={() => setTestReportDocument(true)} />

                </div>
            }

            <div className='flex gap-x-9'>
                <select
                    className='rounded-lg text-xs p-1 border border-gray-500'
                    value={passFail}

                    onChange={(e) => setPassFail(e.target.value)}
                >
                    <option className='hidden'>
                        Select
                    </option>

                    {passFailOption.map((val) =>
                        <option value={val.value} className='text-xs' >
                            {val.show}
                        </option>
                    )}
                </select>

                <select
                    className='rounded-lg text-xs p-1 border border-gray-500'
                    value={splitTest}

                    onChange={(e) => setSplitTest(e.target.value)}
                >
                    <option className='hidden'>
                        Select
                    </option>

                    {splitTestOption.map((val) =>
                        <option value={val.value} className='text-xs' >
                            {val.show}
                        </option>
                    )}
                </select>


                <select
                    className='rounded-lg text-xs p-1 border border-gray-500'
                    value={headingOptions}

                    onChange={(e) => setHeadingOptions(e.target.value)}
                >
                    <option className='hidden'>
                        Select
                    </option>

                    {headingOption.map((val) =>
                        <option value={val.value} className='text-xs' >
                            {val.show}
                        </option>
                    )}
                </select>
                <div className='mt-5'>
                    {
                        isDashBoard ?
                            <BlobProvider document={
                                <TestReport headingOptions={headingOptions} washCareData={washCareData} testComments={testComments} splitTest={splitTest} passFail={passFail} reportData={reportData} />} fileName="my_pdf3.pdf">
                                {({ blob }) =>
                                    <button className="bg-green-600 text-white rounded-md  p-1 w-32 text-sm"
                                        onClick={() => {
                                            setAttachments(prev => {
                                                if (prev.find(i => (i.result === passFail) && (i.accredidation === splitTest) && i.buyer === headingOptions)) {
                                                    toast.info("This File Already Attached...!")
                                                    return prev
                                                }
                                                return [...prev, {
                                                    result: passFail,
                                                    reportNo: testReportNo,
                                                    accredidation: splitTest,
                                                    buyer: headingOptions,
                                                    blob
                                                }]
                                            })
                                        }}
                                    >Attach</button>
                                }
                            </BlobProvider>
                            :
                            <></>
                    }

                </div>
            </div>
            {/* <div className=''>
                <TestReportDocument headingOptions={headingOptions} splitTest={splitTest} setSplitTest={setSplitTest} activeRegistrationId={activeRegistrationId} passFail={passFail} setPassFail={setPassFail}
                    reportData={reportData} washCareData={washCareData} testComments={testComments}
                />
            </div> */}
            <div className='overflow-auto flex flex-col'>
                <TestReportPreview headingOptions={headingOptions} washCareData={washCareData} testComments={testComments} setTestComments={setTestComments} splitTest={splitTest} setSplitTest={setSplitTest} activeRegistrationId={activeRegistrationId} reportData={reportData} passFail={passFail}
                />
            </div>

        </div>
    )
}

export default TestReportContainerPreview
