import React from 'react'

const CardWrapper = ({ name, children }) => {
    return (
        <div className='w-full h-[170px] text-center border border-gray-200'>
            <div className={`bg-gradient-to-b from-[#afafae] text-center rounded-xs flex items-center justify-center  border-2 border-[#E0E0E0] text-gray-800 bg-slate-600 py-1`}>
                <span className='text-[16px] font-normal text-black'>{name}</span>
            </div>
            <div className='h-[80%] p-1 '>
                {children}
            </div>
        </div>
    )
}

export default CardWrapper