import React from 'react'
import FactoryAddress from './FactoryAddress'
import { getImageUrlPath, viewBase64String } from '../../../Utils/helper'
import empty from "../../../assets/empty.png"

const SampleImage = ({ sampleItem, reportData, splitTest }) => {

  const imageWidth = "350px"
  const imageHeight = "300px";

  const image = sampleItem?.image

  return (
    <div className=''>

      <div className=' border-2 border-gray-900  w-[400px] h-[350px]   m-auto mt-3 pt-5 '>
        <div className='grid justify-center items-center '>
          {Boolean(image) ?
            <img style={{ height: imageHeight, width: imageWidth, objectFit: 'fit' }}
              src={getImageUrlPath(image)}
            />
            :
            <img src={empty} className='w-36 text-center' />
          }
        </div>
      </div>

    </div>
  )
}

export default SampleImage