import React from 'react'
import { useGetLabTestRequirementMasterQuery } from '../../../redux/LabServices/LabTestRequirementMasterServices';
import secureLocalStorage from 'react-secure-storage';
import TestAllParameters from './TestAllParameters';
import TestAllResult from './TestAllResult';

const TestOverAllResult = ({ val, dataObj, item }) => {



    function getAllResultTestLevel() {

        return item?.currentResultValue?.filter(j => j.LabTestRequirementDetails?.LabTestRequirement?.testId == val.testId)?.filter(i => i.LabTestRequirementDetails?.labTestAnalyteDetailsParameters?.isResult).every((datas) => {
            const resultData = datas?.resultValue ? datas?.resultValue : []
            console.log(resultData, "resultData")
            return resultData.every((result) => {
                return result?.result
            })
        })
    }




    return (
        <>



            {getAllResultTestLevel()
                ?
                <span className='text-black  p-1 rounded-md'>Pass</span>
                :
                <span className='text-black  p-1 rounded-md '>Fail</span>
            }








        </>
    )
}

export default TestOverAllResult