import React from 'react';

const ResultHeadingForDimensional = ({ labTestRequirementDetailsId, resultDetails, }) => {
  const datas = resultDetails ? resultDetails.find(item => item?.labTestRequirementDetailsId === labTestRequirementDetailsId) : []
  const resultData = datas?.resultValue || [];
  return (
    <>
      {
        resultData?.map((result, resultIndex) =>
          <>
            <td className='border border-gray-500 p-0.5' >
              <input type="text" className=" text-center p-1 w-full focus:outline-none "
                value={"Original (mm)"}
                disabled />
            </td>
            <td className='border border-gray-500 p-0.5' >
              <input type="text" className=" text-center w-full p-1 focus:outline-none "
                value={"After Wash(mm)"} disabled />
            </td>
            <td className='border border-gray-500  p-1 ' key={resultIndex}>
              <input type="text" className=" text-center w-full p-1 focus:outline-none"
                value={"Dimensional Change(%)"}
                disabled />
            </td>
          </>
        )
      }
    </>
  );
}



export default ResultHeadingForDimensional;
