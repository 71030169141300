import React, { useEffect, useState } from 'react'
import { PDFViewer, BlobProvider } from '@react-pdf/renderer';
import { findFromList, handleMailSend, params } from "../../../Utils/helper"
import { toast } from 'react-toastify';
import InvoiceDocument from '../Document-InvoiceReport';
import { useGetRegistrationByIdQuery, useUpdateRegistrationMutation } from '../../../redux/LabServices/RegistrationServices';
import { Loader } from '../../../Basic/components';
import { useGetBranchQuery } from '../../../redux/services/BranchMasterService';
import { useGetLabTestNameMasterQuery } from '../../../redux/LabServices/LabTestNameMasterServices';
import { useGetLabTestMethodMasterQuery } from '../../../redux/LabServices/LabTestMethodMasterServices';
import { useGetWashCareGroupMasterQuery } from '../../../redux/LabServices/WashCareGroupMasterServices';
import ConfirmationDocument from '../Document-SampleReceipt';
import { useGetLabTestResultEntryQuery } from '../../../redux/LabServices/LabTestResultEntryServices';
import secureLocalStorage from 'react-secure-storage';
import logo from "../../../../src/assets/srgmaillogo.png"

const SampleReceiptDocument = ({ activeRegistrationId }) => {
    const params = {
        companyId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "userCompanyId"
        ),
        branchId: secureLocalStorage.getItem(
            sessionStorage.getItem("sessionId") + "currentBranchId"
        ),

    };
    const [pdfBlob, setPdfBlob] = useState(null);
    const [sendData, setSendData] = useState({})
    const { data: branchData, isLoading: isBranchLoading, isFetching: isBranchFetching } = useGetBranchQuery({ params })
    const { data: registrationData, isLoading, isFetching } = useGetRegistrationByIdQuery({ id: activeRegistrationId }, { skip: !activeRegistrationId })





    const { data: reportData } = useGetLabTestResultEntryQuery({
        params: {
            ...params,
            registrationId: activeRegistrationId ? structuredClone(activeRegistrationId) : undefined,
            isFinalReport: true,
        }
    })



    useEffect(() => {
        if (registrationData?.data && branchData?.data) {
            const { branchId, endUseId } = registrationData.data
            let washCareDetails = []

            let testDetails = []

            const addFunction = (id, sampleChar, property, arr, methodId, washCareImage) => {
                let index = arr?.findIndex(item => parseInt(item[property]) === parseInt(id))
                if (index === -1) {
                    let newItem = {}
                    newItem[property] = id
                    newItem["methodId"] = methodId
                    newItem["image"] = washCareImage
                    newItem["samples"] = [sampleChar]
                    arr.push(newItem)
                } else {
                    arr[index]["samples"] = [...arr[index]["samples"], sampleChar]
                }
            }

            registrationData.data.RegistrationSampleDetails.forEach((RegistrationSampleDetail) => {
                RegistrationSampleDetail.SampleWashCareDetails.forEach((SampleWashCareDetail) => {
                    addFunction(SampleWashCareDetail.washCareId, RegistrationSampleDetail.sampleId.slice(-1), "washCareId", washCareDetails, "", SampleWashCareDetail?.WashCare?.image,)
                })
                RegistrationSampleDetail.SampleTestDetails.forEach((SampleTestDetail) => {
                    addFunction(SampleTestDetail.testId, RegistrationSampleDetail.sampleId.slice(-1), "testId", testDetails, SampleTestDetail.methodId)


                })
            })


            let data = {
                ...registrationData.data,
                washCareDetails,
                testDetails,
                branchCode: findFromList(branchId, branchData.data, "branchCode"),
            }
            setSendData(data)
        }
    }, [registrationData, isLoading, isFetching, branchData, isBranchFetching, isBranchLoading])


    const { data: testList } = useGetLabTestNameMasterQuery({ params })
    const { data: methodList } = useGetLabTestMethodMasterQuery({ params })


    const { data: washCareData, isLoading: isWashCareGroupLoading, isFetching: isWashCareGroupFetching } =
        useGetWashCareGroupMasterQuery({ params: { ...params, includeWashCare: true } })

    let companyEmail = sendData?.email

    if (!registrationData?.data && branchData?.data || isWashCareGroupLoading || isWashCareGroupFetching || !testList?.data || !methodList?.data) return <Loader />
    return (
        <div >
            <div className='mt-5'>
                <BlobProvider document={<ConfirmationDocument testList={testList} methodList={methodList} data={sendData} washCareData={washCareData} />} fileName="my_pdf3.pdf">
                    {({ blob }) => {
                        return <button className='bg-green-400 p-1 rounded-md mb-4' onClick={() => handleMailSend(blob, companyEmail, `${"Sample Acknowledgement"}/${registrationData?.data?.docId || ""}/${registrationData?.data?.SubmissionType?.name || ""}`, registrationData?.data?.docId, logo, "Sample Acknowledgement")}>Send Mail</button>;
                    }}
                </BlobProvider>
            </div>

            <PDFViewer className='w-full h-screen'>
                <ConfirmationDocument reportData={reportData} testList={testList} methodList={methodList} data={sendData} washCareData={washCareData} />
            </PDFViewer>
        </div>
    )
}

export default SampleReceiptDocument