import React, { useEffect, useState } from 'react'
import { useGetRegistrationByIdQuery, useUpdateRegistrationMutation } from '../../../redux/LabServices/RegistrationServices'
import { Loader } from '../../../Basic/components';


import { toast } from 'react-toastify';
import moment from 'moment';
import Header from '../PrintFormat-ProfomaInvoice/Header';
import Address from './Address';
import GenerateInvoiceNumber from './GenerateInvoiceNumber';
import FactoryAddress from '../PrintFormat-TestReport/FactoryAddress';
import Document from './Document';
import InvoiceReportDocument from '../DashBoard/InvoiceReportDocument';
import { CheckBox, Modal } from '../../../Inputs';
import empty from "../../../assets/empty.png"
import { getCommonParams, getImageUrlPath } from '../../../Utils/helper'
import { useGetBranchByIdQuery } from '../../../redux/services/BranchMasterService';
import { useGetPartyByIdQuery } from '../../../redux/ErpServices/PartyMasterServices';


const PrintInvoiceReport = ({ invoiceDocument, setInvoiceDocument, componentRef, id, setShowSaveButton, showSaveButton }) => {
    const [testDetailsData, setTestDetailsData] = useState([])
    const [amendment, setAmendment] = useState(false);
    const [invoiceDate, setInvoiceDate] = useState()
    const [amendmentPrice, setAmendmentPrice] = useState();
    const [surChargePrice, setSurChargePrice] = useState()
    const [amendmentQty, setAmendmentQty] = useState();
    const [amendmentDiscount, setAmendmentDiscount] = useState();
    const { data } = useGetRegistrationByIdQuery({ id: parseInt(id), params: { invoice: true } }, { skip: !id })
    const [packageDiscount, setPackageDiscount] = useState()
    const [update] = useUpdateRegistrationMutation()

    const { branchId } = getCommonParams();

    const { data: singleBranchData, isLoading: isSingleBranchDataLoading, isFetching: isSingleBranchDataFetching } = useGetBranchByIdQuery(branchId);


    const partyId = data?.data?.partyId

    const {
        data: singlePartyData,
        isFetching: isSinglepartyFetching,
        isLoading: isSinglePartyLoading,
    } = useGetPartyByIdQuery(partyId, { skip: !partyId });



    function isCalculateSurchargePrice(testPrice) {

        let FindUrgentPrice = data?.data?.service?.includes("URGENT")
        let FindExpressPrice = data?.data?.service?.includes("EXPRESS")

        if (FindUrgentPrice) {
            // if (data?.data?.typeOfInvoice == "PACKAGE") {
            //     setSurChargePrice(testPrice + testPrice)
            // }
            return testPrice + testPrice

        }
        else if (FindExpressPrice) {
            // if (data?.data?.typeOfInvoice == "PACKAGE") {
            //     setSurChargePrice(((parseFloat(40) / parseFloat(100)) * parseFloat(testPrice || 0)))
            // }

            return ((parseFloat(40) / parseFloat(100)) * parseFloat(testPrice || 0))
        }
        else {
            // setSurChargePrice(testPrice + testPrice)
            // setSurChargePrice(0)
            // return ((parseFloat(40) / parseFloat(100)) * parseFloat(testPrice || 0))

            return 0
        }

    }


    useEffect(() => {
        setPackageDiscount(data?.data?.packageDetails[0]?.discount ? data?.data?.packageDetails[0]?.discount : 0)
        setSurChargePrice(data?.data?.packageDetails[0]?.surChargePrice ? data?.data?.packageDetails[0]?.surChargePrice : isCalculateSurchargePrice(data?.data?.packageDetails[0]?.price))


    }, [data, setPackageDiscount])

    const handleSave = async (text) => {
        try {
            let returnData = await update({ registrationId: id, executiveSummaryUpdate: true, invoiceTestDetails: testDetailsData ? testDetailsData : [], discount: packageDiscount, amendmentDiscount, amendmentPrice, amendmentQty, amendment, surChargePrice }).unwrap();
            toast.success(text + "Successfully");
        } catch (error) {
            console.log("handle");
        }

    }


    function handleInputChange(value, index, field) {
        setTestDetailsData(prev => {
            let newData = structuredClone(prev);
            newData[index][field] = value;
            return newData
        })
    }

    useEffect(() => {
        if (!data?.data) return
        let testData = [];
        if (data?.data?.invoiceTestDetails.length > 0) {
            testData = data?.data?.invoiceTestDetails
        }
        else {

            let FindUrgentPrice = data?.data?.service?.includes("URGENT")
            let FindExpressPrice = data?.data?.service?.includes("EXPRESS")

            if (FindUrgentPrice) {
                data?.data?.testDetails?.forEach(val => {
                    testData.push({ ...val, surChargePrice: parseFloat(val.price) + parseFloat(val.price) })
                });
            }
            else if (FindExpressPrice) {

                data?.data?.testDetails?.forEach(val => {
                    testData.push({ ...val, surChargePrice: (parseFloat(40) / parseFloat(100)) * parseFloat(val.price || 0) })
                });


            }
            else {

                data?.data?.testDetails?.forEach(val => {
                    testData.push({ ...val, surChargePrice: 0 })
                });
                // testData = data?.data?.testDetails
            }

        }

        setTestDetailsData([...testData])
        setAmendment(data?.data?.amendment)
    }, [data, setTestDetailsData, setAmendment])


    useEffect(() => {
        if (!data?.data) return
        if (!amendment) {
            setAmendmentDiscount()
            setAmendmentPrice()
            setAmendmentQty()
        }

        else {
            setAmendmentDiscount(0)
            setAmendmentPrice(singleBranchData?.data?.amendmentPrice || 0)
            setAmendmentQty(1)
        }

    }, [amendment])

    if (!data?.data) {
        return <Loader />
    }

    const imageWidth = "200px"
    const imageHeight = "150px";

    const image = data?.data?.authorizedSignature ? data?.data?.authorizedSignature : ""


    function refreshInvoiceDetails() {
        setTestDetailsData(prev => {
            let newObj = structuredClone(prev)
            newObj.forEach((val, valIndex) => {
                let upDatePrice = (singlePartyData?.data?.testDetails?.find(item => parseInt(item.testId) === parseInt(val.testId))?.price || 0)
                newObj[valIndex]["price"] = upDatePrice
                newObj[valIndex]["surChargePrice"] = isCalculateSurchargePrice(newObj[valIndex]["price"])
            })
            return newObj
        })
    }


    console.log(data, "dataaaaainvoice")
    return (
        <>
            {/* <button className='bg-red-400 w-24 rounded-xl p-1' onClick={() => generatePDF(componentRef)}>Send Mail</button> */}
            {/* <div className='hidden font-times'>
            <Document data={data} componentRef={componentRef} testDetailsData={testDetailsData}/>
        </div> */}

            <Modal isOpen={invoiceDocument} onClose={() => setInvoiceDocument(false)} widthClass={" h-[90%] w-[90%] bg-white p-2"}>

                <InvoiceReportDocument id={id} />

            </Modal>

            <div className=" font-times w-full bg-white shadow-md p-5 rounded-md grid grid-cols-1 place-content-center ">

                <div>
                    <Header />
                </div>


                <div>
                    <h2 className="text-md font-semibold text-center mt-2 mb-2">{(data?.data?.partyIsGst || data?.data?.partyIsOutside) ? "TAX INVOICE" : "INVOICE"}</h2>
                </div>

                <div className='grid grid-cols-2 gap-y-1 text-xs'>
                    {data?.data?.invoiceNo
                        ?
                        <p className='text-xs'><strong>Invoice No:</strong> {data?.data.invoiceNo}</p>
                        :
                        <GenerateInvoiceNumber registrationId={data?.data.registrationId} />
                    }
                    <p className='text-xs'><strong>Invoice.Date:</strong> {moment.utc(data?.data?.invoiceDate).format("DD-MM-YYYY")}</p>
                    <p className='text-xs'><strong>STATE CODE: 33</strong> </p>
                    <p className='text-xs'><strong>HSN CODE: 998346</strong> </p>

                </div>
                <div className='grid grid-cols-1 mt-1.5 text-xs'>
                    <h1 >To : {data?.data?.toCompany?.name} ---{data?.data?.toCompany?.address}</h1>
                    <h1 className='mt-1'  >ContactName : {data?.data?.toCompany?.contactDetails?.map(val => val.contactPersonName).join("/")}</h1>
                    <h1>Email.Id : {data?.data?.toCompany?.contactDetails?.map(val => val.email).join("/")}</h1>
                </div>

                {/* <div className='grid grid-cols-2 auto-cols-max mt-1.5 text-xs'>
                <p className=''>BuyerName:   {data?.data?.buyerName ? data?.data?.buyerName : ""} </p>
                <p className=''>Test Report No:  {data?.data?.testReportNo ? data?.data?.testReportNo : ""} </p>
                <p className=''>Color:   {data?.data?.color ? data?.data?.color : ""} </p>
                <p className=''>Reg.Date:   {moment.utc(data?.data?.regDate ? data?.data?.regDate : "").format("DD-MM-YYYY")}</p>
                <p className=''>Service:   {data?.data?.service ? data?.data?.service : ""} Days</p>
                <p className=''>Description:  {data?.data?.sampleDescription ? data?.data?.sampleDescription : ""}</p>
                <p className=''>Style. No:   {data?.data?.styleNo ? data?.data?.styleNo : ""} </p>
                <p className=''>Batch. No:   {data?.data?.batchNo ? data?.data?.batchNo : ""}</p>
            </div> */}
                <div className='w-full flex justify-end text-orange-600' >
                    <CheckBox name="AmendmentPrice" value={amendment} setValue={setAmendment} />

                </div>

                <div className="w-full grid justify-end ">
                    <button
                        type='button' className={`text-white bg-green-700 rounded-md p-1 w-24 mr-5 mb-5`}
                        onClick={() => {


                            refreshInvoiceDetails();
                        }}

                    > Refresh
                    </button>
                </div>

                <div className=' mb-2'>

                    <table className='w-full mt-1 border border-gray-500 table-fixed p-2'>

                        <tbody>
                            <tr>
                                <td className='text-xs border border-gray-600 p-1 whitespace-normal break-words'>
                                    BuyerName: <span className='ml-2'>{data?.data?.buyerName ? data?.data?.buyerName : ""}</span>
                                </td >
                                <td className='text-xs border border-gray-600 p-1 whitespace-normal break-words'>
                                    Test Report No: <span className='ml-2'></span> {data?.data?.testReportNo ? data?.data?.testReportNo : ""}
                                </td >
                            </tr>
                            <tr>
                                <td className='text-xs border border-gray-600 p-1 whitespace-normal break-words'>
                                    Color:  <span className='ml-2'> {data?.data?.color ? data?.data?.color : ""}</span>
                                </td >
                                <td className='text-xs border border-gray-600 p-1 whitespace-normal break-words'>
                                    Reg.Date: <span className='ml-2'>  {moment.utc(data?.data?.regDate ? data?.data?.regDate : "").format("DD-MM-YYYY")}</span>
                                </td >
                            </tr>
                            <tr>
                                <td className='text-xs border border-gray-600 p-1 whitespace-normal break-words'>
                                    Service:  <span className='ml-2'> {data?.data?.service ? data?.data?.service : ""} Days </span>
                                </td >
                                <td className='text-xs border border-gray-600 p-1 whitespace-normal break-words'>
                                    Description: <span className='ml-2'> {data?.data?.sampleDescription ? data?.data?.sampleDescription : ""} </span>
                                </td >
                            </tr>
                            <tr>
                                <td className='text-xs border border-gray-600 p-1 whitespace-normal break-words'>
                                    Style. No: <span className='ml-2'> {data?.data?.styleNo ? data?.data?.styleNo : ""} </span>
                                </td >
                                <td className='text-xs border border-gray-600 p-1 whitespace-normal break-words'>
                                    Batch. No:  <span className='ml-2'>{data?.data?.batchNo ? data?.data?.batchNo : ""} </span>
                                </td >
                            </tr>
                        </tbody>
                    </table>

                </div>


                {
                    data?.data?.typeOfInvoice == "PACKAGE" ? (
                        <>
                            <table className="table-fixed w-full mt-1.5">
                                <thead>
                                    <tr>
                                        <th className="border border-gray-400 text-xs p-1 w-9">S.No</th>
                                        <th className="border border-gray-400 text-xs p-1  w-60">Package Name</th>
                                        <th className="border border-gray-400 text-xs p-1 w-32">Quantity</th>
                                        <th className="border border-gray-400 text-xs p-1 ">Price</th>
                                        <th className="border border-gray-400 text-xs p-1 ">SurChargePrice</th>
                                        <th className="border border-gray-400 text-xs p-1">Discount</th>
                                        <th className="border border-gray-400 text-xs p-1 w-32">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.data?.packageDetails.map((item, index) =>
                                        <tr key={index}>
                                            <td className="border border-gray-400 text-xs p-1 ">{index + 1}</td>

                                            <td className="border border-gray-400 text-xs p-1">{item.packageName}</td>
                                            <td className="border border-gray-400 text-xs p-1 text-right ">
                                                {item?.qty || 0}
                                            </td>
                                            <td className="border border-gray-400 text-xs p-1 text-right ">{item.price}</td>
                                            <td className="border border-gray-400 text-xs p-1 text-right ">

                                                <input disabled type="number" className='p-1 text-right w-full' value={surChargePrice}

                                                />
                                            </td>

                                            <td className="border border-gray-400 text-xs p-1 text-right ">

                                                <input type="number" className='p-1 text-right w-full' value={packageDiscount} onChange={(e) => {
                                                    setPackageDiscount(e.target.value)
                                                }} />


                                            </td>
                                            <td className="border border-gray-400 text-xs p-1 text-right">{(!item.qty || !item.price) ? 0 : (((parseFloat(item.qty) * parseFloat(item.price)) + parseFloat(item?.surChargePrice || isCalculateSurchargePrice(item.price))) - (item.discount ? item.discount : 0)).toFixed(2)}</td>



                                        </tr>
                                    )}

                                    {
                                        amendment &&

                                        <tr >


                                            <td colSpan={2} className="border border-gray-400 text-xs p-1 text-center">Amendment Charge</td>
                                            <td className="border border-gray-400 text-xs p-1 text-right">
                                                <input type="number" className='p-1 text-right' value={amendmentQty} onChange={(e) => {
                                                    setAmendmentQty(e.target.value)
                                                }} />
                                            </td>

                                            <td className="border border-gray-400 text-xs p-1 ">
                                                <input type="number" className='p-1 text-right w-full' value={amendmentPrice} onChange={(e) => {
                                                    setAmendmentPrice(e.target.value)
                                                }} />
                                            </td>

                                            <td className="border border-gray-400 text-xs p-1 ">

                                            </td>

                                            <td className="border border-gray-400 text-xs p-1 ">
                                                <input type="number" className='p-1 text-right w-full' value={amendmentDiscount} onChange={(e) => {
                                                    setAmendmentDiscount(e.target.value)
                                                }} />
                                            </td>

                                            <td className="border border-gray-400 text-xs p-1 text-right">{(!amendmentQty || !amendmentPrice) ? 0 : ((parseFloat(amendmentQty) * parseFloat(amendmentPrice)) - (amendmentDiscount ? amendmentDiscount : 0)).toFixed(2)}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                            <div className='flex justify-end '>
                                <button

                                    className='bg-green-400 rounded-lg border border-black text-xs w-16 h-9 p-1 mt-5'
                                    onClick={() => { handleSave("updated") }}
                                >Save

                                </button>
                            </div>

                        </>
                    )

                        : ""

                }

                {
                    testDetailsData.length > 0 ? (

                        <>
                            <div className='mt-5'>
                                <table className="table-fixed w-full">
                                    <thead>
                                        <tr>
                                            <th className="border border-gray-400 text-xs p-1 w-9">S.No</th>

                                            <th className="border border-gray-400 text-xs p-1 w-60">Test</th>
                                            <th className="border border-gray-400 text-xs p-1 ">Quantity</th>
                                            <th className="border border-gray-400 text-xs p-1 w-32">Rate</th>
                                            <th className="border border-gray-400 text-xs p-1 w-32">SurChargePrice</th>
                                            <th className="border border-gray-400 text-xs p-1 ">Discount</th>

                                            <th className="border border-gray-400 text-xs p-1 w-32">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {testDetailsData.map((item, index) => (
                                            <tr key={index}>
                                                <td className="border border-gray-400 text-xs p-1 ">{index + 1}</td>

                                                <td className="border border-gray-400 text-xs p-1">{item.testName}</td>
                                                <td className="border border-gray-400 text-xs p-1 text-right">
                                                    <input type="number" className='p-1 text-right' value={item?.qty ? item?.qty : 0} onChange={(e) => {
                                                        handleInputChange(e.target.value, index, 'qty')
                                                    }} />
                                                </td>
                                                <td className="border border-gray-400 text-xs p-1 text-right">{item.price}</td>
                                                <td className="border border-gray-400 text-xs p-1 text-right">
                                                    <input disabled type="number" className='p-1 text-right w-full' value={item?.surChargePrice ? item?.surChargePrice : 0}
                                                    // onChange={(e) => {
                                                    //     handleInputChange(e.target.value, index, 'surChargePrice')
                                                    // }}
                                                    /></td>
                                                <td className="border border-gray-400 text-xs p-1 ">
                                                    <input type="number" className='p-1 text-right w-full' value={item?.discount ? item?.discount : 0} onChange={(e) => {
                                                        handleInputChange(e.target.value, index, 'discount')
                                                    }} />
                                                </td>

                                                <td className="border border-gray-400 text-xs p-1 text-right">{(!item.qty || !item.price) ? 0 : (((parseFloat(item.qty) * parseFloat(item.price)) + parseFloat(item?.surChargePrice || 0)) - (item.discount ? item.discount : 0)).toFixed(2)}</td>
                                            </tr>
                                        ))}
                                        {
                                            amendment &&

                                            <tr >


                                                <td colSpan={2} className="border border-gray-400 text-xs p-1 text-center">Amendment Charge</td>
                                                <td className="border border-gray-400 text-xs p-1 text-right">
                                                    <input type="number" className='p-1 text-right' value={amendmentQty} onChange={(e) => {
                                                        setAmendmentQty(e.target.value)
                                                    }} />
                                                </td>

                                                <td className="border border-gray-400 text-xs p-1 ">
                                                    <input type="number" className='p-1 text-right w-full' value={amendmentPrice} onChange={(e) => {
                                                        setAmendmentPrice(e.target.value)
                                                    }} />
                                                </td>
                                                <td className="border border-gray-400 text-xs p-1 ">

                                                </td>
                                                <td className="border border-gray-400 text-xs p-1 ">
                                                    <input type="number" className='p-1 text-right w-full' value={amendmentDiscount} onChange={(e) => {
                                                        setAmendmentDiscount(e.target.value)
                                                    }} />
                                                </td>

                                                <td className="border border-gray-400 text-xs p-1 text-right">{(!amendmentQty || !amendmentPrice) ? 0 : ((parseFloat(amendmentQty) * parseFloat(amendmentPrice)) - (amendmentDiscount ? amendmentDiscount : 0)).toFixed(2)}</td>
                                            </tr>
                                        }

                                    </tbody>
                                </table>
                            </div>

                            <div className='flex justify-end '>
                                <button
                                    className='bg-green-400 rounded-lg border border-black text-xs w-16 h-9 p-1 mt-5'
                                    onClick={() => { handleSave("updated") }}
                                >Save
                                </button>
                            </div>

                        </>
                    )


                        : ""


                }

                <div className='flex align-items-end justify-end mr-32 mt-5 text-xs'>

                    <div className='grid grid-cols-1'>
                        <div className='mb-1'>
                            Analysis Charge:
                        </div>
                        {data?.data?.taxDetails?.map(val =>
                            <h1>
                                {`${val?.taxName} @ ${val.taxPercent} % & ${"TDS"} @ ${val?.tdsPercent} % : ${parseFloat(val?.taxAmount).toFixed(2)}`}
                            </h1>

                        )}

                        <div className='mb-2'>
                            RoundOff:  {data?.data?.roundOff ? parseFloat(data?.data?.roundOff).toFixed(2) : 0.00}

                        </div>

                        <h1>
                            NetCharge : {data?.data?.netAmount ? parseFloat(data?.data?.netAmount).toFixed(2) : 0.00}
                        </h1>
                        <h1>
                            Grand Total : {data?.data?.netAmount ? parseFloat(data?.data?.netAmount).toFixed(2) : 0.00}
                        </h1>


                    </div>

                </div>


                <div className='mt-2.5'>
                    <h1 className='text-sm font-bold'>
                        TERMS AND CONDITIONS
                    </h1>
                    <ol className='text-xs'>

                        <li>
                            1. 2% interest per month will be charged for any invoice remained unpaid for more than a month from the date of issue of the Invoice
                        </li>
                        <li>
                            2. No claim against this invoice will be entertained unless brought to our notice in writing within 7 days of receipt of this invoice
                        </li>
                        <li>
                            3. Our report for which this invoice is issued includes all the tests requested by you and the results thereof. You shall have thirty days from receipt of report to request
                            additional testing of the samples or to notify us of any errors or omissions relating to our report provided, however, such notice shall be in writing and shall
                            specifically address the issue you wish to raise. A failure to raise such issue within the prescribed time shall constitute your unqualified acceptance of the
                            completeness of report, the tests conducted and the correctness of the report contents
                        </li>
                        <li>
                            4. Prepayment may be enforced when the account is overdue.
                        </li>
                        <li>
                            5. Any dispute arising out of this transaction / Contract will be referred to institutional arbitration council of Tirupur as per the rules and regulations of Arbitration
                            Council of Tirupur and the award passed will be binding on us.
                        </li>
                        <li>
                            6. The test report is as per the sample provided by you / your representation.
                        </li>


                    </ol>

                </div>

                <div className='mt-4 text-xs'>
                    <h1 className='font-bold'>
                        OUR BANK DETAILS
                    </h1>
                    <h1 className='mt-0.5'>
                        <strong> ACCOUNT NAME:</strong> {data?.data?.bankDetails?.accountName ? data?.data?.bankDetails?.accountName : ""}    </h1>
                    <h1><strong> ACCOUNT NO:</strong>  {data?.data?.bankDetails?.accountNo ? data?.data?.bankDetails?.accountNo : ""}   </h1>
                    <h1><strong> IFSC.CODE:</strong>  {data?.data?.bankDetails?.ifsc ? data?.data?.bankDetails?.ifsc : ""}    </h1>
                    <h1><strong> BRANCH:</strong>  {data?.data?.bankDetails?.branch ? data?.data?.bankDetails?.branch : ""}
                    </h1>
                    <h1><strong> GST NO :</strong>  33AADCS8162K1Z4 </h1>

                </div>


                <div className="grid justify-end mt-2 mr-20 ml-20 text-xs">
                    <div className='w-ful mt-2 mb-2'>
                        {Boolean(image) ?
                            <img style={{ height: imageHeight, width: imageWidth, objectFit: 'fit' }}
                                src={getImageUrlPath(image)}
                            />
                            :
                            <img src={empty} className='w-20  text-center' />
                        }
                    </div>
                    <h1>
                        Verified By &   Authorized By
                    </h1>
                    {/* <h1>
                    Authorized By
                </h1> */}
                </div>


                <div className='text-xs '>
                    <FactoryAddress />
                </div>


            </div>
        </>
    );
};

export default PrintInvoiceReport;
